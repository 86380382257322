import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import Switch from 'react-switch';
import SubPostings from './Postings';
import EditSubscription from '../Common/EditSubscription';
import SwitchOrgs from '../Common/SwitchOrgs';
import SubInviteMembers from '../Common/InviteMembers';
import {signOut} from '../Services/AuthRoutes';
import withRouter from '../Functions/WithRouter';

const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const courseIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png";
const answerBubbleIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/answer-bubble.png';
const exampleLogoLight = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-logo-light.png';
const exampleLogoDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-logo-dark.png';
const settingsIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/settings-icon.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";

class Settings extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showChangeEmail: false,
          showChangeEmailOnlyParticipants: false,
          emailId: null,
          username: '',
          isAdvisor: '',
          myOrgs: [],
          dataToShare: [],

          startingCredits: 100,
          availableCredits: 100,

          studentAliasSingular: "Career-Seeker",

          showModule: false,
          showWalkthrough: false,
          testResumeExport: true,

          typeOptions: ['','For-Profit','Non-Profit','Public Sector'],
          postOptions: [],
          cteModelStandardsOptions: [],
          cteModelStandards: ['Arts, Media, and Entertainment','Information and Communication Technologies','Public Services','Education, Child Development, and Family Services'],
          wblPreferenceOptions: ['Mentors','Career Events','Projects','Work'],
          wblPreferences: [],
          pathwayOptions: [],
          pathways: ['Elizabeth Learning Center','Banning High School','Hollywood High School'],
          editPathwayMode: false,
          careerTrackOptions: [],
          availableAssessments: [],
          standardAssessments: [],
          estimatedHoursOptions: [],
          functionOptions: [],
          industryOptions: [],

          hiddenTabs: [
            { name: 'Community', hidden: false },
            { name: 'Learning', hidden: false },
            { name: 'Explore Paths', hidden: false }
          ],

          ccEmails: [],

          orgName: '',
          orgURL: '',
          orgFocus: '',
          orgProgramName: '',
          orgDescription: '',
          orgMission: '',

          countOptions: [],
          growthOptions: [],

          binaryOptions: ['','Yes','No'],
          maybeOptions: ['','Yes','Maybe','No'],
          officeTypeOptions: [],
          politicalAlignmentOptions: [],
          registrationOptions: [],
          timeframeOptions: [],

          isIntermediary: false,
          companyName: '',
          companyURL: '',
          companyType: '',
          companyIndustry: '',
          employeeCount: '',
          employeeGrowth: '',
          contactFirstName: '',
          contactLastName: '',
          contactTitle: '',
          contactEmail: '',
          contactPhone: '',
          sharePartners: [],
          hireTimeframes: [],

        }

        this.shareCompass = this.shareCompass.bind(this)
        this.contactUs = this.contactUs.bind(this)
        // this.signOut = this.signOut.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.saveSettings = this.saveSettings.bind(this)

        this.closeModal = this.closeModal.bind(this)

        this.renderOrgInformation = this.renderOrgInformation.bind(this)
        this.renderDataToShare = this.renderDataToShare.bind(this)

        this.renderCourses = this.renderCourses.bind(this)
        this.exportToCSV = this.exportToCSV.bind(this)

        this.addItem = this.addItem.bind(this)
        this.retrieveTrackPosts = this.retrieveTrackPosts.bind(this)
        this.savePersonalInfo = this.savePersonalInfo.bind(this)
        this.saveOrgInfo = this.saveOrgInfo.bind(this)
        this.saveAccountInfo = this.saveAccountInfo.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.addAnswerChoice = this.addAnswerChoice.bind(this)
        this.renderCCEmails = this.renderCCEmails.bind(this)
        this.addCCEmail = this.addCCEmail.bind(this)
        this.saveFile = this.saveFile.bind(this)
        this.renderOrgContacts = this.renderOrgContacts.bind(this)
        this.savePreferences = this.savePreferences.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.removeItem = this.removeItem.bind(this)

        this.suggestItems = this.suggestItems.bind(this)
        this.suggestedItemClicked = this.suggestedItemClicked.bind(this)
        this.deleteAccount = this.deleteAccount.bind(this)
        this.addCertificate = this.addCertificate.bind(this)
        this.pullArchivedMembers = this.pullArchivedMembers.bind(this)
        this.toggleTalentRetention = this.toggleTalentRetention.bind(this)
        this.toggleChatbotConfig = this.toggleChatbotConfig.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      if (this.state.emailId) {
        //send this logged out user out of the app
      } else {

        this.retrieveData()

      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within shared settings', this.props.roleName, this.props.activeOrg, this.props.accountCode)

      let internalToolEnabled = localStorage.getItem('internalToolEnabled')
      let tempRoleName = localStorage.getItem('roleName');
      let tempAccountCode = localStorage.getItem('accountCode');

      let employerAccount = false
      if (tempRoleName === 'Employee' && tempAccountCode) {
        employerAccount = true
      }

      const email = this.props.email
      const accountCode = this.props.accountCode
      const activeOrg = this.props.activeOrg
      const orgFocus = this.props.orgFocus
      const roleName = this.props.roleName

      console.log('show email: ', email)

      let dataToShare = [
        'Name', 'Profile Photo','Resume URL','LinkedIn URL','Portfolio URL','School','Major / Pathway','Graduation Year','Race','Gender','Veteran Status','Work Authorization','Projects','Experience','Career Assessments','Endorsements'
      ]

      let showWalkthrough = true
      // if (activeOrg === 'unite-la') {
      //   showWalkthrough = true
      // }

      let availableAssessments = []
      let timeframeOptions = []

      let problemPlatformOrg = false
      let problemPlatformEmployer = false
      if (window.location.pathname.includes('/problem-platform')) {
        if (roleName === 'Admin' || roleName === 'Work Based Learning Coordinator' || roleName === 'Work-Based Learning Coordinator') {
          problemPlatformOrg = true
        } else if (roleName === 'Employer') {
          problemPlatformEmployer = true
        }
      }

      if (window.location.pathname.includes('/organizations') || problemPlatformOrg) {
        availableAssessments = [
          { name: 'Work Preferences', description: '' }, { name: 'Work Interests', description: '' },
          { name: 'General Interests', description: '' }, { name: 'Personality', description: '' },
          { name: 'Values', description: '' }, { name: 'Skills', description: '' },
          { name: 'Adversity', description: '' }, { name: 'Political', description: '' },
        ]
      } else if (window.location.pathname.includes('/employers') || problemPlatformEmployer) {

        const currentMonth = new Date().getMonth()
        const currentYear = new Date().getFullYear()
        const seasons = ['Spring','Summer','Fall','Winter']
        let currentSeason = ''
        if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2) {
          currentSeason = seasons[0]
        } else if (currentMonth === 3 || currentMonth === 4 || currentMonth === 5) {
          currentSeason = seasons[1]
        } else if (currentMonth === 6 || currentMonth === 7 || currentMonth === 8) {
          currentSeason = seasons[2]
        } else if (currentMonth === 9 || currentMonth === 10 || currentMonth === 11) {
          currentSeason = seasons[3]
        }

        for (let i = 1; i <= 4; i++) {
          if (i === 1) {
            timeframeOptions.push(currentSeason + ' ' + currentYear)
          } else {
            // not currentSeason
            const index = seasons.indexOf(currentSeason)
            if (index + i - 1 > 3) {
              const nextYear = currentYear + 1
              const newIndex = (index + i - 1) - 4
              timeframeOptions.push(seasons[newIndex] + ' ' +  nextYear)
            } else {
              timeframeOptions.push(seasons[index + i - 1] + ' ' + currentYear)
            }
          }
        }
        timeframeOptions.push("Ad-Hoc / As-Needed")
      }

      this.setState({ emailId: email, accountCode, activeOrg, orgFocus, roleName, dataToShare, showWalkthrough,
        availableAssessments, timeframeOptions, internalToolEnabled, employerAccount
      });

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          let politicalAlignmentOptions = response.data.workOptions[0].politicalAlignmentOptions
          if (politicalAlignmentOptions) {
            politicalAlignmentOptions.unshift('')
          }

          let registrationOptions = response.data.workOptions[0].unitedStateOptions
          if (registrationOptions) {
            registrationOptions.unshift('')
          }

          const functionOptions = response.data.workOptions[0].functionOptions
          const industryOptions = response.data.workOptions[0].industryOptions
          const countOptions = response.data.workOptions[0].employeeCountOptions
          const growthOptions = response.data.workOptions[0].employeeGrowthOptions
          const hireOptions = response.data.workOptions[0].projectedInternOptions
          let departmentOptions = response.data.workOptions[0].departmentOptions
          const estimatedHoursOptions = response.data.workOptions[0].durationOptions

          this.setState({
            functionOptions, industryOptions, countOptions, growthOptions, hireOptions,
            politicalAlignmentOptions, registrationOptions, departmentOptions, estimatedHoursOptions
          })
        }
      });

      if (activeOrg) {
        if (window.location.pathname.includes('/advisor')) {

          Axios.get('/api/org', { params: { orgCode: activeOrg } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

              if (response.data.success) {
                console.log('org info query worked')

                const orgName = response.data.orgInfo.orgName
                const academies = response.data.orgInfo.academies
                let includeGuidedCompass = false
                if (response.data.orgInfo.placementPartners && response.data.orgInfo.placementPartners.includes('guidedcompass')) {
                  includeGuidedCompass = true
                }
                const wblPreferences = response.data.orgInfo.wblPreferences
                const orgDepartments = response.data.orgInfo.departments
                const caseNoteTagOptions = response.data.orgInfo.caseNoteTagOptions

                let academyCodes = []
                if (academies && academies.length > 0) {
                  for (let i = 1; i <= academies.length; i++) {
                    academyCodes.push(academies[i - 1].orgCode)
                  }
                }

                const disableFreeformChatbotMessaging = response.data.orgInfo.disableFreeformChatbotMessaging
                const disableChatbotPrompts = response.data.orgInfo.disableChatbotPrompts

                this.setState({ orgName, academies, academyCodes, includeGuidedCompass,
                  wblPreferences, orgDepartments, caseNoteTagOptions, disableFreeformChatbotMessaging, disableChatbotPrompts
                });

                // let courses = []
                // if (response.data.orgInfo.courseIds && response.data.orgInfo.courseIds.length > 0) {
                //   console.log('courses exist')
                //
                // }

              } else {
                console.log('org info query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });

          Axios.get('/api/users/profile/details', { params: { email } })
          .then((response) => {

              if (response.data.success) {
                console.log('User profile query worked', response.data);

                const user = response.data.user
                const firstNameValue = response.data.user.firstName
                const lastNameValue = response.data.user.lastName
                const linkedInURLValue = response.data.user.linkedInURL
                const resumeURLValue = response.data.user.resumeURL
                const customWebsiteURLValue = response.data.user.customWebsiteURL
                const jobTitle = response.data.user.jobTitle
                const employerName = response.data.user.employerName
                const workZipcode = response.data.user.workZipcode
                const workTenure = response.data.user.workTenure
                const overallFit = response.data.user.overallFit
                const degreeAttained = response.data.user.degreeAttained
                const schoolName = response.data.user.schoolName
                const studyFields = response.data.user.studyFields
                const myOrgs = response.data.user.myOrgs
                const assistantChatCount = response.data.user.assistantChatCount

                let availableCredits = this.state.availableCredits
                if (assistantChatCount) {
                  availableCredits = availableCredits - assistantChatCount
                }

                this.setState({
                  user, firstNameValue, lastNameValue, linkedInURLValue, resumeURLValue, customWebsiteURLValue,
                  jobTitle, employerName, workZipcode, workTenure, overallFit, degreeAttained, schoolName, studyFields,
                  myOrgs, availableCredits
                });

                const courseIds = response.data.user.courseIds

                if (courseIds && courseIds.length > 0 && courseIds[0]) {
                  // pull from courses

                  Axios.get('/api/courses', { params: { courseIds } })
                  .then((response) => {

                      if (response.data.success) {
                        console.log('Courses query worked', response.data);

                        const courses = response.data.courses
                        this.setState({ courses })

                      } else {
                        console.log('no course details found', response.data.message)
                      }

                  }).catch((error) => {
                      console.log('course query did not work', error);
                  });

                  Axios.get('/api/grades', { params: { orgCode: activeOrg, courseIds } })
                  .then((response) => {
                    console.log('Grades query attempted', response.data);

                      if (response.data.success) {
                        console.log('grades query worked')

                        const grades = response.data.grades
                        const students = response.data.students
                        const projectNames = response.data.projectNames

                        this.setState({ grades, students, projectNames })

                      } else {
                        console.log('grades query did not work', response.data.message)
                      }

                  }).catch((error) => {
                      console.log('Grades query did not work for some reason', error);
                  });

                }

              } else {
                console.log('no user details found', response.data.message)

              }

          }).catch((error) => {
              console.log('User profile query did not work', error);
          });
        } else if (window.location.pathname.includes('/organizations') || problemPlatformOrg) {

          Axios.get('/api/org', { params: { orgCode: activeOrg } })
          .then((response) => {
            console.log('Org info query attempted in sub settings', response.data);

              if (response.data.success) {
                console.log('org info query worked')

                let studentAliasSingular = this.state.studentAliasSingular
                if (response.data.orgInfo.studentAliasSingular) {
                  studentAliasSingular = response.data.orgInfo.studentAliasSingular
                }

                const studentAliasPlural = response.data.orgInfo.studentAliasPlural
                const mentorAliasSingular = response.data.orgInfo.mentorAliasSingular
                const mentorAliasPlural = response.data.orgInfo.mentorAliasPlural

                const studentBenefitsText = response.data.orgInfo.studentBenefitsText
                const mentorBenefitsText = response.data.orgInfo.mentorBenefitsText
                const employerBenefitsText = response.data.orgInfo.employerBenefitsText

                const studentLongBenefitsText = response.data.orgInfo.studentLongBenefitsText
                const mentorLongBenefitsText = response.data.orgInfo.mentorLongBenefitsText
                const employerLongBenefitsText = response.data.orgInfo.employerLongBenefitsText

                let standardAssessments = response.data.orgInfo.standardAssessments
                if (!standardAssessments) {
                  standardAssessments = []
                }
                let hiddenTabs = this.state.hiddenTabs
                if (response.data.orgInfo.hiddenTabs && response.data.orgInfo.hiddenTabs.length > 0) {
                  hiddenTabs = response.data.orgInfo.hiddenTabs
                }
                const employerFormTitle = response.data.orgInfo.employerFormTitle
                const confirmationMessage = response.data.orgInfo.confirmationMessage
                const employerDashboardMessage = response.data.orgInfo.employerDashboardMessage
                const internshipEmptyStateMessage = response.data.orgInfo.internshipEmptyStateMessage
                const pathways = response.data.orgInfo.pathways
                const orgDepartments = response.data.orgInfo.departments
                const caseNoteTagOptions = response.data.orgInfo.caseNoteTagOptions
                const certificateOptions = response.data.orgInfo.certificateOptions
                const meetingTagOptions = response.data.orgInfo.meetingTagOptions
                const meetingPurposeOptions = response.data.orgInfo.meetingPurposeOptions

                this.setState({

                    orgName: response.data.orgInfo.orgName,
                    orgURL: response.data.orgInfo.orgURL,
                    orgType: response.data.orgInfo.orgType,
                    orgFocus: response.data.orgInfo.orgFocus,
                    orgProgramName: response.data.orgInfo.orgProgramName,
                    orgDescription: response.data.orgInfo.orgDescription,
                    orgMission: response.data.orgInfo.orgMission,

                    contactFirstName: response.data.orgInfo.contactFirstName,
                    contactLastName: response.data.orgInfo.contactLastName,
                    contactTitle: response.data.orgInfo.contactTitle,
                    contactEmail: response.data.orgInfo.contactEmail,
                    ccEmails: response.data.orgInfo.ccEmails,
                    chatEmail: response.data.orgInfo.chatEmail,

                    automaticallyAttach: response.data.orgInfo.automaticallyAttach,
                    parentPostId: response.data.orgInfo.parentPostId,
                    careerTrackOptions: response.data.orgInfo.careerTracks,
                    disableFreeformChatbotMessaging: response.data.orgInfo.disableFreeformChatbotMessaging,
                    disableChatbotPrompts: response.data.orgInfo.disableChatbotPrompts,
                    studentSignUpNotification: response.data.orgInfo.studentSignUpNotification,
                    employerSignUpNotification: response.data.orgInfo.employerSignUpNotification,

                    orgLogoPath: response.data.orgInfo.webLogoURIColor,
                    orgLogoWhitePath: response.data.orgInfo.webLogoURI,

                    studentAliasSingular, studentAliasPlural, mentorAliasSingular, mentorAliasPlural,
                    studentBenefitsText, mentorBenefitsText, employerBenefitsText,
                    studentLongBenefitsText, mentorLongBenefitsText, employerLongBenefitsText,
                    standardAssessments, hiddenTabs, employerFormTitle, confirmationMessage, employerDashboardMessage,
                    internshipEmptyStateMessage, pathways, orgDepartments, caseNoteTagOptions, certificateOptions,
                    meetingTagOptions, meetingPurposeOptions
                });

                this.retrieveTrackPosts(activeOrg, response.data.orgInfo.parentPostId)

              } else {
                console.log('org info query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });

          Axios.get('/api/users/profile/details', { params: { email } })
          .then((response) => {

              if (response.data.success) {
                console.log('User profile query worked', response.data);

                const firstName = response.data.user.firstName
                const firstNameValue = firstName
                const lastName = response.data.user.lastName
                const lastNameValue = lastName
                const jobTitle = response.data.user.jobTitle
                const employerName = response.data.user.employerName
                const workZipcode = response.data.user.workZipcode
                const workTenure = response.data.user.workTenure
                const degreeAttained = response.data.user.degreeAttained
                const schoolName = response.data.user.schoolName
                const studyFields = response.data.user.studyFields

                this.setState({
                  firstName, firstNameValue, lastName, lastNameValue, jobTitle, employerName, workZipcode,
                  workTenure, degreeAttained, schoolName, studyFields
                });

              } else {
                console.log('no user details found', response.data.message)

              }

          }).catch((error) => {
              console.log('User profile query did not work', error);
          });

          Axios.get('/api/pathways', { params: { orgCode: activeOrg }})
          .then((response) => {
            console.log('Pathways query attempted no 1', response.data);

            if (response.data.success) {
              console.log('pathway query worked no 1')

              if (response.data.pathways.length !== 0) {

                let pathwayOptions = []
                if (response.data.pathways && response.data.pathways.length > 0) {
                  pathwayOptions = [{ title: '' }].concat(response.data.pathways)
                }

                this.setState({ pathwayOptions });
              }

            } else {
              console.log('pathway query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Pathway query did not work for some reason', error);
          });

        } else if (window.location.pathname.includes('/employers') || problemPlatformEmployer) {
          // employers

          let self = this
          function pullAccountInformation(passedAccountCode) {
            console.log('pullAccountInformation called', passedAccountCode)

            Axios.get('/api/account', { params: { accountCode: passedAccountCode } })
            .then((response) => {
              console.log('Account info query attempted within sub settings', response.data);

              if (response.data.success) {
                console.log('account info query worked in sub settings')

                const officeType = response.data.accountInfo.officeType
                const politicalAlignment = response.data.accountInfo.politicalAlignment
                const stateRegistration = response.data.accountInfo.stateRegistration
                const congressionalDistrict = response.data.accountInfo.congressionalDistrict
                const hasFunds = response.data.accountInfo.hasFunds
                const hostUndocumented = response.data.accountInfo.hostUndocumented
                const hireTimeframes = response.data.accountInfo.hireTimeframes
                const employerLogoPath = response.data.accountInfo.employerLogoURI
                const employerLogoWhitePath = response.data.accountInfo.employerLogoWhiteURI

                let orgContacts = undefined
                let showOrgContacts = false
                if (response.data.accountInfo.orgContacts) {
                  orgContacts = response.data.accountInfo.orgContacts
                  showOrgContacts = true
                  // orgContacts = []
                  // for (let i = 1; i <= response.data.accountInfo.orgContacts.length; i++) {
                  //   if (response.data.accountInfo.orgContacts[i - 1].orgCode === activeOrg) {
                  //     orgContractLastName.push(response.data.accountInfo.orgContacts[i - 1])
                  //   }
                  // }
                  // if (orgContacts.length > 0) {
                  //   showOrgContacts = true
                  // }
                }

                self.setState({
                    isIntermediary: response.data.accountInfo.isIntermediary,
                    companyName: response.data.accountInfo.employerName,
                    companyURL: response.data.accountInfo.employerURL,
                    companyType: response.data.accountInfo.employerType,
                    companyIndustry: response.data.accountInfo.employerIndustry,
                    employeeCount: response.data.accountInfo.employeeCount,
                    employeeGrowth: response.data.accountInfo.employeeGrowth,
                    sharePartners: response.data.accountInfo.sharePartners,
                    employerLocation: response.data.accountInfo.employerLocation,

                    contactFirstName: response.data.accountInfo.contactFirstName,
                    contactLastName: response.data.accountInfo.contactLastName,
                    contactTitle: response.data.accountInfo.contactTitle,
                    contactEmail: response.data.accountInfo.contactEmail,
                    contactPhone: response.data.accountInfo.contactPhone,
                    description: response.data.accountInfo.description,
                    employerCulture: response.data.accountInfo.employerCulture,
                    employerDepartments: response.data.accountInfo.departments,
                    videos: response.data.accountInfo.videos,

                    officeType, politicalAlignment, stateRegistration, congressionalDistrict, hasFunds, hostUndocumented,
                    hireTimeframes, employerLogoPath, employerLogoWhitePath, orgContacts, showOrgContacts
                });
              }

            }).catch((error) => {
              console.log('Account info query did not work for some reason', error);
            });
          }

          if (accountCode && accountCode !== '') {
            console.log('is an existing accountCode', accountCode)
            pullAccountInformation(accountCode)
          } else {
            console.log('is an accountCode', accountCode, email)

            if (email) {
              Axios.get('/api/users/profile/details', { params: { email } })
              .then((response) => {

                  if (response.data.success) {
                    console.log('User profile query worked', response.data);

                    const newAccountCode = response.data.user.accountCode
                    console.log('did we get: ', newAccountCode)
                    if (newAccountCode) {
                      pullAccountInformation(newAccountCode)
                    } else {
                      const errorMessage = 'there was an error finding your account'
                      console.log(errorMessage)
                      this.setState({ errorMessage })
                    }

                  } else {
                    console.log('no user details found', response.data.message)

                  }

              }).catch((error) => {
                  console.log('User profile query did not work', error);
              });
            }
          }

          Axios.get('/api/org', { params: { orgCode: activeOrg } })
          .then((response) => {
            console.log('org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              let officeTypeOptions = response.data.orgInfo.officeTypeOptions
              if (officeTypeOptions) {
                officeTypeOptions.unshift('')
              }

              this.setState({ officeTypeOptions });

            }

          }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
          });

        } else {
          // student

          Axios.get('/api/work', { params: { workerEmail: email, onlyCurrent: true } })
          .then((response) => {
            console.log('Work query attempted within employer dashboard', response.data);

            if (response.data.success) {
              console.log('account info query worked in sub settings')

              if (response.data.workArray && response.data.workArray.length > 0) {
                this.setState({ isWorking: true });
              }
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
          });

          Axios.get('/api/org', { params: { orgCode: activeOrg } })
          .then((response) => {
            console.log('Org query attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved org')

               const orgName = response.data.orgInfo.orgName
               const academies = response.data.orgInfo.academies

               console.log('show academies: ', academies)
               let academyCodes = []
               if (academies && academies.length > 0) {
                 for (let i = 1; i <= academies.length; i++) {
                   academyCodes.push(academies[i - 1].orgCode)
                 }
               }

               const disableWeeklyEmails = response.data.orgInfo.disableWeeklyEmails
               this.setState({ orgName, academies, academyCodes, disableWeeklyEmails });

             } else {
               console.log('no org data found', response.data.message)
               // this.setState({ serverErrorMessage: 'No org found'})
             }

          }).catch((error) => {
             console.log('Org query did not work', error);
             // this.setState({ serverErrorMessage: 'No org found'})
          });

          Axios.get('/api/users/profile/details/' + email)
          .then((response) => {
            if (response.data.success) {

              const responseData = response.data

              const user = responseData.user
              const roleName = responseData.user.roleName
              const remoteAuth = responseData.user.remoteAuth
              const activeOrg = responseData.user.activeOrg
              const myOrgs = responseData.user.myOrgs
              const openToMentoring = responseData.user.openToMentoring
              const workMode = responseData.user.workMode
              let subscribed = true
              if (responseData.user.unsubscribed) {
                subscribed = false
              }

              const subscribedForReminders = responseData.user.subscribedForReminders

              const firstNameValue = responseData.user.firstName
              const lastNameValue = responseData.user.lastName
              const publicProfile = responseData.user.publicProfile

              this.setState({
                user, roleName, remoteAuth, activeOrg, myOrgs, openToMentoring, workMode,
                subscribed, subscribedForReminders,
                firstNameValue, lastNameValue, publicProfile
              });

            } else {
              // this.setState({ isLoading: false })
            }
          }).catch((error) => {
              console.log('Profile details fetch did not work', error);
              // this.setState({ isLoading: false })
          });
        }
      }
    }

    retrieveTrackPosts(org, parentPostId) {
      Axios.get('/api/postings/user', { params: { orgCode: org } })
      .then((response) => {
        console.log('Posted jobs query attempted', response.data);

        if (response.data.success) {
          console.log('posted jobs query worked')

          if (response.data.postings.length !== 0) {
            let jobs = response.data.postings
            console.log('testing 1')
            let postOptions = []
            let parentPost = null
            for (let i = 1; i <= jobs.length; i++) {
              if (jobs[i - 1].postType === "Track") {
                postOptions.push(jobs[i - 1])
                console.log('testing 2', parentPostId)
                if (parentPostId) {
                  if (parentPostId === jobs[i - 1]._id) {
                    parentPost = jobs[i - 1]
                    console.log('testing 3', postOptions, parentPost)
                  }
                } else if (!parentPost) {
                  parentPost = jobs[i - 1]
                }
              }
            }

            console.log('testing 4', postOptions, parentPost)
            this.setState({ postOptions, parentPost });
          }

        } else {
          console.log('posted jobs query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Posted jobs query did not work for some reason', error);
      });
    }

    shareCompass(event) {
        console.log('share Compass tapped')
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event)

      if (event.target.name === 'search') {
        this.setState({ searchString: event.target.value, textFormHasChanged: true })
      } else if (event.target.name.includes('employerContact')) {
        let orgContacts = this.state.orgContacts
        const nameArray = event.target.name.split('|')
        const index = Number(nameArray[1])

        let objectName = ''
        if (nameArray[0] === 'employerContactFirstName') {
          objectName = 'contactFirstName'
        } else if (nameArray[0] === 'employerContactLastName') {
          objectName = 'contactLastName'
        } else if (nameArray[0] === 'employerContactEmail') {
          objectName = 'contactEmail'
        } else if (nameArray[0] === 'employerContactPhone') {
          objectName = 'contactPhone'
        } else if (nameArray[0] === 'employerContactTitle') {
          objectName = 'contactTitle'
        }

        orgContacts[index][objectName] = event.target.value
        this.setState({ orgContacts, formHasChanged: true })
      } else if (event.target.name.includes('orgContact')) {
        let orgContacts = this.state.orgContacts
        const nameArray = event.target.name.split('|')
        const index = Number(nameArray[1])

        orgContacts[index][nameArray[0]] = event.target.value
        console.log('show values: ', orgContacts, nameArray[0])
        this.setState({ orgContacts, formHasChanged: true })
      // } else if (event.target.name === 'orgCode') {
      //   this.setState({ orgCode: event.target.value })
      } else if (event.target.name === 'firstName') {
          console.log('firstNameSelectedHandler changed', event)
          this.setState({ firstNameValue: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'lastName') {
          console.log('lastNameSelectedHandler changed', event)
          this.setState({ lastNameValue: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'linkedInURL') {
          console.log('linkedInURLSelectedHandler changed', event)
          this.setState({ linkedInURLValue: event.target.value, textFormHasChanged: true })

      } else if (event.target.name === 'resumeURL') {
          console.log('resumeURLSelectedHandler changed', event)
          this.setState({ resumeURLValue: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'customWebsiteURL') {
          console.log('customWebsiteURLSelectedHandler changed', event)
          this.setState({ customWebsiteURLValue: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'myersBriggs') {
          console.log('myersBriggsSelectedHandler changed', event)
          this.setState({ myersBriggsValue: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'parentPost') {
        let parentPost = this.state.parentPost
        for (let i = 1; i <= this.state.postOptions.length; i++) {
          if (this.state.parentOptions[i - 1].title === event.target.value) {
            parentPost = this.state.parentOptions[i - 1]
          }
        }

        this.setState({ parentPost })
      } else if (event.target.name.includes('careerTrackOptions')) {
        let careerTrackOptions = this.state.careerTrackOptions
        let nameArray = event.target.name.split("|")
        let index = Number(nameArray[1]) - 1
        console.log('show ct: ', careerTrackOptions, nameArray, Number(nameArray[1]), index)

        careerTrackOptions[index] = event.target.value

        this.setState({ careerTrackOptions })
      } else if (event.target.name.includes('ccEmail')) {
        let ccEmails = this.state.ccEmails
        let nameArray = event.target.name.split("|")
        let index = Number(nameArray[1])

        ccEmails[index] = event.target.value
        this.setState({ ccEmails })
      } else if (event.target.name === 'orgLogo') {
        console.log('orgLogoSelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ orgLogoImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);

            this.saveFile(event.target.name, event.target.files[0])
        }
      } else if (event.target.name === 'orgLogoWhite') {
        console.log('orgLogoWhiteSelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ orgLogoWhiteImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);

            this.saveFile(event.target.name, event.target.files[0])
        }
      } else if (event.target.name === 'employerLogo') {
        console.log('employerLogoSelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ employerLogoImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);

            this.saveFile(event.target.name, event.target.files[0])
        }
      } else if (event.target.name === 'employerLogoWhite') {
        console.log('employerLogoWhiteSelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ employerLogoWhiteImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);

            this.saveFile(event.target.name, event.target.files[0])
        }
      } else if (event.target.name === 'subscribed') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ subscribed: value, formHasChanged: true })
        this.savePersonalInfo(event.target.name,value)
      } else if (event.target.name === 'subscribedForReminders') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ subscribedForReminders: value, formHasChanged: true })
        this.savePersonalInfo(event.target.name,value)
      } else if (event.target.name.includes('video|')) {
        let videos = this.state.videos
        const index = Number(event.target.name.split("|")[1])
        videos[index] = event.target.value
        this.setState({ videos })

      } else if (event.target.name === 'employerDepartment') {
        this.suggestItems('employerDepartment', event.target.value)
        this.setState({ [event.target.name]: event.target.value })
      } else {
        this.setState({ [event.target.name]: event.target.value, formHasChanged: true })
      }
    }

    suggestItems(type, searchString) {
      console.log('suggestItems called')

      if (type === 'employerDepartment') {
        if (!searchString || searchString === '') {
          this.setState({ searchString, suggestedDepartments: null })
        } else {
          // this.setState({ searchString })

          let suggestedDepartments = []

          const searchStringRegEx = new RegExp(searchString.toLowerCase())

          for (let i = 1; i <= this.state.departmentOptions.length; i++) {
            if (searchStringRegEx.test(this.state.departmentOptions[i - 1].toLowerCase())) {

              suggestedDepartments.push(this.state.departmentOptions[i - 1])
            }
          }

          this.setState({ suggestedDepartments })

        }
      }
    }

    suggestedItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'employerDepartment') {

        const employerDepartment = passedItem
        const suggestedDepartments = null

        this.setState({ employerDepartment, suggestedDepartments })

      }
    }

    saveFile(category, passedFile) {
      console.log('saveFile called', category, passedFile)

      let orgCode = this.state.activeOrg
      if (category === 'employerLogo' || category === 'employerLogoWhite') {
        orgCode = this.state.accountCode
      }

      const fileName = passedFile.name
      let originalName = category + '|' + orgCode + '|' + fileName + '|' + new Date()

      let fileData = new FormData();
      // const fileName = 'profileImage'
      // const fileName = 'newFile'
      fileData.append('baseFileName', passedFile, originalName)

      fetch("/api/file-upload", {
          mode: 'no-cors',
          method: "POST",
          body: fileData
      }).then(function (res) {
        console.log('what is the logo response');
        if (res.ok) {

          const serverPostSuccess = true
          const serverSuccessMessage = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
          if (category === 'orgLogo') {
            this.setState({ serverPostSuccess, serverSuccessMessage, orgLogoFile: passedFile })
          } else if (category === 'orgLogoWhite') {
            this.setState({ serverPostSuccess, serverSuccessMessage, orgLogoWhiteFile: passedFile })
          } else if (category === 'employerLogo') {
            this.setState({ serverPostSuccess, serverSuccessMessage, employerLogoFile: passedFile })
          } else if (category === 'employerLogoWhite') {
            this.setState({ serverPostSuccess, serverSuccessMessage, employerLogoWhiteFile: passedFile })
          }

          const self = this

          res.json()
          .then(function(data) {
            console.log('show data: ', data)
            let newFilePath = data.filePath
            console.log('show filePath: ', newFilePath)

            let existingFilePath = null
            if (category === 'orgLogo') {
              existingFilePath = self.state.orgLogoPath
            } else if (category === 'orgLogoWhite') {
              existingFilePath = self.state.orgLogoWhite
            } else if (category === 'employerLogo') {
              existingFilePath = self.state.employerLogo
            } else if (category === 'employerLogoWhite') {
              existingFilePath = self.state.employerLogoWhite
            }

            // remove existing file
            if (existingFilePath) {
              const deleteArray = existingFilePath.split("amazonaws.com/")
              console.log('show deleteArrary: ', deleteArray)
              const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
              console.log('show deleteKey: ', deleteKey)

              Axios.put('/api/file', { deleteKey })
              .then((response) => {
                console.log('tried to delete', response.data)
                if (response.data.success) {
                  //save values
                  console.log('File delete worked');

                  if (category === 'orgLogo') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', orgLogoPath: newFilePath
                    })
                  } else if (category === 'orgLogoWhite') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', orgLogoWhitePath: newFilePath
                    })
                  } else if (category === 'employerLogo') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', employerLogoPath: newFilePath
                    })
                  } else if (category === 'employerLogoWhite') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', employerLogoWhitePath: newFilePath
                    })
                  }

                } else {
                  console.error('there was an error saving the file');
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: response.data.message,
                  })
                }
              }).catch((error) => {
                  console.log('The saving did not work', error);
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: error,
                  })
              });
            }
          })

        } else if (res.status === 401) {
          //unauthorized
          this.setState({
              serverSuccessOrgLogo: false,
              serverErrorMessageOrgLogo: 'There was an error saving logo: Unauthorized save.'
          })
        }
      }.bind(this), function (e) {
        //there was an error
        this.setState({
            serverSuccessOrgLogo: false,
            serverErrorMessageOrgLogo: 'There was an error saving logo:' + e
        })
      }.bind(this));
    }

    contactUs(event) {
        console.log('contact us tapped')
    }

    addItem(type, value) {
      console.log('addItem called', type, value)

      if (type === 'timeframe') {
        let hireTimeframes = this.state.hireTimeframes
        if (hireTimeframes.includes(value)) {
          const index = hireTimeframes.indexOf(value)
          if (index > -1) {
            hireTimeframes.splice(index, 1);
          }
        } else {
          hireTimeframes.push(value)
        }

        this.setState({ hireTimeframes })
      } else if (type === 'employerDepartment') {
        let employerDepartments = this.state.employerDepartments
        if (employerDepartments) {
          employerDepartments.push(this.state.employerDepartment)
        } else {
          employerDepartments = [this.state.employerDepartment]
        }
        this.setState({ employerDepartments, employerDepartment: '', suggestedDepartments: null })
      } else if (type === 'orgDepartment') {
        let orgDepartments = this.state.orgDepartments
        const code = this.state.orgDepartment.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        if (orgDepartments) {
          orgDepartments.push({ code, name: this.state.orgDepartment})
        } else {
          orgDepartments = [{ code, name: this.state.orgDepartment }]
        }
        this.setState({ orgDepartments, orgDepartment: '' })

      } else if (type === 'caseNoteTagOption') {
        let caseNoteTagOptions = this.state.caseNoteTagOptions

        if (caseNoteTagOptions) {
          caseNoteTagOptions.push(this.state.caseNoteTagOption)
        } else {
          caseNoteTagOptions = [this.state.caseNoteTagOption]
        }
        this.setState({ caseNoteTagOptions, caseNoteTagOption: '' })
      } else if (type === 'meetingTagOption') {
        let meetingTagOptions = this.state.meetingTagOptions
        const code = this.state.meetingTagOption.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        if (meetingTagOptions) {
          meetingTagOptions.push({ code, name: this.state.meetingTagOption })
        } else {
          meetingTagOptions = [{ code, name: this.state.meetingTagOption }]
        }

        this.setState({ meetingTagOptions, meetingTagOption: '' })

      } else if (type === 'video') {
        let videos = this.state.videos
        if (videos) {
          videos.push('')
        } else {
          videos = ['']
        }
        this.setState({ videos })
      } else if (type === 'meetingOutcomeOption') {
        let meetingOutcomeOptions = this.state.meetingOutcomeOptions
        // const code = this.state.meetingOutcomeOption.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        if (meetingOutcomeOptions) {
          meetingOutcomeOptions.push(this.state.meetingOutcomeOption)
        } else {
          meetingOutcomeOptions = [this.state.meetingOutcomeOption]
        }

        this.setState({ meetingOutcomeOptions, meetingOutcomeOption: '' })

      } else if (type === 'meetingPurposeOption') {
        let meetingPurposeOptions = this.state.meetingPurposeOptions
        if (meetingPurposeOptions) {
          meetingPurposeOptions.push({ name: this.state.meetingOutcomeName, meetingOutcomeOptions: this.state.meetingOutcomeOptions })
        } else {
          meetingPurposeOptions = [{ name: this.state.meetingOutcomeName, meetingOutcomeOptions: this.state.meetingOutcomeOptions }]
        }

        // console.log('show meetingPurposeOptions: ', meetingPurposeOptions)

        this.closeModal()
        this.setState({ meetingPurposeOptions, meetingOutcomeName: '', meetingOutcomeOptions: null })

      }
    }

    removeItem(type, index) {
      console.log('removeItem called', type, index)

      if (type === 'employerDepartment') {
        let employerDepartments = this.state.employerDepartments
        employerDepartments.splice(index, 1)
        this.setState({ employerDepartments })
      } else if (type === 'orgDepartment') {
        let orgDepartments = this.state.orgDepartments
        orgDepartments.splice(index, 1)
        this.setState({ orgDepartments })
      } else if (type === 'caseNoteTagOption') {
        let caseNoteTagOptions = this.state.caseNoteTagOptions
        caseNoteTagOptions.splice(index, 1)
        this.setState({ caseNoteTagOptions })
      } else if (type === 'video') {
        let videos = this.state.videos
        videos.splice(index,1)
        this.setState({ videos })
      } else if (type === 'meetingTagOptions') {
        let meetingTagOptions = this.state.meetingTagOptions
        meetingTagOptions.splice(index, 1)
        this.setState({ meetingTagOptions })
      } else if (type === 'meetingOutcomeOptions') {
        let meetingOutcomeOptions = this.state.meetingOutcomeOptions
        meetingOutcomeOptions.splice(index, 1)
        this.setState({ meetingOutcomeOptions })
      } else if (type === 'meetingPurposeOptions') {
        let meetingPurposeOptions = this.state.meetingPurposeOptions
        meetingPurposeOptions.splice(index, 1)
        this.setState({ meetingPurposeOptions })
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showExportData: false, showGrades: false, showWBLPreferences: false,
        showInviteMembersWidget: false, showCertificate: false, selectedCertificate: null, selectedIndex: null,
        certificateName: null, certificateIssuer: null,
        certificateEstimatedHours: null, certificatePathway: null, certificateFunction: null, certificateIndustry: null,
        certificateDescription: null, viewArchivedMembers: false, showCredits: false
      });
    }

    renderDataToShare(passedIndex) {
      console.log('renderDataToShare called', passedIndex)

      let rows = []
      for (let i = 1; i <= this.state.dataToShare.length; i++) {
        const index = i - 1
        // console.log('log index: ', index)
        const remainder = index % 3
        console.log('show modulo: ', index, remainder)

        if (passedIndex === remainder) {
          if (this.state.dataToShare[index]) {
            rows.push(
              <div key={i.toString + "dataToShare"}>
                <div className="float-left right-margin">
                  <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-20" />
                </div>
                <div className="float-left">
                  <p>{this.state.dataToShare[index]}</p>
                </div>
                <div className="clear" />
                <div className="half-spacer" />
              </div>
            )
          }
        }
      }

      return rows
    }

    saveSettings(change) {
      console.log('saveSettings called', change)

      if (window.location.pathname.includes ('/advisor')) {

      } else {
        // student
        const emailId = this.state.emailId
        const openToMentoring = change
        const updatedAt = new Date()

        this.setState({ openToMentoring })

        const userObject = { emailId, openToMentoring, updatedAt }
        Axios.post('/api/users/profile/details', userObject)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('User update worked', response.data);
            this.setState({ textFormHasChanged: false, serverSuccessMessage: 'Profile details saved successfully!' })

          } else {
            console.error('there was an error saving to user profile');
            this.setState({ textFormHasChanged: false, serverErrorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Saving to user profile did not work', error);
        });
      }
    }

    renderCourses() {
      console.log('renderCourses called')

      let rows = []
      for (let i = 1; i <= this.state.courses.length; i++) {

        rows.push(
          <div key={'courses|' + i.toString()}>
            <div className="float-left full-width top-margin">
              <div className="light-background padding-20">
                <div className="fixed-column-90">
                  <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                  <img src={courseIconBlue} alt="Compass search icon" className="image-auto-80"/>
                </div>
                <div className="calc-column-180 row-10 horizontal-padding-5">
                  <p className="heading-text-2">{this.state.courses[i - 1].name}</p>
                  <p className="description-text-3">{this.state.courses[i - 1].description}</p>

                  <div>
                    <p>{this.state.courses[i - 1].enrollmentCount} students enrolled</p>
                  </div>

                  <div className="spacer" />

                  <div className="tag-container float-left dark-background" >
                    <p className="description-text-2 white-text">{this.state.courses[i - 1].gradeLevel}</p>
                  </div>
                  <div className="tag-container float-left dark-background" >
                    <p className="description-text-2 white-text">{this.state.courses[i - 1].knowledgeLevel}</p>
                  </div>
                  <div className="clear" />
                </div>
                <div className="clear" />
              </div>
            </div>
          </div>
        )
      }

      return rows
    }

    exportToCSV(reportType) {
      console.log('exportToCSV called')

      this.setState({ serverErrorMessage: 'There was an error exporting to CSV. Please email help@guidedcompass.com immediately!', isLoading: true })

      let csvData = []
      if (reportType === 'grades') {

        console.log('pull postings')

        csvData.push(
          [ "Post Date","Grade","Project Name","Student First Name","Student Last Name","Student Email","Industry Rep. First Name","Industry Rep. Last Name","Industry Rep. Email","Is Transparent","Feedback"]
        )

        const grades = this.state.grades
        if (grades && grades.length > 0) {
          for (let i = 1; i <= grades.length; i++) {
            console.log(i, grades[i - 1])

            //replace commas
            let commaStartingValue = /,/g
            let commaReplacementValue = ";"

            const postDate = grades[i - 1].updatedAt
            let grade = grades[i - 1].grade
            if (!grade) {
              grade = 'N/A'
            }
            let projectName = this.state.projectNames[i - 1]
            let studentFirstName = this.state.students[i - 1].studentFirstName
            let studentLastName = this.state.students[i - 1].studentLastName
            let studentEmail = this.state.students[i - 1].studentEmail
            let industryFirstName = this.state.grades[i - 1].contributorFirstName
            let industryLastName = this.state.grades[i - 1].industryLastName
            let industryEmail = this.state.grades[i - 1].industyEmail
            let isTransparent = this.state.grades[i - 1].isTransparent

            let feedback = this.state.grades[i - 1].feedback.replace(commaStartingValue,commaReplacementValue)
            if (!feedback) {
              feedback = ''
            }

            csvData.push([
              postDate, grade, projectName, studentFirstName, studentLastName, studentEmail,
              industryFirstName, industryLastName, industryEmail, isTransparent, feedback
            ])
          }

          let csvContent = "data:text/csv;charset=utf-8,";
          csvData.forEach(function(rowArray){
             let row = rowArray.join(",");
             csvContent += row + "\r\n";
          });

          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "grades-report.csv");
          document.body.appendChild(link); // Required for FF

          link.click();

          this.setState({ isLoading: false })

        } else {
          this.setState({ clientErrorMessage: 'There is no data to export', isLoading: false })
        }
      } else if (reportType === 'basicInfo') {

        // firstName, lastName, username, phoneNumber, oauthUid, schoolDistrict
        // school, major, gradYear,
        // resumeURL, coverLetterURL, letterOfRecommendationURL, transcriptURL, linkedInURL, customWebsiteURL
        // favoritesArray,
        // race, gender, veteran, workAuthorization

        // numberOfMembers, householdIncome, fosterYouth, homeless, incarcerated, adversityList,
        // matchingPreferences, matchingUseCases

        console.log('user: ', this.state.user)

        const user = this.state.user
        if (user) {

          csvData.push(
            [ "First Name","Last Name","Username","Phone Number","oAuth ID","School District","School","Major / Pathway","Grad Year","Resume URL","LinkedIn URL","Portfolio URL","Favorites [Ids]"]
          )

          //replace commas
          let commaStartingValue = /,/g
          let commaReplacementValue = ";"

          // firstName, lastName, username, phoneNumber, oauthUid, schoolDistrict
          // school, major, gradYear,
          // resumeURL, coverLetterURL, letterOfRecommendationURL, transcriptURL, linkedInURL, customWebsiteURL
          // favoritesArray,

          const firstName = user.firstName
          const lastName = user.lastName
          const username = user.username
          const phoneNumber = user.phoneNumber
          const oauthUid = user.oauthUid
          let schoolDistrict = ''
          if (user.schoolDistrict) {
            schoolDistrict = user.schoolDistrict.replace(commaStartingValue,commaReplacementValue)
          }

          let school = ''
          if (user.school) {
            school = user.school.replace(commaStartingValue,commaReplacementValue)
          }

          let major = ''
          if (user.major) {
            major = user.major.replace(commaStartingValue,commaReplacementValue)
          }

          const gradYear = user.gradYear

          let resumeURL = ''
          if (user.resumeURL) {
            resumeURL = user.resumeURL.replace(/#/g,'')
          }

          let linkedInURL = ''
          if (user.linkedInURL) {
            linkedInURL = user.linkedInURL.replace(/#/g,'')
          }

          let portfolioURL = ''
          if (user.customWebsiteURL) {
            portfolioURL = user.customWebsiteURL.replace(/#/g,'')
          }
          let favorites = user.favoritesArray.toString()
          if (user.favoritesArray) {
            favorites = user.favoritesArray.toString().replace(commaStartingValue,commaReplacementValue)
          }

          csvData.push([
            firstName, lastName, username, phoneNumber, oauthUid, schoolDistrict, school, major, gradYear,
            resumeURL, linkedInURL, portfolioURL, favorites
          ])

          let csvContent = "data:text/csv;charset=utf-8,";
          csvData.forEach(function(rowArray){
             let row = rowArray.join(",");
             csvContent += row + "\r\n";
          });

          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", reportType + ".csv");
          document.body.appendChild(link); // Required for FF

          link.click();

          this.setState({ isLoading: false })

        } else {
          this.setState({ clientErrorMessage: 'There is no data to export', isLoading: false })
        }
      } else if (reportType === 'resume') {

        // const preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
        // const postHtml = "</body></html>";
        // const html = preHtml+document.getElementById('exportContent').innerHTML+postHtml;
        //
        // const blob = new Blob(['\ufeff', html], {
        //     type: 'application/msword'
        // });
        //
        // // Specify link url
        // const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        //
        // // Specify file name
        // const cuFirstName = localStorage.getItem('firstName');
        // const cuLastName = localStorage.getItem('lastName');
        //
        // const filename = cuFirstName + ' ' + cuLastName + '.doc'
        //
        // // Create download link element
        // const downloadLink = document.createElement("a");
        //
        // document.body.appendChild(downloadLink);
        //
        // if (navigator.msSaveOrOpenBlob ){
        //     navigator.msSaveOrOpenBlob(blob, filename);
        // } else{
        //     // Create a link to the file
        //     downloadLink.href = url;
        //
        //     // Setting the file name
        //     downloadLink.download = filename;
        //
        //     //triggering the function
        //     downloadLink.click();
        // }
        //
        // document.body.removeChild(downloadLink);

      }
    }

    savePersonalInfo(type, value) {
      console.log('savePersonalInfo', type, value)

      this.setState({ serverSuccessMessage: null, serverErrorMessage: null })

      if (!this.state.firstNameValue || this.state.firstNameValue === '') {
        this.setState({ serverPostSuccess: false, serverErrorMessage: 'Pleas add your first name'})
      } else if (!this.state.lastNameValue || this.state.lastNameValue === '') {
        this.setState({ serverPostSuccess: false, serverErrorMessage: 'Pleas add your last name'})
      } else {
        console.log('saving profile')
        const emailId = this.state.emailId
        const firstNameValue = this.state.firstNameValue
        const lastNameValue = this.state.lastNameValue

        let unsubscribed = !this.state.subscibed
        let subscribedForReminders = this.state.subscribedForReminders
        if (type === 'subscribed') {
          unsubscribed = !value
          // if (!value) {
          //   unsubscribed = true
          // }
        } else if (type === 'subscribedForReminders'){
          subscribedForReminders = value
        }

        const updatedAt = new Date()

        const userObject = { emailId, firstNameValue, lastNameValue, unsubscribed, subscribedForReminders, updatedAt }
        Axios.post('/api/users/profile/details', userObject)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('User update worked', response.data);
            this.setState({
              subscribed: !unsubscribed, subscribedForReminders,
              serverPostSuccess: true,
              serverSuccessMessage: 'Profile details saved successfully!'
            })

          } else {
            console.error('there was an error saving to user profile');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Saving to user profile did not work', error);
        });
      }

    }

    saveOrgInfo() {
      console.log('saveOrgInfo clicked');

      const orgCode = this.state.activeOrg

      const orgName = this.state.orgName
      const orgURL = this.state.orgURL
      const orgProgramName = this.state.orgProgramName
      const orgDescription = this.state.orgDescription
      const orgMission = this.state.orgMission
      const contactFirstName = this.state.contactFirstName
      const contactLastName = this.state.contactLastName
      const contactTitle = this.state.contactTitle
      const contactEmail = this.state.contactEmail
      const chatEmail = this.state.chatEmail
      const careerTracks = this.state.careerTrackOptions

      const automaticallyAttach = this.state.automaticallyAttach
      let parentPostId = ''
      if (this.state.parentPost) {
        parentPostId = this.state.parentPost._id
      }

      const ccEmails = this.state.ccEmails

      const studentAliasSingular = this.state.studentAliasSingular
      const studentAliasPlural = this.state.studentAliasPlural
      const mentorAliasSingular = this.state.mentorAliasSingular
      const mentorAliasPlural = this.state.mentorAliasPlural

      const studentBenefitsText = this.state.studentBenefitsText
      const mentorBenefitsText = this.state.mentorBenefitsText
      const employerBenefitsText = this.state.employerBenefitsText

      const studentLongBenefitsText = this.state.studentLongBenefitsText
      const mentorLongBenefitsText = this.state.mentorLongBenefitsText
      const employerLongBenefitsText = this.state.employerLongBenefitsText

      const standardAssessments = this.state.standardAssessments
      const hiddenTabs = this.state.hiddenTabs
      const employerFormTitle = this.state.employerFormTitle
      const confirmationMessage = this.state.confirmationMessage
      const employerDashboardMessage = this.state.employerDashboardMessage
      const internshipEmptyStateMessage = this.state.internshipEmptyStateMessage

      const disableFreeformChatbotMessaging = this.state.disableFreeformChatbotMessaging
      const disableChatbotPrompts = this.state.disableChatbotPrompts
      const studentSignUpNotification = this.state.studentSignUpNotification
      const employerSignUpNotification = this.state.employerSignUpNotification
      const departments = this.state.orgDepartments
      const caseNoteTagOptions = this.state.caseNoteTagOptions
      const meetingTagOptions = this.state.meetingTagOptions
      const meetingPurposeOptions = this.state.meetingPurposeOptions

      const updatedAt = new Date()

      Axios.post('/api/org/update', {
        orgCode, orgName, orgURL, orgProgramName, orgDescription, orgMission,
        automaticallyAttach, parentPostId, careerTracks, ccEmails,
        studentAliasSingular, studentAliasPlural, mentorAliasSingular, mentorAliasPlural,
        studentBenefitsText, mentorBenefitsText, employerBenefitsText,
        studentLongBenefitsText, mentorLongBenefitsText, employerLongBenefitsText,
        standardAssessments, hiddenTabs, employerFormTitle, confirmationMessage, employerDashboardMessage, internshipEmptyStateMessage,
        disableFreeformChatbotMessaging, disableChatbotPrompts,
        studentSignUpNotification, employerSignUpNotification,
        departments, caseNoteTagOptions, meetingTagOptions, meetingPurposeOptions,
        contactFirstName, contactLastName, contactTitle, contactEmail, chatEmail, updatedAt
       })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Org info update worked', response.data);
          this.setState({

            serverPostSuccess: true,
            serverSuccessMessage: 'Org information saved successfully!'
          })

        } else {
          console.error('there was an error updating the org info');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('Org info save did not work', error);
      });
    }

    saveAccountInfo() {
      console.log('saveAccountInfo clicked');

      const accountCode = this.state.accountCode

      const isIntermediary = this.state.isIntermediary
      const employerName = this.state.companyName
      const employerURL = this.state.companyURL
      const employerType = this.state.companyType
      const employerIndustry = this.state.companyIndustry
      const employeeCount = this.state.employeeCount
      const employeeGrowth = this.state.employeeGrowth
      const employerLocation = this.state.employerLocation
      const description = this.state.description
      const employerCulture = this.state.employerCulture

      const contactFirstName = this.state.contactFirstName
      const contactLastName = this.state.contactLastName
      const contactTitle = this.state.contactTitle
      const contactEmail = this.state.contactEmail
      const contactPhone = this.state.contactPhone
      const orgContacts = this.state.orgContacts

      const officeType = this.state.officeType
      const politicalAlignment = this.state.politicalAlignment
      const stateRegistration = this.state.stateRegistration
      const congressionalDistrict = this.state.congressionalDistrict
      const hasFunds = this.state.hasFunds
      const hostUndocumented = this.state.hostUndocumented
      const hireTimeframes = this.state.hireTimeframes
      const departments = this.state.employerDepartments
      const videos = this.state.videos

      const activeOrg = this.state.activeOrg

      Axios.post('/api/account/update', {
        accountCode, isIntermediary, employerName, employerURL, employerType, employerIndustry,
        employeeCount, employeeGrowth, employerLocation, description, employerCulture, contactFirstName, contactLastName,
        contactTitle, contactEmail, contactPhone, orgContacts, activeOrg, hireTimeframes, departments, videos,
        officeType, politicalAlignment, stateRegistration, congressionalDistrict, hasFunds, hostUndocumented })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Account update worked', response.data);
          this.setState({

            serverPostSuccess: true,
            serverSuccessMessage: 'Account information saved successfully!'
          })

        } else {
          console.error('there was an error updating the account info');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('Account info save did not work', error);
      });
    }

    renderAnswerChoices() {

      let rows = []
      for (let i = 1; i <= this.state.careerTrackOptions.length; i++) {
        console.log(i);

        let eventName = "careerTrackOptions|" + i.toString()
        rows.push(
          <div key={i}>
            <div className="choice-container-1">
              <img src={answerBubbleIcon} alt="Compass dashboard icon" className="answer-bubble"/>
            </div>
            <div className="choice-container-2">
              <input type="text" className="adjacent-text-field" placeholder="Create a career track..." name={eventName} value={this.state.careerTrackOptions[i - 1]} onChange={this.formChangeHandler}></input>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows
    }

    addAnswerChoice(index) {
      console.log('addAnswerChoice called')

      let careerTrackOptions = this.state.careerTrackOptions
      careerTrackOptions.push('')

      this.setState({ careerTrackOptions })
    }

    renderTags(type, passedArray) {
      console.log('renderTags ', type, passedArray)

      if (passedArray) {
        return (
          <div key={type + "|0"}>
            <div className="spacer" />
            {passedArray.map((value, optionIndex) =>
              <div key={type + "|" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeItem(type, optionIndex)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <button className="background-button" disabled={(type === 'certificateOptions') ? false : true} onClick={() => this.setState({ modalIsOpen: true, showCertificate: true, selectedCertificate: value, selectedIndex: optionIndex, certificateName: value.name, certificateIssuer: value.issuer, certificateEstimatedHours: value.estimatedHours, certificatePathway: value.pathway, certificateFunction: value.workFunction, certificateIndustry: value.industry, certificateDescription: value.description })}>
                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      {(type === 'orgDepartment' || type === 'certificateOptions' || type === 'meetingTagOptions' || type === 'meetingPurposeOptions') ? (
                        <p className="description-text-2">{value.name}</p>
                      ) : (
                        <p className="description-text-2">{value}</p>
                      )}

                    </div>
                    <div className="half-spacer" />
                  </div>
                </button>
              </div>
            )}
          </div>
        )
      }
    }

    renderCCEmails() {
      console.log('renderCCEmails called')

      let rows = []
      for (let i = 1; i <= this.state.ccEmails.length; i++) {

        const index = i - 1

        rows.push(
          <div key={i}>
            <input type="text" className="text-field" placeholder="Add the cc email..." name={"ccEmail|" + index} value={this.state.ccEmails[index]} onChange={this.formChangeHandler}></input>
            <div className="half-spacer" />
          </div>
        )
      }

      return rows
    }

    addCCEmail() {
      console.log('addCCEmail')

      let ccEmails = this.state.ccEmails
      ccEmails.push('')
      this.setState({ ccEmails })
    }

    optionClicked(type, value, index) {
      console.log('optionClicked called', type, value, index)

      if (type === 'wblPreference') {
        let wblPreferences = this.state.wblPreferences
        if (wblPreferences && wblPreferences.length > 0) {

          if (wblPreferences.includes(value)) {

            const removeIndex = wblPreferences.indexOf(value)
            wblPreferences.splice(removeIndex, 1)

          } else {
            wblPreferences.push(value)
          }
        } else {
          wblPreferences.push(value)
        }
        this.setState({ wblPreferences })
      } else if (type === 'hiddenTabs') {
        let hiddenTabs = this.state.hiddenTabs
        hiddenTabs[index]['hidden'] = value
        this.setState({ hiddenTabs })
      } else {
        let wblPreferences = this.state.wblPreferences
        if (wblPreferences && wblPreferences.length > 0) {

          if (wblPreferences.includes(value)) {

            const removeIndex = wblPreferences.indexOf(value)
            wblPreferences.splice(removeIndex, 1)

          } else {
            wblPreferences.push(value)
          }
        } else {
          wblPreferences.push(value)
        }
        this.setState({ wblPreferences })
      }
    }

    savePreferences() {
      console.log('savePreferences called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const orgCode = this.state.activeOrg
      const includeGuidedCompass = this.state.includeGuidedCompass
      let wblPreferences = this.state.wblPreferences
      const updatedAt = new Date()

      Axios.post('/api/org/update', {
        orgCode, includeGuidedCompass, wblPreferences, updatedAt
       })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Org info update worked', response.data);
          this.setState({ successMessage: 'Org information saved successfully!' })

        } else {
          console.error('there was an error updating the org info');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('Org info save did not work', error);
          if (error) {
            this.setState({ errorMessage: error.toString() })
          }
      });
    }

    switchMode(change) {
      console.log('switchMode called', change)

      this.setState({ isSaving: true })

      const emailId = this.state.emailId
      const workMode = change

      Axios.post('/api/users/profile/details', {  emailId, workMode })
      .then((response) => {
        console.log('Work mode attempted', response.data);

          if (response.data.success) {
            console.log('work mode query worked')

            localStorage.setItem('workMode', workMode)
            this.setState({ workMode, isSaving: false })

          } else {
            console.log('work mode query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Work mode query did not work for some reason', error);
      });
    }

    renderOrgInformation() {
      console.log('renderOrgInformation called')

      return (
        <div>
          {/*
          <div className="row-10">
            <p className="subtitle">Your Information</p>
            <div className="clear" />
          </div>

          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">First Name</label>
              <input type="text" className="text-field" placeholder="First name..." name="firstName" value={this.state.firstNameValue} onChange={this.formChangeHandler}></input>
            </div>

            <div className="container-right">
              <label className="profile-label">Last Name</label>
              <input type="text" className="text-field" placeholder="Last name..." name="lastName" value={this.state.lastNameValue} onChange={this.formChangeHandler}></input>
            </div>

            <div className="clear"/>
          </div>

          { (this.state.serverPostSuccess) ? (
            <p className="success-message">{this.state.serverSuccessMessage}</p>
          ) : (
            <p className="error-message">{this.state.serverErrorMessage}</p>
          )}

          <div className="row-10">
            <button className="btn btn-primary" onClick={() => this.savePersonalInfo()}>Save Personal Info</button>
          </div>

          <div className="spacer" />

          <hr />

          <div className="spacer" /><div className="spacer" />*/}

          {(window.location.pathname.includes('/edit-organization-profile') || this.state.showOrgInfoInSettings) && (
            <div>
              {(!window.location.pathname.includes('/edit-organization-profile')) && (
                <div className="row-10">
                  <p className="heading-text-4">Org Information</p>
                </div>
              )}

              <div className="clear" />
              <div className="spacer" />

              <div className="container-left">
                <label className="profile-label">Organization Name</label>
                <input type="text" className="text-field" placeholder="Org name..." name="orgName" value={this.state.orgName} onChange={this.formChangeHandler}></input>
              </div>

              <div className="container-right">
                <label className="profile-label">Organization URL</label>
                <input type="text" className="text-field" placeholder="Org url..." name="orgURL" value={this.state.orgURL} onChange={this.formChangeHandler}></input>
              </div>

              <div className="clear"/>

              <div className="edit-profile-row">
                <label className="profile-label">Organization Primary Program Name (Optional)</label>
                <input type="text" className="text-field" placeholder="Org program name..." name="orgProgramName" value={this.state.orgProgramName} onChange={this.formChangeHandler}></input>
              </div>

              <div className="edit-profile-row">
                <label className="profile-label">Org Description</label>
                <textarea type="text" className="text-field" placeholder="What do you guys do..." name="orgDescription" value={this.state.orgDescription} onChange={this.formChangeHandler}></textarea>
              </div>

              <div className="edit-profile-row">
                <label className="profile-label">Org Mission</label>
                <textarea type="text" className="text-field" placeholder="Write your organization's mission..." name="orgMission" value={this.state.orgMission} onChange={this.formChangeHandler}></textarea>
              </div>

              <div className="edit-profile-row">
                <div className="container-left">
                    <label className="profile-label">Primary Contact First Name</label>
                    <input type="text" className="text-field" placeholder="First name..." name="contactFirstName" value={this.state.contactFirstName} onChange={this.formChangeHandler}></input>
                </div>

                <div className="container-right">
                    <label className="profile-label">Primary Contact Last Name</label>
                    <input type="text" className="text-field" placeholder="Last name..." name="contactLastName" value={this.state.contactLastName} onChange={this.formChangeHandler}></input>
                </div>
                <div className="clear" />
              </div>

              <div className="edit-profile-row">
                <div className="container-left">
                    <label className="profile-label">Primary Contact Title</label>
                    <input type="text" className="text-field" placeholder="Title..." name="contactTitle" value={this.state.contactTitle} onChange={this.formChangeHandler}></input>
                </div>

                <div className="container-right">
                    <label className="profile-label">Primary Contact Email</label>
                    <input type="text" className="text-field" placeholder="Email..." name="contactEmail" value={this.state.contactEmail} onChange={this.formChangeHandler}></input>
                </div>

                <div className="clear"/>
              </div>

              <div className="edit-profile-row">
                <div className="float-left">
                  <label className="profile-label">CC Emails (Colleagues Automatically CC'd)</label>
                </div>
                <div className="float-left left-padding">
                  <div className="spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                  <button className="background-button" onClick={() => this.addCCEmail()}>
                    <div>
                      <img src={addIconBlue} alt="Compass add circle outline icon" className="image-auto-22 padding-5 cta-border circle-radius" />
                    </div>
                  </button>
                </div>
                <div className="clear" />

                {this.renderCCEmails()}

              </div>

              <div className="edit-profile-row">
                <div className="container-left">
                    <label className="profile-label">Email for Chat Conversations</label>
                    <input type="text" className="text-field" placeholder="Email..." name="chatEmail" value={this.state.chatEmail} onChange={this.formChangeHandler}></input>
                </div>

                <div className="clear"/>
              </div>

              <div className="spacer" />

              {(!window.location.pathname.includes('/problem-platform')) && (
                <div>
                  <div className="spacer" /><div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <label className="heading-text-4">Configurations</label>
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p>Disable Free-Form Compass Assistant Messaging</p>
                      <label className="profile-descriptor">This disallows students / career-seekers from messaging the chatbot without clicking a prompt.</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <Switch
                        onChange={(change) => this.setState({ disableFreeformChatbotMessaging: change, formHasChanged: true })}
                        checked={this.state.disableFreeformChatbotMessaging}
                        id="normal-switch"
                      />
                    </div>
                    <div className="container-right">
                      <p>Disable Compass Assistant Prompts</p>
                      <label className="profile-descriptor">This hides student / career-seeker prompts to engage with chatbot in courses, goals, assessments, etc...</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <Switch
                        onChange={(change) => this.setState({ disableChatbotPrompts: change, formHasChanged: true })}
                        checked={this.state.disableChatbotPrompts}
                        id="normal-switch"
                      />
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p>Student Sign Up Notification</p>
                      <label className="profile-descriptor">Org contact is notified on new student sign up</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <Switch
                        onChange={(change) => this.setState({ studentSignUpNotification: change, formHasChanged: true })}
                        checked={this.state.studentSignUpNotification}
                        id="normal-switch"
                      />
                    </div>
                    <div className="container-right">
                      <p>Employer Sign Up Notification</p>
                      <label className="profile-descriptor">Org contact is notified on new employer sign up</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <Switch
                        onChange={(change) => this.setState({ employerSignUpNotification: change, formHasChanged: true })}
                        checked={this.state.employerSignUpNotification}
                        id="normal-switch"
                      />
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p>Student Alias [Singular]</p>
                      <label className="profile-descriptor">Singular term used to address students</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <input type="text" className="text-field" placeholder="(e.g., mentee)" name="studentAliasSingular" value={this.state.studentAliasSingular} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="container-right">
                      <p>Student Alias [Plural]</p>
                      <label className="profile-descriptor">Plural term used to address students</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <input type="text" className="text-field" placeholder="(e.g., mentees)" name="studentAliasPlural" value={this.state.studentAliasPlural} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p>Mentor Alias [Singular]</p>
                      <label className="profile-descriptor">Singular term used to address mentors</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <input type="text" className="text-field" placeholder="(e.g., coach)" name="mentorAliasSingular" value={this.state.mentorAliasSingular} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="container-right">
                      <p>Mentor Alias [Plural]</p>
                      <label className="profile-descriptor">Plural term used to address mentors</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <input type="text" className="text-field" placeholder="(e.g., coaches)" name="mentorAliasPlural" value={this.state.mentorAliasPlural} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p>Student Short Benefits Text</p>
                      <label className="profile-descriptor">This description will show up on dedicated landing pages to help students understand the benefits of signing up</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <textarea type="text" className="text-field" placeholder="Student short benefits text..." name="studentBenefitsText" value={this.state.studentBenefitsText} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="container-right">
                      <p>Student Long Benefits Text</p>
                      <label className="profile-descriptor">This description will show up on dedicated landing pages to help students understand the benefits of signing up</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <textarea type="text" className="text-field" placeholder="Student long benefits text..." name="studentLongBenefitsText" value={this.state.studentLongBenefitsText} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p>Mentor Short Benefits Text</p>
                      <label className="profile-descriptor">This description will show up on dedicated landing pages to help mentors understand the benefits of signing up</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <textarea type="text" className="text-field" placeholder="Mentor short benefits text..." name="mentorBenefitsText" value={this.state.mentorBenefitsText} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="container-right">
                      <p>Mentor Long Benefits Text</p>
                      <label className="profile-descriptor">This description will show up on dedicated landing pages to help mentors understand the benefits of signing up</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <textarea type="text" className="text-field" placeholder="Mentor long benefits text..." name="mentorLongBenefitsText" value={this.state.mentorLongBenefitsText} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p>Employer Short Benefits Text</p>
                      <label className="profile-descriptor">This description will show up on dedicated landing pages to help employers understand the benefits of signing up</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <textarea type="text" className="text-field" placeholder="Employer short benefits text..." name="employerBenefitsText" value={this.state.employerBenefitsText} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="container-right">
                      <p>Employer Long Benefits Text</p>
                      <label className="profile-descriptor">This description will show up on dedicated landing pages to help employers understand the benefits of signing up</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <textarea type="text" className="text-field" placeholder="Employer long benefits text..." name="employerLongBenefitsText" value={this.state.employerLongBenefitsText} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p>Employer Form Title</p>
                      <label className="profile-descriptor">Add a custom title for the employer form</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <input type="text" className="text-field" placeholder="Employer form title..." name="employerFormTitle" value={this.state.employerFormTitle} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="container-right">
                      <p>Confirmation Message</p>
                      <label className="profile-descriptor">Add a custom message for employers after they post project or work opportunities</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <textarea type="text" className="text-field" placeholder="Confirmation message..." name="confirmationMessage" value={this.state.confirmationMessage} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p>Employer Dashboard Message</p>
                      <label className="profile-descriptor">Add a custom message for the employer dashboard after they sign in</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <textarea type="text" className="text-field" placeholder="Employer dashboard message..." name="employerDashboardMessage" value={this.state.employerDashboardMessage} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="container-right">
                      <p>Internship Empty State Message</p>
                      <label className="profile-descriptor">Add a custom message for employers for when no interns have been referred to them yet</label>
                      <div className="clear" />
                      <div className="spacer" />
                      <textarea type="text" className="text-field" placeholder="Internship empty state message..." name="internshipEmptyStateMessage" value={this.state.internshipEmptyStateMessage} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="clear"/>

                  <div className="row-15">
                    <div className="float-left">
                      <p>Standard Assessments</p>
                      <label className="profile-descriptor">Which career assessments would you like students to take to match to mentors and work opportunities?</label>
                      <div className="clear" />
                      <div className="spacer" />
                    </div>

                    <div className="clear"/>
                    <div className="half-spacer" />

                    {this.state.availableAssessments.map((value, optionIndex) =>
                      <div key={optionIndex}>
                        <div className="float-left row-5 right-padding">
                          {(console.log('show sa2: ', this.state.standardAssessments))}
                          {(this.state.standardAssessments.some(sa => sa.name === value.name)) ? (
                            <button className="background-button tag-container-4 float-left top-margin-5 left-margin-5" onClick={() => this.optionClicked('standardAssessments',value, optionIndex)}>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2 white-text">{value.name}</p>
                                </div>
                              </div>
                            </button>
                          ) : (
                            <button className="background-button tag-container-5" onClick={() => this.optionClicked('standardAssessments',value, optionIndex)}>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2">{value.name}</p>
                                </div>
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="clear"/>

                  </div>

                  <div className="row-15">
                    <label className="profile-label">Hide Career-Seeker Tabs</label>

                    <div className="standard-border padding-20">
                      {this.state.hiddenTabs.map((value, optionIndex) =>
                        <div key={optionIndex} className="row-10">
                          <div className="calc-column-offset-90">
                            <p className="description-text-1">{value.name}</p>
                          </div>
                          <div className="fixed-column-90">
                            <Switch
                              onChange={(change) => this.optionClicked('hiddenTabs', change, optionIndex)}
                              checked={value.hidden}
                              id="normal-switch"
                            />
                          </div>
                          <div className="clear" />
                          {(this.state.hiddenTabs[optionIndex + 1]) && (
                            <div>
                              <div className="spacer" />
                              <hr />
                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  </div>

                  {(this.state.orgFocus === 'Placement') && (
                    <div>
                      <div className="clear"/>

                      {(this.state.postOptions.length !== 0) && (
                        <div>
                          <div className="container-left">
                            <label className="profile-label">Attach Employer Jobs to Track Posting</label>
                            <Switch
                              onChange={(change) => this.setState({ automaticallyAttach: change, formHasChanged: true })}
                              checked={this.state.automaticallyAttach}
                              id="normal-switch"
                            />
                          </div>

                          {this.state.automaticallyAttach && (
                            <div className="container-right">
                              <label className="profile-label">Parent Post</label>
                              <select name="parentPost" value={this.state.parentPost.title} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.postOptions.map(value =>
                                  <option key={value.key} value={value.title}>{value.title}</option>
                                )}
                              </select>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="clear" />
                  {/*
                  {(this.state.orgFocus === "Mentor" || this.state.orgFocus === "Placement") && (
                    <div>
                      {(this.state.editPathwayMode) ? (
                        <div className="edit-profile-row">
                          <div className="highlighted-container">
                            <div className="float-left">
                              <label className="heading-text-6">Career Tracks</label>
                            </div>
                            <div className="float-right right-padding-20 top-margin-negative-5">
                              <button className="background-button" onClick={() => this.setState({ editPathwayMode: false })}>
                                <div>
                                  <p className="description-text-3 bold-text cta-color">DONE</p>
                                </div>
                              </button>
                            </div>
                            <div className="clear"/>
                            <div className="spacer" /><div className="spacer" />

                            {this.renderAnswerChoices()}
                            <div className="add-item-line-button">
                              <hr  />
                              <a className="background-button" onClick={() => this.addAnswerChoice()}>
                                <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                              </a>
                            </div>
                            <div className="clear" />

                          </div>

                        </div>
                      ) : (
                        <div className="edit-profile-row">
                          <label>Career Tracks</label>

                          <div className="clear"/>
                          <div className="float-left">
                            <button className="background-button" onClick={() => this.setState({ editPathwayMode: true })}>
                              <div className="horizontal-padding-4">
                                <p className="heading-text-5 cta-color">{this.state.careerTrackOptions.length} Career Track Options</p>
                              </div>
                            </button>
                          </div>
                          <div className="float-left left-padding-20">
                            <button className="background-button" onClick={() => this.setState({ editPathwayMode: true })}>
                              <div className="row-10 horizontal-padding-4">
                                <p className="description-text-3 bold-text cta-color">EDIT</p>
                              </div>
                            </button>
                          </div>
                          <div className="clear"/>

                        </div>
                      )}
                    </div>
                  )}*/}

                  <div className="clear" />
                  {/*
                  <div className="row-10">
                    <div>
                      <div className="float-left right-padding">
                        <label className="profile-label">Certificate & Badge Options</label>
                      </div>

                      <div className="float-left">
                        <button className="background-button top-margin" onClick={() => this.setState({ modalIsOpen: true, showCertificate: true })}>
                          <div className="cta-border padding-5 circle-radius">
                            <img src={addIconBlue} alt="GC" className="image-auto-12" />
                          </div>
                        </button>
                      </div>
                      <div className="clear" />

                    </div>
                    <div className="clear" />

                    {this.renderTags('certificateOptions', this.state.certificateOptions)}
                    <div className="clear" />

                  </div>*/}

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Meeting Activity Code/Tag Options</label>
                      <div className="calc-column-offset-100 right-padding">
                        <input type="text" className="text-field" placeholder="(e.g., Career Pathway)..." name="meetingTagOption" value={this.state.meetingTagOption} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-100">
                        <button className="btn btn-squarish" onClick={() => this.addItem('meetingTagOption',this.state.meetingTagOption)}>Add</button>
                      </div>
                      <div className="clear" />

                    </div>
                    <div className="clear" />

                    {this.renderTags('meetingTagOptions', this.state.meetingTagOptions)}
                    <div className="clear" />

                  </div>

                  <div className="row-10">
                    <div>
                      <div className="float-left right-padding">
                        <label className="profile-label">Meeting Purpose Options</label>
                      </div>

                      <div className="float-left">
                        <button className="background-button top-margin" onClick={() => this.setState({ modalIsOpen: true, showMeetingPurpose: true })}>
                          <div className="cta-border padding-5 circle-radius">
                            <img src={addIconBlue} alt="GC" className="image-auto-12" />
                          </div>
                        </button>
                      </div>
                      <div className="clear" />

                    </div>
                    <div className="clear" />

                    {this.renderTags('meetingPurposeOptions', this.state.meetingPurposeOptions)}
                    <div className="clear" />

                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Departments</label>
                      <div className="calc-column-offset-100 right-padding">
                        <input type="text" className="text-field" placeholder="Department name..." name="orgDepartment" value={this.state.orgDepartment} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-100">
                        <button className="btn btn-squarish" onClick={() => this.addItem('orgDepartment',this.state.orgDepartment)}>Add</button>
                      </div>
                      <div className="clear" />

                    </div>
                    <div className="clear" />

                    {this.renderTags('orgDepartment', this.state.orgDepartments)}
                    <div className="clear" />

                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Case Note Tag Options</label>
                      <div className="calc-column-offset-100 right-padding">
                        <input type="text" className="text-field" placeholder="Tag name..." name="caseNoteTagOption" value={this.state.caseNoteTagOption} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-100">
                        <button className="btn btn-squarish" onClick={() => this.addItem('caseNoteTagOption',this.state.caseNoteTagOption)}>Add</button>
                      </div>
                      <div className="clear" />

                    </div>
                    <div className="clear" />

                    {this.renderTags('caseNoteTagOption', this.state.caseNoteTagOptions)}
                    <div className="clear" />

                  </div>



                </div>
              )}

              { (this.state.serverPostSuccess) ? (
                <p className="success-message">{this.state.serverSuccessMessage}</p>
              ) : (
                <p className="error-message">{this.state.serverErrorMessage}</p>
              )}

              <button className="btn btn-primary" onClick={() => this.saveOrgInfo()}>Save Org Info</button>

            </div>
          )}
        </div>
      )
    }

    renderOrgContacts() {
      console.log('renderOrgContacts called', this.state.orgcContacts)

      let rows = []

      let counter = 0
      for (let i = 1; i <= this.state.orgContacts.length; i++) {

        if (this.state.orgContacts[i - 1].orgCode === this.state.activeOrg) {

          const value = this.state.orgContacts[i - 1]
          const index = i - 1

          counter++

          rows.push(
            <div key={index} className="padding-20 standard-border">
              <div className="fixed-column-50 top-padding">
                <p className="heading-text-3 cta-color">#{counter}</p>
              </div>
              <div className="calc-column-offset-50">
                <div className="row-5">
                  <div className="container-left">
                      <label className="profile-label">{this.state.companyName} Contact First Name</label>
                      <input type="text" className="text-field" placeholder="Employer contact first name..." name={"employerContactFirstName|" + index} value={value.contactFirstName} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="container-right">
                      <label className="profile-label">{this.state.companyName} Contact Last Name</label>
                      <input type="text" className="text-field" placeholder="Employer contact last name..." name={"employerContactLastName|" + index} value={value.contactLastName} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-5">
                  <div className="container-left">
                      <label className="profile-label">{this.state.companyName} Contact Title</label>
                      <input type="text" className="text-field" placeholder="Employer contact title..." name={"employerContactTitle|" + index} value={value.contactTitle} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">{this.state.companyName} Contact Email</label>
                    <input type="text" className="text-field" placeholder="Employer contact email..." name={"employerContactEmail|" + index} value={value.contactEmail} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-5">
                  <div className="container-left">
                    <label className="profile-label">{this.state.companyName} Contact Phone</label>
                    <input type="text" className="text-field" placeholder="Employer contact phone..." name={"employerContactPhone|" + index} value={value.contactPhone} onChange={this.formChangeHandler}></input>
                  </div>

                  <div className="clear" />
                </div>

                {(this.state.activeOrg === 'dpscd') && (
                  <div>
                    <div className="row-5">
                      <div className="container-left">
                          <label className="profile-label">Org Referrer First Name</label>
                          <input type="text" className="text-field" placeholder="Internal first name..." name={"orgContactFirstName|" + index} value={value.orgContactFirstName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                          <label className="profile-label">Org Referrer Last Name</label>
                          <input type="text" className="text-field" placeholder="Internal contact last name..." name={"orgContactLastName|" + index} value={value.orgContactLastName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-5">
                      <div className="container-left">
                        <label className="profile-label">Org Referrer Contact Email</label>
                        <input type="text" className="text-field" placeholder="Internal contact email..." name={"orgContactEmail|" + index} value={value.orgContactEmail} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                )}

              </div>
              <div className="clear" />
            </div>
          )
        }
      }

      return rows
    }

    deleteAccount() {
      console.log('deleteAccount')

      Axios.delete('/api/users/' + this.state.emailId)
      .then((response) => {
        console.log('tried to  delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Post delete worked');

          signOut(
            this.state.emailId, this.state.activeOrg, this.state.orgFocus,
            this.state.accountCode, this.state.roleName, this.props.navigate
          )

        } else {
          console.error('there was an error deleting the posting');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ errorMessage: error.toString() })
      });
    }

    addCertificate() {
      console.log('addCertificate called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.certificateName || this.state.certificateName === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a name for the certificate' })
      } else if (!this.state.certificateIssuer || this.state.certificateIssuer === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add an issuer for the certificate' })
      } else if (!this.state.certificateEstimatedHours || this.state.certificateEstimatedHours === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add an estimated number of hours for completion for the certificate' })
      } else if (!this.state.certificateDescription || this.state.certificateDescription === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a description for the certificate' })
      } else {

        const name = this.state.certificateName
        const issuer = this.state.certificateIssuer
        const estimatedHours = this.state.certificateEstimatedHours
        const pathway = this.state.certificatePathway
        const workFunction = this.state.certificateFunction
        const industry = this.state.certificateIndustry
        const description = this.state.certificateDescription

        const certificate = { name, issuer, estimatedHours, pathway, workFunction, industry, description }

        const orgCode = this.state.activeOrg
        const updatedAt = new Date()

        let certificateObject = { certificate, orgCode, updatedAt }
        if (this.state.selectedCertificate) {
          certificateObject['selectedIndex'] = this.state.selectedIndex
        } else {
          certificateObject['addCertificate'] = true
        }

        // save certificate options
        Axios.post('/api/org/update', certificateObject)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Org info update worked', response.data);

            const certificateOptions = response.data.org.certificateOptions
            this.setState({ certificateOptions, isSaving: false, successMessage: 'Successfully updated org' })
            this.closeModal()

          } else {
            console.error('there was an error updating the org info');
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Org info save did not work', error);
            if (error) {
              this.setState({ isSaving: false, errorMessage: error.toString() })
            }
        });
      }
    }

    pullArchivedMembers() {
      console.log('pullArchivedMembers called')

      const orgCode = this.state.activeOrg

      Axios.get('/api/org/members', { params: { orgCode, isArchived: true } })
      .then((response) => {

        if (response.data.success) {
          console.log('Member query worked', response.data);

          if (response.data.members && response.data.members.length > 0) {
            const archivedMembers = response.data.members
            this.setState({ modalIsOpen: true, viewArchivedMembers: true, archivedMembers })
          } else {
            this.setState({ modalIsOpen: true, viewArchivedMembers: true, errorMessage: 'No members found' })
          }

        } else {
          console.log('no members found', response.data.message)
          this.setState({ modalIsOpen: true, viewArchivedMembers: true, errorMessage: response.data.message })
        }

      }).catch((error) => {
          console.log('Members query did not work', error);
          this.setState({ modalIsOpen: true, viewArchivedMembers: true, errorMessage: error.toString() })
      });
    }

    toggleTalentRetention() {
      console.log('toggleTalentRetention called')

      let payload = null
      if (window.location.pathname.includes('/employers')) {
        payload = { toggleType: 'Employer', accountCode: this.state.accountCode }
      } else if (window.location.pathname.includes('/app')){
        payload = { email: this.state.emailId, accountCode: this.state.accountCode }
      }

      Axios.post('/api/toggle-talent-retention', payload)
      .then((response) => {
        console.log('attempting to toggle: ', response.data)
        if (response.data.success) {
          //save values
          console.log(response.data.message);

          let internalToolEnabled = this.state.internalToolEnabled
          let employerAccount = this.state.employerAccount
          if (window.location.pathname.includes('/employers')) {

            if (internalToolEnabled) {
              internalToolEnabled = false
              localStorage.removeItem('internalToolEnabled')
            } else {
              internalToolEnabled = true
              localStorage.setItem('internalToolEnabled', 'true')
            }
          } else if (window.location.pathname.includes('/app')){
            if (employerAccount) {
              employerAccount = false
              localStorage.removeItem('accountCode')
            } else {
              employerAccount = true
              localStorage.setItem('accountCode', response.data.accountCode)
            }
            localStorage.setItem('roleName', response.data.roleName)
          }

          this.setState({ serverPostSuccess: true, serverSuccessMessage: response.data.message,
            internalToolEnabled, employerAccount
          })

        } else {
          console.error(response.data.messsage);
          this.setState({ serverPostSuccess: false, serverErrorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('Org info save did not work', error);
      });
    }

    toggleChatbotConfig(type,change) {
      console.log('toggleChatbotConfig called')

      const orgCode = this.state.activeOrg
      let disableFreeformChatbotMessaging = this.state.disableFreeformChatbotMessaging
      let disableChatbotPrompts = this.state.disableChatbotPrompts
      if (type === 'disableFreeformChatbotMessaging') {
        disableFreeformChatbotMessaging = change
      } else if (type === 'disableChatbotPrompts') {
        disableChatbotPrompts = change
      }
      const updatedAt = new Date()

      Axios.post('/api/org/update', {
        orgCode, disableFreeformChatbotMessaging, disableChatbotPrompts, updatedAt
       })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Org info update worked', response.data);

          this.setState({ disableFreeformChatbotMessaging, disableChatbotPrompts,
            formHasChanged: true, serverPostSuccess: true,
            serverSuccessMessage: 'Org information saved successfully!'
        })

        } else {
          console.error('there was an error updating the org info');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('Org info save did not work', error);
      });
    }

    render() {

      let problemPlatformOrg = false
      let problemPlatformEmployer = false
      if (window.location.pathname.includes('/problem-platform')) {
        if (this.state.roleName === 'Employer') {
          problemPlatformEmployer = true
        } else if (this.state.roleName === 'Admin' || this.state.roleName === 'Work-Based Learning Coordinator') {
          problemPlatformOrg = true
        }
      }

      if (window.location.pathname.includes('/advisor')) {

        return (
          <div>
            <div className="settings-container">
                <p className="heading-text-2">Settings</p>

                {(this.state.roleName === 'Academy Lead' || this.state.roleName === 'Teacher' || this.state.roleName === 'School Support') && (
                  <div>

                    {(this.state.courses && this.state.courses.length > 0) && (
                      <div className="edit-profile-row">
                        <label>Courses</label>
                        <div className="spacer" />
                        {this.renderCourses()}
                        <div className="clear" />
                      </div>
                    )}

                    <div className="spacer"/><div className="half-spacer"/>
                    <hr className="clear-margin clear-padding"/>
                    <div className="spacer"/><div className="half-spacer"/>

                    {(this.state.academies) && (
                      <div>
                        <div className="edit-profile-row">
                          <div className="name-container">
                            <label>Role</label>
                            <p>{this.state.roleName ? this.state.roleName : "No first name added"}</p>
                          </div>
                          <div className="name-container">
                            <label>Academy Name</label>
                            <p>{this.state.orgName}</p>
                          </div>
                          <div className="clear" />
                        </div>

                        <SwitchOrgs
                          emailId={this.state.emailId} activeOrg={this.state.activeOrg} myOrgs={this.state.myOrgs}
                          sharePartners={this.state.sharePartners} roleName={this.state.roleName}
                          academies={this.state.academies} academyCodes={this.state.academyCodes}
                          accountCode={this.state.emp} passActiveOrg={this.props.passActiveOrg}
                          history={this.props.navigate}
                        />
                      </div>
                    )}

                    {(this.state.orgFocus !== 'Placement' && (this.state.roleName === 'Teacher' || this.state.roleName === 'School Staff')) && (
                      <div>
                        <div className="row-15">
                          <p className="heading-text-5 bottom-margin">Configure Compass Assistant Chatbot</p>
                          <div className="container-left">
                            <p>Disable Free-Form Compass Assistant Messaging</p>
                            <label className="profile-descriptor">This disallows students / career-seekers from messaging the chatbot without clicking a prompt.</label>
                            <div className="clear" />
                            <div className="spacer" />
                            <Switch
                              onChange={(change) => this.toggleChatbotConfig('disableFreeformChatbotMessaging',change)}
                              checked={this.state.disableFreeformChatbotMessaging}
                              id="normal-switch"
                            />
                          </div>
                          <div className="container-right">
                            <p>Disable Compass Assistant Prompts</p>
                            <label className="profile-descriptor">This hides student / career-seeker prompts to engage with chatbot in courses, goals, assessments, etc...</label>
                            <div className="clear" />
                            <div className="spacer" />
                            <Switch
                              onChange={(change) => this.toggleChatbotConfig('disableChatbotPrompts',change)}
                              checked={this.state.disableChatbotPrompts}
                              id="normal-switch"
                            />
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="spacer"/><div className="half-spacer"/>
                        <hr className="clear-padding clear-margin" />
                        <div className="spacer" />
                      </div>
                    )}

                    {/*
                    {(this.state.roleName === 'Teacher' || this.state.roleName === 'School Staff') && (
                      <div>
                        <div>
                          <a className="background-link cta-color" onClick={() => this.setState({ modalIsOpen: true, showGrades: true })}>
                            <p>View & Export Grades</p>
                          </a>
                        </div>
                        <div className="spacer"/><div className="half-spacer"/>
                        <hr className="clear-padding clear-margin" />
                        <div className="spacer" />
                      </div>
                    )}*/}

                    <Link to={'/app'}>
                      <div>
                        <p>View Student Portal</p>
                      </div>

                    </Link>

                    {(!this.state.remoteAuth) && (
                      <div>
                        <div className="spacer"/><div className="half-spacer"/>
                        <hr className="clear-margin clear-padding"/>
                        <div className="spacer"/><div className="half-spacer"/>

                        <Link to={'/advisor/settings/change-password'}>
                          <div>
                            <p>Change Password</p>
                          </div>
                        </Link>
                        {console.log(")SDFDF", this.state.showChangeEmailOnlyParticipants, this.state.roleName)}
                        {(this.state.showChangeEmail) && (
                          <div>
                            <div className="spacer"/><div className="half-spacer"/>
                            <hr className="clear-margin clear-padding"/>
                            <div className="spacer"/><div className="half-spacer"/>

                            <div>
                              <Link to={'/advisor/settings/change-email'}>
                                <div>
                                  <p>Change Email</p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="clear" />
                    <div className="spacer"/><div className="half-spacer"/>
                    <hr className="clear-margin clear-padding"/>
                    <div className="spacer"/><div className="half-spacer"/>

                  </div>
                )}

                {(this.state.orgFocus === 'Mentor' || this.state.roleName === 'Mentor') && (
                  <div>
                    <SwitchOrgs
                      emailId={this.state.emailId} activeOrg={this.state.activeOrg} myOrgs={this.state.myOrgs}
                      sharePartners={this.state.sharePartners} roleName={this.state.roleName}
                      academies={this.state.academies} academyCodes={this.state.academyCodes}
                      accountCode={this.state.emp} passActiveOrg={this.props.passActiveOrg}
                      history={this.props.navigate}
                    />

                    <div>
                      <Link to={'/advisor/edit-profile/public-preferences'}><p>Manage Privacy Settings</p></Link>
                      <hr />
                    </div>

                    <div>
                      <Link to={'/advisor/settings/change-password'}>
                        <div>
                          <p>Change Password</p>
                        </div>
                      </Link>
                    </div>

                    <div>
                      <hr />
                    </div>

                    {(this.state.showChangeEmail || this.state.showChangeEmailOnlyParticipants) && (
                      <div>
                        <div>
                          <Link to={'/advisor/settings/change-email'}>
                            <div>
                              <p>Change Email Account</p>
                            </div>
                          </Link>
                        </div>

                        <div>
                          <hr />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div>
                  <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showCredits: true })}><p>{this.state.availableCredits} Available AI Credits</p></button>
                  <hr />
                </div>

                {(!this.state.remoteAuth) && (
                  <button className="btn btn-secondary" onClick={() => signOut(
                    this.state.emailId, this.state.activeOrg, this.state.orgFocus,
                    this.state.accountCode, this.state.roleName, this.props.navigate
                  )}>Sign Out</button>
                )}

            </div>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >

            {(this.state.showGrades) && (
              <div key="grades" className="full-width padding-40">
                <div>

                  <div className="description-text-2">
                    <div className="fixed-column-40 row-10">
                      <p>No.</p>
                    </div>
                    <div className="fixed-column-60 row-10">
                      <p>Grade</p>
                    </div>
                    <div className="fixed-column-100 row-10">
                      <p>Project Name</p>
                    </div>
                    <div className="fixed-column-150 row-10">
                      <p>Student Name</p>
                    </div>
                    <div className="fixed-column-150 row-10">
                      <p>Ind. Rep. Name</p>
                    </div>
                    <div className="fixed-column-180 row-10">
                      <p>Ind. Rep. Email</p>
                    </div>

                    <div className="clear" />
                    <div className="spacer" />
                    <hr />
                    <div className="spacer" />

                  </div>

                 {(this.state.grades && this.state.grades.length > 0) ? (
                   <div>
                     {this.state.grades.map((value, index) =>
                       <div>
                         <div className="description-text-2">
                           <div className="fixed-column-40  top-margin-5">
                             <p>#{index + 1}.</p>
                           </div>
                           <div className="fixed-column-60 heading-text-2 cta-color half-bold-text">
                             {(value.grade) ? (
                               <p>{value.grade}</p>
                             ) : (
                               <p>N/A</p>
                             )}
                           </div>
                           <div className="fixed-column-100 top-margin-5">
                             <p className="curtail-text">{this.state.projectNames[index]}</p>
                           </div>

                           <div className="fixed-column-150 top-margin-5">
                             <p className="curtail-text">{this.state.students[index].studentFirstName} {this.state.students[index].studentLastName}</p>
                           </div>
                           <div className="fixed-column-150 top-margin-5">
                             <p className="curtail-text">{value.contributorFirstName} {value.contributorLastName}</p>
                           </div>
                           <div className="fixed-column-180 top-margin-5">
                             <p className="curtail-text">{value.contributorEmail}</p>
                           </div>

                           <div className="clear" />
                         </div>

                         <div className="spacer"/>

                         {(value.feedback) && (
                           <div className="left-padding-40">
                             <p className="description-text-2">{value.feedback}</p>
                           </div>
                         )}

                         <div className="spacer"/><div className="spacer"/>
                       </div>
                     )}

                     <div className="clear" />
                     <div className="spacer"/><div className="spacer"/>

                     <div>
                      <div className="float-left">
                        <button className="btn btn-primary" onClick={() => this.exportToCSV('grades')}>Export to CSV</button>
                      </div>
                      <div className="float-left left-padding">
                        <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                      </div>
                      <div className="clear"/>
                     </div>

                     {(this.state.serverErrorMessage && this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}
                   </div>
                 ) : (
                   <div>
                     <div className="spacer"/><div className="spacer"/>
                     <p className="error-message">There are no student grades on projects available to export.</p>
                   </div>
                 )}

                </div>
              </div>
            )}

            {(this.state.showSettings) && (
              <div key="showIndustry" className="full-width padding-20">
                <p className="heading-text-2">Settings</p>
                <div className="spacer" />

                <div>
                  <EditSubscription />
                </div>

                {(!this.state.remoteAuth) && (
                  <div className="row-10">
                    <button className="btn btn-secondary" onClick={() => signOut(
                      this.state.emailId, this.state.activeOrg, this.state.orgFocus,
                      this.state.accountCode, this.state.roleName, this.props.navigate
                    )}>Sign Out</button>
                  </div>
                )}

                <div className="row-20">
                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>

              </div>
            )}

            {(this.state.showWBLPreferences) && (
              <div key="showIndustry" className="full-width padding-20">
                <p className="heading-text-2">PBL & WBL Preferences</p>

                <div className="row-10">
                  <label className="profile-label">Would you like to automatically include project-based learning and work-based learning opportunities from the broader Guided Compass community?</label>
                  <Switch
                    onChange={(change) => this.setState({ includeGuidedCompass: change })}
                    checked={this.state.includeGuidedCompass}
                    id="normal-switch"
                  />
                </div>

                {(this.state.includeGuidedCompass) && (
                  <div className="row-10">
                      <label className="profile-label">What types of opportunities would you like to include?</label>
                      {this.state.wblPreferenceOptions.map((value, optionIndex) =>
                        <div key={optionIndex}>
                          <div className="float-left row-10 right-padding">
                            {(this.state.wblPreferences.some(sa => sa === value)) ? (
                              <button className="background-button tag-container-4 float-left top-margin-5 left-margin-5" onClick={() => this.optionClicked('wblPreference',value, optionIndex)}>
                                <div>
                                  <div className="float-left left-text">
                                    <p className="description-text-2 white-text">{value}</p>
                                  </div>
                                </div>
                              </button>
                            ) : (
                              <button className="background-button tag-container-5" onClick={() => this.optionClicked('wblPreference',value, optionIndex)}>
                                <div>
                                  <div className="float-left left-text">
                                    <p className="description-text-2">{value}</p>
                                  </div>
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="clear" />

                  </div>
                )}

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-message">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="success-message">{this.state.successMessage}</p>}

                <div className="row-20">
                  <button className="btn btn-primary right-margin" onClick={() => this.savePreferences()}>Save Preferences</button>
                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>

              </div>
            )}

            {(this.state.showCredits) && (
              <div key="showCredits" className="full-width padding-20">
                <div>
                  <div className="calc-column-offset-30">
                    <p className="heading-text-2">AI Credits</p>
                  </div>
                  <div className="fixed-column-30">
                    <button className="background-button" onClick={() => this.closeModal()}>
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="spacer" /><div className="spacer" />

                <div className="row-20">
                 <p>Since AI capabilities are costly, users must expend AI credits to use AI capabilities. Users all receive {this.state.startingCredits} credits for free. Additional credits may be purchased for $1 per credit or sponsored by your community administrator.</p>

                 <p className="top-margin-20">Please email questions or requests to help@guidedcompass.com.</p>
                </div>

                <div className="row-20">
                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>

              </div>
            )}

           </Modal>
          </div>
        )
      } else if (window.location.pathname.includes('/organizations') || problemPlatformOrg) {

        return (
          <div>
            <div className="super-spacer"/>

            <div>
              <div className="calc-column-offset-50">
                <p className="heading-text-2">{(problemPlatformOrg || window.location.pathname.includes('/edit-organization-profile')) ? "Edit Profile Info" : "Settings"}</p>
              </div>

              {(window.location.pathname.includes('/problem-platform')) && (
                <div className="fixed-column-50">
                  <div className="half-spacer" />
                  <button type="button" className="background-button" onClick={() => this.setState({ modalIsOpen: true, showSettings: true })}>
                    <img src={settingsIcon} alt="GC" className="image-auto-30" />
                  </button>
                </div>
              )}

              <div className="clear" />
            </div>

            {(window.location.pathname.includes('/problem-platform')) && (
              <div>
                <p className="description-text-2">Profile Type: {this.state.roleName}</p>
              </div>
            )}

            <div className="row-10">
              {this.renderOrgInformation()}
            </div>

            {(window.location.pathname.includes('/problem-platform')) && (
              <div>
                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                <hr />

                <SubPostings source="Admin" path={this.state.path} activeOrg={this.state.activeOrg} accountCode={null} />

              </div>
            )}


            {/*
            <div className="row-20">
              <hr />
            </div>

            <div>

              <div className="row-10">
                <p className="subtitle">My Info</p>
              </div>

              <div className="clear" />

              <div className="container-left">
                  <label className="profile-label">First Name</label>
                  <label className="heading-text-4">{this.state.cuFirstName}</label>
              </div>

              <div className="container-right">
                  <label className="profile-label">Last Name</label>
                  <label className="heading-text-4">{this.state.cuLastName}</label>
              </div>

              <div className="container-left">
                  <label className="profile-label">Primary Contact Email</label>
                  <label className="heading-text-4">{this.state.emailId}</label>
              </div>

              <div className="clear" />
            </div>*/}

            {(!window.location.pathname.includes('/problem-platform') && !window.location.pathname.includes('/edit-organization-profile')) && (
              <div>

                <div className="row-20">
                  <hr />
                </div>

                {(this.state.showOrgInfoInSettings) && (
                  <div>


                    <div className="row-10">
                      <p className="subtitle">Information</p>
                    </div>

                    <div className="spacer" />
                  </div>
                )}
                {/*
                <Link className="secret-link" to={'/organizations/' + this.state.org + '/walkthrough'}><p>View Walkthrough</p></Link>

                <hr className="cell-separator-advisor"/>*/}

                <Link className="background-button cta-color" to={'/organizations/' + this.state.activeOrg + '/bulk-uploader'}><p>Bulk Upload Information</p></Link>

                <div className="row-20">
                  <hr />
                </div>

                <button className="background-button cta-color" onClick={() => this.pullArchivedMembers()}>View Archived Members</button>

                <div className="row-20">
                  <hr />
                </div>

                <Link className="background-button" target="_blank" rel="noopener noreferrer" to={'/app'} state={{ source: 'org', passedOrg: this.state.activeOrg, passedFirstName: this.state.cuFirstName, passedLastName: this.state.cuLastName, passedEmail: this.state.emailId, passedUsername: this.state.username }}>View {this.state.studentAliasSingular} Portal</Link>

                <div className="row-20">
                  <hr />
                </div>

                <Link to={'/organizations/' + this.state.activeOrg + '/settings/change-password'}>
                  <div>
                    <p>Change Password</p>
                  </div>
                </Link>

                <div className="spacer"/><div className="half-spacer"/>
                <hr className="clear-margin clear-padding"/>
                <div className="spacer"/><div className="half-spacer"/>

                {(this.state.showChangeEmail) && (
                  <div>
                    <div>
                      <Link to={'/organizations/' + this.state.activeOrg + '/settings/change-email'}>
                        <div>
                          <p>Change Email</p>
                        </div>
                      </Link>
                    </div>

                    <div className="spacer"/><div className="half-spacer"/>
                    <hr className="clear-margin clear-padding"/>
                    <div className="spacer"/><div className="half-spacer"/>
                  </div>
                )}

                {/*
                  <div>
                    <a className="secret-link" href="https://itunes.apple.com/us/app/guided-compass/id1436670877?ls=1&mt=8">View the iOS App (For Students)</a>
                    <hr className="cell-separator-advisor"/>
                    <a  className="secret-link"href="https://play.google.com/store/apps/details?id=com.guidedcompass">View the Android App (For Students)</a>
                    <hr className="cell-separator-advisor"/>
                  </div>
                */}

                {(this.state.orgFocus === 'Mentor') && (
                  <div>
                    <Link className="secret-link" to={'/advisor/dashboard'}><p>View Advisor Portal</p></Link>
                    <div className="row-20">
                      <hr />
                    </div>
                  </div>
                )}

                {(!this.state.remoteAuth) && (
                  <div className="row-20">
                    <button className="btn btn-secondary" onClick={() => signOut(
                      this.state.emailId, this.state.activeOrg, this.state.orgFocus,
                      this.state.accountCode, this.state.roleName, this.props.navigate
                    )}>Sign Out</button>
                  </div>
                )}
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

            {(this.state.showSettings) && (
              <div key="showIndustry" className="full-width padding-20">
                <p className="heading-text-2">Settings</p>
                <div className="spacer" />

                <div>
                  <EditSubscription />
                </div>

                {(!this.state.remoteAuth) && (
                  <div className="row-10">
                    <button className="btn btn-secondary" onClick={() => signOut(
                      this.state.emailId, this.state.activeOrg, this.state.orgFocus,
                      this.state.accountCode, this.state.roleName, this.props.navigate
                    )}>Sign Out</button>
                  </div>
                )}

                <div className="row-20">
                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>

              </div>
            )}

            {(this.state.showCertificate) && (
              <div key="showIndustry" className="full-width padding-20">
                <p className="heading-text-2">Certificate & Badge Options</p>
                <div className="spacer" /><div className="spacer" />

                <div className="row-10">
                  <div className="container-left">
                    <p className="profile-label">Name <label className="error-color">*</label></p>
                    <input type="text" className="text-field" placeholder="(e.g., Adobe Photoshop)..." name="certificateName" value={this.state.certificateName} onChange={this.formChangeHandler}></input>
                  </div>

                  <div className="container-right">
                    <p className="profile-label">Issuer <label className="error-color">*</label></p>
                    <input type="text" className="text-field" placeholder="Harvard University..." name="certificateIssuer" value={this.state.certificateIssuer} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <div className="container-left">
                    <p className="profile-label">Estimated Hours to Achieve <label className="error-color">*</label></p>
                    <select name="certificateEstimatedHours" value={this.state.certificateEstimatedHours} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.estimatedHoursOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="container-right">
                    <p className="profile-label">Pathway (Optional)</p>
                    <select name="certificatePathway" value={this.state.certificatePathway} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.pathwayOptions.map(value =>
                        <option key={value.title} value={value.title}>{value.title}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <div className="container-left">
                    <p className="profile-label">Work Function (Optional)</p>
                    <select name="certificateFunction" value={this.state.certificateFunction} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.functionOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="container-right">
                    <p className="profile-label">Industry (Optional)</p>
                    <select name="certificateIndustry" value={this.state.certificateIndustry} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.industryOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <p className="profile-label">Description <label className="error-color">*</label></p>
                  <textarea type="text" className="text-field" placeholder="Add a description of the certificate or badge..." name="certificateDescription" value={this.state.certificateDescription} onChange={this.formChangeHandler}></textarea>
                </div>

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                <div className="row-20">
                  <button className="btn btn-primary right-margin" onClick={() => this.addCertificate()}>{(this.state.selectedCertificate) ? "Edit" : "Add"} Certificate / Badge</button>
                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>

              </div>
            )}

            {(this.state.showMeetingPurpose) && (
              <div key="showIndustry" className="full-width padding-20">
                <p className="heading-text-2">Meeting Purpose Options</p>
                <div className="spacer" /><div className="spacer" />

                <div className="row-10">
                  <div className="container-left">
                    <p className="profile-label">Name <label className="error-color">*</label></p>
                    <input type="text" className="text-field" placeholder="(e.g., Name for Meeting Purpose)..." name="meetingOutcomeName" value={this.state.meetingOutcomeName} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>
                {/*
                <div className="row-10">
                  <p className="profile-label">Description <label className="error-color">*</label></p>
                  <textarea type="text" className="text-field" placeholder="Add a description of the certificate or badge..." name="certificateDescription" value={this.state.certificateDescription} onChange={this.formChangeHandler}></textarea>
                </div>*/}

                <div className="row-10">
                  <div className="container-left">
                    <p className="profile-label">Meeting Outcome Options <label className="error-color">*</label></p>
                    <div className="calc-column-offset-100 right-padding">
                      <input type="text" className="text-field" placeholder="(e.g., Submitted Application)..." name="meetingOutcomeOption" value={this.state.meetingOutcomeOption} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="fixed-column-100">
                      <button className="btn btn-squarish" onClick={() => this.addItem('meetingOutcomeOption',this.state.meetingOutcomeOption)}>Add</button>
                    </div>
                    <div className="clear" />

                    {this.renderTags('meetingOutcomeOptions', this.state.meetingOutcomeOptions)}
                    <div className="clear" />
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                <div className="row-20">
                  <button className="btn btn-primary right-margin" onClick={() => this.addItem('meetingPurposeOption')}>{(this.state.selectedMeetingPurposeOption) ? "Edit" : "Add"} Meeting Purpose Option</button>
                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>

              </div>
            )}

            {(this.state.viewArchivedMembers) && (
              <div key="showIndustry" className="full-width padding-20">
                <p className="heading-text-2">Archived Members</p>
                <div className="spacer" /><div className="spacer" />

                {(this.state.archivedMembers) && (
                  <div className="row-10">
                    {this.state.archivedMembers.map((value,index) =>
                      <div key={value} className="bottom-margin">
                        <p>{index + 1}. {value.firstName} {value.lastName} ({value.email}) [{value.roleName}]</p>
                      </div>
                    )}

                    <div className="row-10">
                      <hr />
                    </div>

                    <p className="row-10">Would you like to reinstate these members? Email help@guidedcompass.com.</p>
                  </div>
                )}

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                <div className="row-20">
                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>

              </div>
            )}

           </Modal>

          </div>
        )
      } else if (window.location.pathname.includes('/employers') || problemPlatformEmployer) {
        // employers

        return (
          <div>
            <div className="super-spacer"/>

            <div>
              <div className="calc-column-offset-50">
                <p className="heading-text-2">{(window.location.pathname.includes('/edit-employer-profile') || problemPlatformEmployer) ? "Edit Employer Profile" : "Settings"}</p>
              </div>
              {(window.location.pathname.includes('/problem-platform')) && (
                <div className="fixed-column-50">
                  <div className="half-spacer" />
                  <button type="button" className="background-button" onClick={() => this.setState({ modalIsOpen: true, showSettings: true })}>
                    <img src={settingsIcon} alt="GC" className="image-auto-30" />
                  </button>
                </div>
              )}

              <div className="clear" />
            </div>

            {(window.location.pathname.includes('/problem-platform')) && (
              <div>
                <p className="description-text-2">Profile Type: {this.state.roleName}</p>
              </div>
            )}

            {(window.location.pathname.includes('/edit-employer-profile')) && (
              <div className="top-padding-20 bottom-padding">
                <label className="profile-label">Employer Profile Link</label>
                <a href={window.location.protocol + "//" + window.location.host + "/employers/pages/" + this.state.accountCode} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host + "/employers/pages/" + this.state.accountCode}</a>
              </div>
            )}

            <div className="spacer" />
            <div className="clear" />

            {(window.location.pathname.includes('/edit-employer-profile') || this.state.activeOrg !== 'guidedcompass') && (
              <div>
                <div className="row-10">
                  <div className="standard-border">
                    <div className="container-left">
                      <div className="upload-image">
                        <div className="row-10 description-text-2 full-width center-text">
                          <p>Add your logo for light backgrounds</p>
                        </div>

                        <div className="relative-position">
                          <label for="employerLogo" className="profile-pic-button">
                            <img src={ this.state.employerLogoImage ? ( this.state.employerLogoImage )
                              : this.state.employerLogoPath ? ( this.state.employerLogoPath )
                              : ( exampleLogoLight)}
                            alt="Compass add logo" for="employerLogo" className="logo-image-largish center-item"/>
                            <div className="bottom-right-overlay">
                              <div className="bottom-right-subcontainer-2">
                                <img src={addIconBlue} alt="Compass add icon" className="image-auto-18 center-item"/>
                              </div>
                              <div className="clear" />
                            </div>
                          </label>
                          <input type="file" id="employerLogo" name="employerLogo" onChange={this.formChangeHandler} accept="image/*" />
                        </div>

                        <div className="clear" />

                        <div className="row-10 description-text-4 full-width center-text">
                          <p>Preferred Dimensions: 150 x 150</p>
                        </div>

                        { (this.state.serverPostSuccess) ? (
                          <p className="success-message">{this.state.serverSuccessMessage}</p>
                        ) : (
                          <p className="error-message">{this.state.serverErrorMessage}</p>
                        )}
                      </div>
                    </div>
                    <div className="container-right dark-background white-text horizontal-padding-5">
                      <div className="upload-image">
                        <div className="row-10 description-text-2 full-width center-text">
                          <p>Add your logo for dark backgrounds</p>
                        </div>

                        <div className="relative-position">
                          <label for="employerLogoWhite" className="profile-pic-button">
                            <img src={ this.state.employerLogoWhiteImage ? ( this.state.employerLogoWhiteImage )
                              : this.state.employerLogoWhitePath ? ( this.state.employerLogoWhitePath )
                              : ( exampleLogoDark)}
                            alt="Compass add logo" for="employerLogoWhite" className="logo-image-largish center-item" />
                            <div className="bottom-right-overlay">
                              <div className="bottom-right-subcontainer-2">
                                <img src={addIconBlue} alt="Compass add icon" className="image-auto-18 center-item"/>
                              </div>
                              <div className="clear" />
                            </div>
                          </label>
                          <input type="file" id="employerLogoWhite" name="employerLogoWhite" onChange={this.formChangeHandler} accept="image/*" />
                        </div>

                        <div className="clear" />

                        <div className="row-10 description-text-4 full-width center-text">
                          <p>Preferred Dimensions: 150 x 150</p>
                        </div>

                        { (this.state.serverPostSuccess) ? (
                          <p className="success-message">{this.state.serverSuccessMessage}</p>
                        ) : (
                          <p className="error-message">{this.state.serverErrorMessage}</p>
                        )}

                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                </div>

                <div className="spacer" />

                <div className="name-container">
                  <label className="profile-label">Employer Name</label>
                  <input type="text" className="text-field" placeholder="Company name..." name="companyName" value={this.state.companyName} onChange={this.formChangeHandler}></input>
                </div>

                <div className="name-container">
                  <label className="profile-label">Employer Website</label>
                  <input type="text" className="text-field" placeholder="Company url..." name="companyURL" value={this.state.companyURL} onChange={this.formChangeHandler}></input>
                </div>

                <div className="name-container">
                    <label className="profile-label">Employer Type</label>
                    <select name="companyType" value={this.state.companyType} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.typeOptions.map(value =>
                        <option key={value.key} value={value}>{value}</option>
                      )}
                    </select>
                </div>

                <div className="name-container">
                    <label className="profile-label">Employer Primary Industry</label>
                    <select name="companyIndustry" value={this.state.companyIndustry} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.industryOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                    </select>
                </div>

                <div className="name-container">
                    <label className="profile-label">Number of Employees</label>
                    <select name="employeeCount" value={this.state.employeeCount} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.countOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                    </select>
                </div>
                {/*
                <div className="name-container">
                    <label className="profile-label">Y/Y Employee Growth</label>
                    <select name="employeeGrowth" value={this.state.employeeGrowth} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.growthOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                    </select>
                </div>*/}

                <div className="name-container">
                    <label className="profile-label">Work Address</label>
                    <input type="text" className="text-field" placeholder="Add full work address..." name="employerLocation" value={this.state.employerLocation} onChange={this.formChangeHandler}></input>
                </div>

                <div className="clear" />

                {(this.state.activeOrg === 'guidedcompass') && (
                  <div className="row-10">
                    <div className="name-container">
                      <label className="profile-label">Departments</label>
                      <div className="calc-column-offset-100 right-padding">
                        <input type="text" className="text-field" placeholder="Department name..." name="employerDepartment" value={this.state.employerDepartment} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-100">
                        <button className="btn btn-squarish" onClick={() => this.addItem('employerDepartment',this.state.employerDepartment)}>Add</button>
                      </div>
                      <div className="clear" />

                    </div>
                    <div className="clear" />

                    {(this.state.suggestedDepartments && this.state.suggestedDepartments.length > 0) && (
                      <div className="card top-margin">
                        <p className="description-text-2 row-10 italicize-text">Suggested Departments</p>

                        {this.state.suggestedDepartments.map((value, optionIndex) =>
                          <div key={value._id} className="left-text bottom-margin-5 full-width">
                            <button className="background-button full-width row-5 left-text" onClick={() => this.suggestedItemClicked(value, 'employerDepartment')}>
                              <div className="full-width">
                                <div className="fixed-column-40">
                                  <div className="mini-spacer" />
                                  <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                </div>
                                <div className="calc-column-offset-40">
                                  <p className="cta-color">{value}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    {this.renderTags('employerDepartment', this.state.employerDepartments)}
                    <div className="clear" />

                  </div>
                )}

                <div className="edit-profile-row">
                  <label className="profile-label">Description of Employer</label>
                  <textarea type="text" className="text-field" placeholder="Add a description of the company..." name="description" value={this.state.description} onChange={this.formChangeHandler}></textarea>
                </div>

                {(!window.location.pathname.includes('/problem-platform')) && (
                  <div>
                    <div className="edit-profile-row">
                      <label className="profile-label">Describe your office and team culture to help us better understand the environment (Optional)</label>
                      <textarea type="text" className="text-field" placeholder="…describe the employer" name="employerCulture" value={this.state.employerCulture} onChange={this.formChangeHandler}></textarea>
                    </div>

                    {(this.state.activeOrg === 'c2c') && (
                      <div>
                        <div className="name-container">
                            <label className="profile-label">Office Type</label>
                            <select name="officeType" value={this.state.officeType} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.officeTypeOptions.map(value =>
                                <option key={value} value={value}>{value}</option>
                              )}
                            </select>
                        </div>
                        <div className="name-container">
                            <label className="profile-label">Political Alignment</label>
                            <select name="politicalAlignment" className="dropdown" value={this.state.politicalAlignment} onChange={this.formChangeHandler}>
                                {this.state.politicalAlignmentOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                        </div>
                        <div className="name-container">
                            <label className="profile-label">State Represented (If Applicable)</label>
                            <select name="stateRegistration" className="dropdown" value={this.state.stateRegistration} onChange={this.formChangeHandler}>
                                {this.state.registrationOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                        </div>
                        <div className="name-container">
                            <label className="profile-label">District Number (If Applicable)</label>
                            <input className="text-field" type="text" placeholder="e.g. 12" name="congressionalDistrict" value={this.state.congressionalDistrict} onChange={this.formChangeHandler}/>
                        </div>

                        <div className="name-container">
                            <label className="profile-label">Are these internship positions paid?</label>
                            <select name="hasFunds" value={this.state.hasFunds} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.binaryOptions.map(value =>
                                <option key={value} value={value}>{value}</option>
                              )}
                            </select>
                        </div>
                        <div className="name-container">
                            <label className="profile-label">Host undocumented students?</label>
                            <select name="hostUndocumented" value={this.state.hostUndocumented} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.maybeOptions.map(value =>
                                <option key={value} value={value}>{value}</option>
                              )}
                            </select>
                        </div>
                        <div className="clear" />
                      </div>
                    )}

                    <div className="edit-profile-row">
                      <label className="profile-label">During what time frame are you interested in hosting interns?<label className="error-color bold-text">*</label></label>
                      {this.state.timeframeOptions.map((value, optionIndex) =>
                        <div key={"success|" + optionIndex} className="float-left">
                          <a className="background-link" onClick={() => this.addItem('timeframe', value)}>
                            <div className="float-left row-5 right-padding">
                              {(this.state.hireTimeframes.includes(value)) ? (
                                <div className="tag-container-4 float-left left-margin-5 top-margin-5">
                                  <p className="description-text-2 white-text">{value}</p>
                                </div>
                              ) : (
                                <div className="tag-container-5">
                                  <p className="description-text-2">{value}</p>
                                </div>
                              )}
                            </div>
                          </a>
                        </div>
                      )}
                      <div className="clear" />
                    </div>

                  </div>
                )}

                {(this.state.showOrgContacts && !window.location.pathname.includes('/employers')) ? (
                  <div className="row-10">
                    <div className="row-5">
                      <p className="heading-text-3">Contacts</p>
                    </div>
                    {this.renderOrgContacts()}
                  </div>
                ) : (
                  <div>
                    <div className="name-container">
                        <label className="profile-label">Primary Contact First Name</label>
                        <input type="text" className="text-field" placeholder="First name..." name="contactFirstName" value={this.state.contactFirstName} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="name-container">
                        <label className="profile-label">Primary Contact Last Name</label>
                        <input type="text" className="text-field" placeholder="Last name..." name="contactLastName" value={this.state.contactLastName} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="name-container">
                        <label className="profile-label">Primary Contact Title</label>
                        <input type="text" className="text-field" placeholder="Title..." name="contactTitle" value={this.state.contactTitle} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="name-container">
                        <label className="profile-label">Primary Contact Email</label>
                        <input type="text" className="text-field" placeholder="Email..." name="contactEmail" value={this.state.contactEmail} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="name-container">
                        <label className="profile-label">Primary Contact Phone Number</label>
                        <input type="text" className="text-field" placeholder="Phone number..." name="contactPhone" value={this.state.contactPhone} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="clear"/>
                  </div>
                )}

                {(this.state.activeOrg === 'guidedcompass') && (
                  <div className="row-20">
                    <hr />

                    <div className="row-20">
                      <p className="heading-text-3">Showcase {this.state.companyName}</p>
                      <div className="spacer" />

                      <div className="row-10">
                        <div className="float-left">
                          <label className="profile-label">Videos</label>
                        </div>
                        <div className="float-left left-padding top-padding">
                          <button className="background-button" onClick={() => this.addItem('video')}>
                            <div className="circle-radius cta-border padding-5">
                              <img src={addIcon} alt="GC" className="image-auto-10" />
                            </div>
                          </button>
                        </div>
                        <div className="clear" />

                        {(this.state.videos && this.state.videos.length > 0) && (
                          <div>
                            {this.state.videos.map((value, optionIndex) =>
                              <div key={"video|" + optionIndex} className="top-margin bottom-margin">
                                <div className="fixed-column-30 top-padding">
                                  <button className="background-button" onClick={() => this.removeItem('video', optionIndex)}>
                                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                  </button>
                                </div>
                                <div className="calc-column-offset-30">
                                  <input type="text" className="adjacent-text-field" placeholder="Http..." name={"video|" + optionIndex} value={value} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="clear" />

                                {(value && value !== '' && !value.startsWith('http')) && (
                                  <div>
                                    <p className="error-message">Please start your link with http</p>
                                  </div>
                                )}

                                <div className="top-margin-20">
                                  <div className="video-container-4">
                                    <iframe
                                      title="videoLink"
                                      className="video-iframe"
                                      src={value}
                                      frameBorder="0"
                                    />
                                  </div>
                                  <div className="clear"/>
                                </div>
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      {(this.state.showModule) && (
                        <div>
                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Y/Y Employee Growth</label>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Y/Y User Growth</label>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Y/Y Revenue Growth</label>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Y/Y Net Revenue Growth</label>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Race Breakdown</label>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Gender Breakdown</label>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Employee Growth</label>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Employee NPS</label>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Employee Retention Rate</label>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Diverse Retetention Rate</label>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                    </div>

                    <hr />
                  </div>
                )}

                { (this.state.serverPostSuccess) ? (
                  <p className="success-message">{this.state.serverSuccessMessage}</p>
                ) : (
                  <p className="error-message">{this.state.serverErrorMessage}</p>
                )}
                <button className="btn btn-primary" onClick={() => this.saveAccountInfo()}>Save Account Info</button>
              </div>
            )}

            {(!window.location.pathname.includes('/problem-platform') && !window.location.pathname.includes('/edit-employer-profile')) && (
              <div>
                {(this.state.activeOrg !== 'guidedcompass') && (
                  <div>
                    <div className="row-20">
                      <hr />
                    </div>

                    <div className="row-10">
                      <p className="subtitle">Other Settings</p>
                    </div>
                  </div>
                )}

                <div className="spacer" />

                <div>
                  <SwitchOrgs
                    emailId={this.state.emailId} activeOrg={this.state.activeOrg} myOrgs={this.state.myOrgs}
                    sharePartners={this.state.sharePartners} roleName={this.state.roleName}
                    academies={this.state.academies} academyCodes={this.state.academyCodes}
                    accountCode={this.state.accountCode} passActiveOrg={this.props.passActiveOrg}
                    history={this.props.navigate}
                  />
                </div>

                <div className="clear"/>
                {/*
                {(this.state.activeOrg === 'guidedcompass') && (
                  <div>
                    <div className="row-20">
                      <Link className="secret-link" to={'/employers/' + this.state.accountCode + '/walkthrough'}><p>View Walkthrough</p></Link>
                    </div>

                    <hr />
                  </div>
                )}*/}


                {/*
                <p className="subtitle">Information</p>
                <br/>

                <Link className="secret-link" to={'/employers/' + this.state.emp + '/walkthrough'}><p>View Walkthrough</p></Link>

                <hr className="cell-separator-advisor"/>

                <a className="secret-link" href="https://itunes.apple.com/us/app/guided-compass/id1436670877?ls=1&mt=8">View the iOS App (For Job Seekers)</a>
                <hr className="cell-separator-advisor"/>
                <a  className="secret-link"href="https://play.google.com/store/apps/details?id=com.guidedcompass">View the Android App (For Job Seekers)</a>
                <hr className="cell-separator-advisor"/>

                <button className="background-button" onClick={() => this.contactUs()}><p>Contact Us > ></p></button>
                <hr />
                */}

                {(this.state.emailId === 'creightontaylor+300@gmail.com') && (
                  <div>
                    <div className="spacer" /><div className="spacer" />

                    <label className="profile-label">Toggle Talent Retention Portal</label>
                    <Switch
                      onChange={(change) => this.toggleTalentRetention()}
                      checked={this.state.internalToolEnabled}
                      id="normal-switch"
                    />

                    <div className="top-padding-20">
                      <hr />
                    </div>
                  </div>
                )}

                <div>
                  <div className="spacer" /><div className="spacer" />

                  <Link to={'/employers/' + this.state.accountCode + '/settings/integrations'}>
                    <div>
                      <p>Integrations and API</p>
                    </div>
                  </Link>

                  <div className="clear"/>

                  <div className="top-padding-20">
                    <hr />
                  </div>
                </div>

                <div>
                  <div className="spacer" /><div className="spacer" />

                  <Link to={'/employers/' + this.state.accountCode + '/settings/change-password'}>
                    <div>
                      <p>Change Password</p>
                    </div>
                  </Link>
                </div>

                <div className="clear"/>

                <div className="row-20">
                  <hr />
                </div>

                {(this.state.showChangeEmail) && (
                  <div>
                    <div>

                      <Link to={'/employers/' + this.state.accountCode + '/settings/change-email'}>
                        <div>
                          <p>Change Email</p>
                        </div>
                      </Link>
                    </div>

                    <div className="clear"/>

                    <div className="row-20">
                      <hr />
                    </div>
                  </div>
                )}

                {(!this.state.remoteAuth) && (
                  <div>
                    <br/>

                    <button className="btn btn-secondary" onClick={() => signOut(
                      this.state.emailId, this.state.activeOrg, this.state.orgFocus,
                      this.state.accountCode, this.state.roleName, this.props.navigate
                    )}>Sign Out</button>
                  </div>
                )}

              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

            {(this.state.showSettings) && (
              <div key="showIndustry" className="full-width padding-20">
                <p className="heading-text-2">Settings</p>
                <div className="spacer" />

                <div>
                  <EditSubscription />
                </div>

                <div>
                  <SwitchOrgs
                    emailId={this.state.emailId} activeOrg={this.state.activeOrg} myOrgs={this.state.myOrgs}
                    sharePartners={this.state.sharePartners} roleName={this.state.roleName}
                    academies={this.state.academies} academyCodes={this.state.academyCodes}
                    accountCode={this.state.emp} passActiveOrg={this.props.passActiveOrg}
                    history={this.props.navigate}
                  />
                </div>

                {(!this.state.remoteAuth) && (
                  <div className="row-10">
                    <button className="btn btn-secondary" onClick={() => signOut(
                      this.state.emailId, this.state.activeOrg, this.state.orgFocus,
                      this.state.emp, this.state.roleName, this.props.navigate
                    )}>Sign Out</button>
                  </div>
                )}

              </div>
            )}

            <div className="row-20">
             <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
            </div>
           </Modal>


          </div>
        )
      } else {

        // student
        return (
            <div>
                <div className="settings-container">
                    <p className="heading-text-2">Settings</p>
                    <hr/>
                    {/*<Link to={'/app/walkthrough'}><p>View Walkthrough</p></Link>
                    <hr/>*/}

                    { (this.state.orgFocus === 'School' && this.state.roleName !== 'Student') && (
                      <div>
                        <Link to={'/advisor/dashboard'}><p>Switch to Advisor App</p></Link>
                        <hr />
                      </div>
                    )}

                    {(this.state.activeOrg === 'c2c') && (
                      <div>
                        <div className="relative-column-80">
                          <p>Are you open to being mentored?</p>
                        </div>
                        <div className="relative-column-20">
                          <div className="half-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                          <Switch
                            onChange={(change) => this.saveSettings(change)}
                            checked={this.state.openToMentoring}
                            id="normal-switch"
                          />
                        </div>
                        <div className="clear" />
                        <div className="spacer"/><div className="spacer"/>
                        <hr className="clear-margin clear-padding"/>
                        <div className="spacer"/><div className="spacer"/>

                      </div>
                    )}

                    {(this.state.academies) && (
                      <div>
                        <SwitchOrgs
                          emailId={this.state.emailId} activeOrg={this.state.activeOrg} myOrgs={this.state.myOrgs}
                          sharePartners={this.state.sharePartners} roleName={this.state.roleName}
                          academies={this.state.academies} academyCodes={this.state.academyCodes}
                          accountCode={this.state.emp} passActiveOrg={this.props.passActiveOrg}
                          history={this.props.navigate}
                        />
                      </div>
                    )}

                    {(this.state.isWorking || this.state.workMode) && (
                      <div>
                        <label className="profile-label">Switch to Work Mode?</label>
                        <Switch
                          disabled={this.state.isSaving}
                          onChange={(change) => this.switchMode(change)}
                          checked={this.state.workMode}
                          id="normal-switch"
                        />
                        <hr />
                      </div>
                    )}

                    {(this.state.emailId === 'creightontaylor+7@gmail.com') && (
                      <div>
                        <div className="spacer" />

                        <label className="profile-label">Toggle Employee Portal</label>
                        <Switch
                          onChange={(change) => this.toggleTalentRetention()}
                          checked={this.state.employerAccount}
                          id="normal-switch"
                        />

                        <div className="top-padding">
                          <hr />
                        </div>
                      </div>
                    )}

                    {(this.state.activeOrg === 'guidedcompass') && (
                      <div>
                        <EditSubscription />
                      </div>
                    )}

                    <div>
                      <Link to={'/app/notification-preferences'}><p>Manage Notifications</p></Link>
                      <hr />
                    </div>

                    <div>
                      <Link to={'/app/edit-profile/public-preferences'}><p>Manage Privacy Settings</p></Link>
                      <hr />
                    </div>

                    {/*
                    {(!this.state.disableWeeklyEmails && !this.state.remoteAuth) && (
                      <div>
                        <div>
                          <p>Notification Preferences</p>
                        </div>

                        <div className="row-5">
                          <div className="fixed-column-20">
                            <div className="mini-spacer" /><div className="mini-spacer" />
                            <input type="checkbox" name="subscribed" checked={this.state.subscribed} onChange={this.formChangeHandler} />
                          </div>
                          <div className="calc-column-offset-20">
                            <p className="description-text-2">Receive weekly emails of event, project, and work opportunities based on your interests.</p>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="row-5">
                          <div className="fixed-column-20">
                            <div className="mini-spacer" /><div className="mini-spacer" />
                            <input type="checkbox" name="subscribedForReminders" checked={this.state.subscribedForReminders} onChange={this.formChangeHandler} />
                          </div>
                          <div className="calc-column-offset-20">
                            <p className="description-text-2">Receive text reminders before the event (if enabled by event creator).</p>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.serverErrorMessage && this.state.serverErrorMessage !== '') && <p className="description-text-3 bold-text error-color">{this.state.serverErrorMessage}</p>}

                        <hr />

                        <Link to={'/app/edit-profile/public-preferences'}><p>{(this.state.publicProfile) ? "Make Your Profile Private" : "Make Your Profile Public"}</p></Link>

                        <hr />
                      </div>
                    )}*/}

                    <div>
                      <Link to={'/app/trends'}><p>View Labor Market Trends</p></Link>
                      <hr />
                    </div>
                    {/*
                    {(this.state.testResumeExport) && (
                      <div>
                        <div id="exportContent">
                          <h1 style={{ textAlign: 'center' }}>Example Content</h1>
                          <p style={{ textAlign: 'center' }}>creightontaylor@gmail.com</p>

                          <div>
                            <div style={{ float: 'left', width: '10%' }}>
                              <p>2020 - 2021</p>
                            </div>
                            <div style={{ float: 'left', width: '80%' }}>
                              <p style={{ fontWeight: 'bold' }}>Rochdale Investment Management</p>
                              <p>Portfolio Analyst</p>
                            </div>
                            <div style={{ float: 'left', width: '10%' }}>
                              <p>Los Angeles, CA</p>
                            </div>
                            <div style={{ clear: 'both' }} />
                          </div>
                        </div>
                        <button className="btn btn-squarish" disabled={this.state.isLoading} onClick={() => this.exportToCSV('resume')}>Export</button>
                      </div>
                    )}*/}

                    {(this.state.showWalkthrough) && (
                      <div>
                        <Link to={'/app/walkthrough'}><p>View Walk-through</p></Link>
                        <hr />
                      </div>
                    )}

                    {(!this.state.remoteAuth) && (
                      <div>
                        <SwitchOrgs
                          emailId={this.state.emailId} activeOrg={this.state.activeOrg} myOrgs={this.state.myOrgs}
                          sharePartners={this.state.sharePartners} roleName={this.state.roleName}
                          academies={this.state.academies} academyCodes={this.state.academyCodes}
                          accountCode={this.state.emp} passActiveOrg={this.props.passActiveOrg}
                          history={this.props.navigate}
                        />

                        <Link to={'/app/change-password'}><p>Change Password</p></Link>
                        <hr />

                        {(this.state.showChangeEmail || this.state.showChangeEmailOnlyParticipants) && (
                          <div>
                            <Link to={'/app/change-email'}><p>Change Email Account</p></Link>
                            <hr />
                          </div>
                        )}

                        <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showInviteMembersWidget: true })}>
                          <div>
                            <p className="cta-color">Invite People to Join</p>
                          </div>
                        </button>

                        <div className="spacer"/><div className="half-spacer"/>
                        <hr className="clear-margin clear-padding"/>
                        <div className="spacer"/><div className="half-spacer"/>

                        <div>
                          <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showCredits: true })}><p>{this.state.availableCredits} Available AI Credits</p></button>
                          <hr />
                        </div>

                        <div>
                          <div className="full-width">
                            <button className="background-button left-text full-width" onClick={() => this.setState({ showConfirmDelete: true })}>
                              <div>
                                <p className="error-color">Delete Account Permanently</p>
                              </div>
                            </button>
                          </div>

                          {(this.state.showConfirmDelete) && (
                            <div className="full-width">
                              <label className="profile-label">Are you sure you want to delete your entire profile?</label>
                              <button className="btn btn-squarish error-background-color" onClick={() => this.deleteAccount()}>
                                Delete Permanently
                              </button>
                              <button className="btn btn-squarish white-background cta-color left-margin" onClick={() => this.setState({ showConfirmDelete: false })}>
                                Cancel
                              </button>
                            </div>
                          )}

                          <div className="clear" />

                          <div className="spacer"/><div className="half-spacer"/>
                          <hr className="clear-margin clear-padding"/>
                          <div className="spacer"/><div className="half-spacer"/>
                        </div>

                        <div className="spacer"/><div className="half-spacer"/>
                      </div>
                    )}

                    {/*
                    <button className="background-button" onClick={() => this.contactUs()}><p>Contact Us > ></p></button>
                    <hr />*/}

                    {(!this.state.remoteAuth) && (
                      <button className="btn btn-secondary" onClick={() => signOut(
                        this.state.emailId, this.state.activeOrg, this.state.orgFocus,
                        this.state.accountCode, this.state.roleName, this.props.navigate
                      )}>Sign Out</button>
                    )}
                </div>

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                 >

                  {(this.state.showExportData) && (
                    <div key="export" className="full-width padding-40">
                      <p className="heading-text-2 bottom-padding">Export your Data</p>

                      <div className="row-10">
                        <div className="calc-column-offset-100-static top-padding-5">
                          <p className="heading-text-5">Basic Info</p>
                        </div>
                        <div className="fixed-column-100">
                          <button className="btn btn-squarish" disabled={this.state.isLoading} onClick={() => this.exportToCSV('basicInfo')}>Export</button>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  )}

                  {(this.state.showInviteMembersWidget) && (
                    <div key="export" className="full-width padding-20">
                      <SubInviteMembers orgName={this.state.orgName} closeModal={this.closeModal} />
                    </div>
                  )}

                  {(this.state.showCredits) && (
                    <div key="showCredits" className="full-width padding-20">
                      <div>
                        <div className="calc-column-offset-30">
                          <p className="heading-text-2">AI Credits</p>
                        </div>
                        <div className="fixed-column-30">
                          <button className="background-button" onClick={() => this.closeModal()}>
                          </button>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="spacer" /><div className="spacer" />

                      <div className="row-20">
                       <p>Since AI capabilities are costly, users must expend AI credits to use AI capabilities. Users all receive {this.state.startingCredits} credits for free. Additional credits may be purchased for $1 per credit or sponsored by your community administrator.</p>

                       <p className="top-margin-20">Please email questions or requests to help@guidedcompass.com.</p>
                      </div>

                      <div className="row-20">
                       <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                      </div>

                    </div>
                  )}

                </Modal>
            </div>

        )
      }
    }
}


export default withRouter(Settings);
