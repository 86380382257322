import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubGoalDetails from '../Common/GoalDetails';
import SubRenderExperience from '../Common/RenderExperience';
import ProjectDetails from './ProjectDetails';
import SubEmployerDetails from './EmployerDetails';
import SubCourseDetails from './CourseDetails';
import SubMatches from './Matches';
import SubEditProfileDetails from './EditProfileDetails';
import withRouter from '../Functions/WithRouter';

const mentoringIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-blue.png';
const assessmentsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-grey.png';

const pathwaysIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-grey.png';
const pathwaysIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';
const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const benchmarksIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-grey.png';

const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
const resumeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-blue.png';
const websiteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/website-icon-dark.png';
const linkedinIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/linkedin-icon-dark.png';

const logIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/log-icon-grey.png';
const projectsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-grey.png';
const projectsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-blue.png';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const logIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/log-icon-dark.png';
const logIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/log-icon-blue.png';
const benchmarksIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-blue.png';
const benchmarksIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-dark.png';
const endorsementsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/endorsement-icon-dark.png';
const endorsementsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/endorsement-icon-blue.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png';
const assessmentsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-blue.png';
const assessmentsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-dark.png';
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const assignmentsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-blue.png';
const assignmentsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-dark.png';

const videoIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/video-icon-dark.png';
const letterOfRecIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/letter-of-rec-icon.png';
const transcriptIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/transcript-icon.png';
const idIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/identification-icon.png';
const editIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

const resourcesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resources-icon-blue.png';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class StudentDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            showTwoSideNavSections: true,

            sideNavData: [],

            evaluateOptions: [], helpOptions: [], communicationOptions: [], trackOptions: [],
            documentTypeOptions: ['All','Resumes','Cover Letters','Career Plans','Financial Plans','Business Plans','Mock Interviews'],
            submissionTypeOptions: ['All','Courses','RSVPs','Projects','Applications','Other'],

            logCategorySelected: 'All',
            logCategories: ['All','Goals','Advising Sessions','Applications','Interviews','Offers','Passions'],

            gradYear: '',

            advisee: null,
            adviseeDetails: null,
            sessions: [],
            sentChecklists: [],
            sentResources: [],
            planData: null,
            interestScoreData: null,
            favorites: [],
            logs: [],
            wpQuestions: [],
            endorsements: [],
            projects: [],

            benchmark: null,
            benchmarkOptions: [],

            resumeURLValue: '',
            customWebsiteURLValue: '',
            linkedInURLValue: '',

            categorySelected: { title: "Basic Profile Info" },
            onboardedItemsComplete: 0,
            totalOnboardItems: 6, //session, checklist, resource, career goal, assessment, endorsement
            detailIndex: 0,

            resultsCategorySelected: 0,
            careersCategorySelected: 0,
            favoritesCategorySelected: 0,
            docCategorySelected: 'All',
            submissionCategorySelected: 'All'

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.nestedRetrieveData = this.nestedRetrieveData.bind(this)

        this.retrieveFavorites = this.retrieveFavorites.bind(this)
        this.renderLogs = this.renderLogs.bind(this)
        this.renderActionItems = this.renderActionItems.bind(this)
        this.renderAssessments = this.renderAssessments.bind(this)
        this.renderFavorites = this.renderFavorites.bind(this)
        this.renderSubmissions = this.renderSubmissions.bind(this)
        this.renderDocuments = this.renderDocuments.bind(this)
        this.renderEnrollments = this.renderEnrollments.bind(this)

        this.renderProfile = this.renderProfile.bind(this)
        this.renderEndorsements = this.renderEndorsements.bind(this)
        this.renderEndorsedSkills = this.renderEndorsedSkills.bind(this)
        this.renderSpecificSkillTraits = this.renderSpecificSkillTraits.bind(this)
        this.renderProjects = this.renderProjects.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
        this.renderRoleName = this.renderRoleName.bind(this)
    }

    componentDidMount() {
      console.log('componentDidMount called in studentDetails')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in substudentdetails: ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if ((!prevProps.member && this.props.member) || this.props.member !== prevProps.member) {
        this.retrieveData()
      } else if (this.props.queryEmail !== prevProps.queryEmail) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in studentDetails')

      let email = localStorage.getItem('email');
      let orgFocus = localStorage.getItem('orgFocus')
      let studentAliasSingular = localStorage.getItem('studentAliasSingular')
      let mentorAliasSingular = localStorage.getItem('mentorAliasSingular')

      // console.log('studentAliasSingular? ', mentorAliasSingular)

      const org = this.props.activeOrg
      const queryEmail = this.props.queryEmail
      const member = this.props.member

      // console.log('show member: ', orgFocus, queryEmail, member)

      if (!member) {
        console.log('no member found')

        Axios.get('/api/users/profile/details/' + queryEmail)
        .then((response) => {
          console.log('Profile info query attempted in studentDetails', response.data);

          if (response.data.success) {
            console.log('profile info query worked')

            const member = response.data.user

            if (!orgFocus || orgFocus === '') {

              Axios.get('/api/org', { params: { orgCode: org } })
              .then((response) => {
                console.log('Org info query attempted', response.data);

                if (response.data.success) {
                  console.log('org info query worked for orgFocus')

                  let orgFocus = response.data.orgInfo.orgFocus
                  // let orgContactEmail = response.data.orgInfo.contactEmail

                  this.setState({ orgFocus, emailId: email, studentAliasSingular, mentorAliasSingular,
                    member, queryEmail
                  });

                  localStorage.setItem('orgFocus', orgFocus)

                  this.nestedRetrieveData(member, org, orgFocus)

                } else {
                  console.log('org info query did not work', response.data.message)
                }
              }).catch((error) => {
                  console.log('Org info query did not work for some reason', error);
              });
            } else {
              console.log('no need to get orgFocus')
              this.setState({ emailId: email, studentAliasSingular, mentorAliasSingular, member, org, queryEmail, orgFocus });
              this.nestedRetrieveData(member, org, orgFocus)
            }

          } else {
            console.log('profile query did not work', response.data.message)

          }
        }).catch((error) => {
            console.log('Profile query did not work for some reason', error);
        });

      } else if (!orgFocus || orgFocus === '') {
        // retrieve orgFocus
        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked for orgFocus')

            let orgFocus = response.data.orgInfo.orgFocus

            this.setState({ orgFocus, emailId: email, member, queryEmail, studentAliasSingular, mentorAliasSingular });
            localStorage.setItem('orgFocus', orgFocus)

            this.nestedRetrieveData(member, org, orgFocus)

          } else {
            console.log('org info query did not work', response.data.message)
          }
        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      } else {
        this.setState({ emailId: email, member, org, queryEmail, orgFocus, studentAliasSingular, mentorAliasSingular });
        this.nestedRetrieveData(member, org, orgFocus)
      }

    }

    nestedRetrieveData(member, org, orgFocus) {
      console.log('nestedRetrieveData called in studentDetails', member, this.state.member, this.props.member)

      if (member) {

        const sideNavData = [
          { title: "Basic Profile Info", unselectedIcon: profileIconDark, selectedIcon: profileIconBlue, section: 1 },
          { title: "Career Assessments", unselectedIcon: assessmentsIconDark, selectedIcon: assessmentsIconBlue, section: 1 },
          { title: "Goals & Logs", unselectedIcon: logIconDark, selectedIcon: logIconBlue, section: 1 },
          { title: "Documents", unselectedIcon: resumeIconDark, selectedIcon: resumeIconBlue, section: 1 },
          { title: "Favorites", unselectedIcon: favoritesIconDark, selectedIcon: favoritesIconBlue, section: 2 },
          { title: "Enrollments", unselectedIcon: courseIconDark, selectedIcon: courseIconBlue, section: 2 },
          { title: "Submissions", unselectedIcon: assignmentsIconDark, selectedIcon: assignmentsIconBlue, section: 2 },
          { title: "Endorsements", unselectedIcon: endorsementsIconDark, selectedIcon: endorsementsIconBlue, section: 2 },
          // { title: "Top Matches", unselectedIcon: benchmarksIconDark, selectedIcon: benchmarksIconBlue, section: 2 },
        ]

        let personalInformation = []

        if (member.phoneNumber) {
          personalInformation.push({ name: "Phone Number", value: member.phoneNumber })
        }
        if (member.alternativePhoneNumber) {
          personalInformation.push({ name: "Alternative Phone Number", value: member.alternativePhoneNumber })
        }
        if (member.alternativeEmail) {
          personalInformation.push({ name: "Alternative Email", value: member.alternativeEmail })
        }

        if (member.races && member.races.length > 0) {
          personalInformation.push({ name: "Races", value: member.races.toString().replace(/,/g,", ") })
        } else {
          if (member.selfDescribedRace) {
            personalInformation.push({ name: "Race", value: member.selfDescribedRace })
          } else if (member.race) {
            personalInformation.push({ name: "Race", value: member.race })
          }

        }

        if (member.gender) {
          personalInformation.push({ name: "Gender", value: member.gender })
        }
        if (member.preferredPronoun) {
          personalInformation.push({ name: "Preferred Pronoun", value: member.preferredPronoun })
        }
        if (member.veteran) {
          personalInformation.push({ name: "Veteran", value: member.veteran })
        }
        if (member.workAuthorization) {
          personalInformation.push({ name: "Work Authorization", value: member.workAuthorization })
        }
        if (member.dateOfBirth) {
          personalInformation.push({ name: "Date of Birth", value: member.dateOfBirth })
        }
        if (member.numberOfMembers) {
          personalInformation.push({ name: "Number of Members", value: member.numberOfMembers })
        }
        if (member.householdIncome) {
          personalInformation.push({ name: "Household Income", value: member.householdIncome })
        }
        if (member.referrerName) {
          personalInformation.push({ name: "Referrer Name", value: member.referrerName })
        }
        if (member.referrerEmail) {
          personalInformation.push({ name: "Referrer Email", value: member.referrerEmail })
        }
        if (member.referrerOrg) {
          personalInformation.push({ name: "Referrer Org", value: member.referrerOrg })
        }

        if (member.language) {
          personalInformation.push({ name: "Language", value: member.language })
        }

        if (member.industry) {
          personalInformation.push({ name: "Industry", value: member.industry })
        }

        if (member.jobTitle) {
          personalInformation.push({ name: "Job Title", value: member.jobTitle })
        }

        if (member.employerName) {
          personalInformation.push({ name: "Employer Name", value: member.employerName })
        }

        if (member.facebookURL) {
          personalInformation.push({ name: "Facebook URL", value: member.facebookURL })
        }

        if (member.instagramURL) {
          personalInformation.push({ name: "Instagram URL", value: member.instagramURL })
        }

        if (member.twitterURL) {
          personalInformation.push({ name: "Twitter URL", value: member.twitterURL })
        }

        if (member.careerStatus) {
          personalInformation.push({ name: "Career Status", value: member.careerStatus })
        }

        if (member.dreamCareer) {
          personalInformation.push({ name: "Dream Career", value: member.dreamCareer })
        }

        if (member.primaryCareerInterest) {
          personalInformation.push({ name: "Primary Career Interest", value: member.primaryCareerInterest })
        }

        if (member.secondaryCareerInterest) {
          personalInformation.push({ name: "Secondary Career Interest", value: member.secondaryCareerInterest })
        }

        if (member.tertiaryCareerInterest) {
          personalInformation.push({ name: "Tertiary Career Interest", value: member.tertiaryCareerInterest })
        }

        if (member.educationStatus) {
          personalInformation.push({ name: "Education Status", value: member.educationStatus })
        }

        if (member.adversityList && member.adversityList.length > 3) {
          personalInformation.push({ name: "Background Information", value: member.adversityList.toString().replace(/,/g,", ") })
        }

        let pathPrefix = '/organizations/' + org
        if (window.location.pathname.includes('/advisor')) {
          pathPrefix = '/advisor'
        }

        // isSubsidyEligible, isWorkforceReady, isWorkReadyCertified, hasWorkPermit
        // exploringCareerPaths, definingGoals, activelyLookingForWork, lookingToChangeEmployers, happilyEmployed

        const evaluateOptions = [
          { name: 'Edit career development status', category: "Evaluate", action: 'Link', url: pathPrefix + '/students/' + member.email + '/evaluations', passedState: { student: member }},
          { name: 'Edit internal notes', category: "Evaluate", action: 'Link', url: pathPrefix + '/students/' + member.email + '/evaluations', passedState: { student: member }},
          { name: 'Add interview scores', category: "Evaluate", action: 'Link', url: pathPrefix + '/students/' + member.email + '/evaluations', passedState: { student: member }},
          { name: 'Log a meeting/session', category: "Track", action: 'Link', url: pathPrefix + '/logs/new' },
        ]
        console.log('log evaluation options: ', evaluateOptions)

        const helpOptions = [
          { name: 'Give Feedback on Projects', category: "Help", action: 'Button', passedState: { categorySelected: { title: "Profile Info" }, modalIsOpen: false, showHelpProfile: false }},
          { name: 'Suggestions for Goals', category: "Help", action: 'Button', passedState: { categorySelected: { title: "Logs" }, modalIsOpen: false, showHelpProfile: false }},
          { name: 'Endorse competencies', category: "Help", action: 'Link', url: pathPrefix + '/endorsements/new', },
          { name: 'Refer for roles', category: "Help", action: 'Link', url: pathPrefix + '/postings' },
        ]

        const communicationOptions = [
          { name: 'Send message', category: "Communicate", action: 'Link', url: pathPrefix + '/messages/' + member.email, passedState: { member, recipient: member } },
          { name: 'Share profile with outsiders', category: "Communicate", action: 'Link', url: '/' + member.username + '/profile' },
          { name: 'Give a shoutout on the newsfeed', category: "Communicate", action: 'Link', url: pathPrefix + '/news-feed' },
        ]


        this.setState({ sideNavData, personalInformation, evaluateOptions, helpOptions, communicationOptions, pathPrefix
        })

        Axios.get('/api/workoptions')
          .then((response) => {
            console.log('Work options query tried', response.data);

            if (response.data.success) {
              console.log('Work options query succeeded')

              let functionOptions = []
              for (let i = 1; i <= response.data.workOptions[0].functionOptions.length; i++) {
                if (i > 1) {
                  functionOptions.push({ value: response.data.workOptions[0].functionOptions[i - 1]})
                }
              }

              let industryOptions = []
              for (let i = 1; i <= response.data.workOptions[0].industryOptions.length; i++) {
                if (i > 1) {
                  industryOptions.push({ value: response.data.workOptions[0].industryOptions[i - 1]})
                }
              }

              let gradeOptions = [' '].concat(response.data.workOptions[0].gradeOptions)
              // console.log('gradeOptions: ', gradeOptions)
              this.setState({ functionOptions, industryOptions, gradeOptions })


            } else {
              console.log('no jobFamilies data found', response.data.message)

            }
        }).catch((error) => {
            console.log('query for work options did not work', error);
        })

        Axios.get('/api/users/profile/details/' + member.email)
        .then((response) => {
          console.log('User query attempted', response.data);

          if (response.data.success) {
            console.log('user query worked', response.data)

            const workPreferences = {
              targetJobTitle: response.data.user.targetJobTitle,
              targetEmployerName: response.data.user.targetEmployerName,
              targetFunctions: response.data.user.targetFunctions,
              targetIndustries: response.data.user.targetIndustries,
              sampleJobTitles: response.data.user.sampleJobTitles,
              studyFields: response.data.user.studyFields,
              isSeekingWork: response.data.user.isSeekingWork,
              mustBeClose: response.data.user.mustBeClose,
              hourlyPay: response.data.user.hourlyPay,
              annualPay: response.data.user.annualPay,
              hoursPerWeek: response.data.user.hoursPerWeek,
              workDistance: response.data.user.workDistance,
              workType: response.data.user.workType,
              workZipcode: response.data.user.workZipcode
            }
            this.setState({ workPreferences })

            if (response.data.user.favoritesArray) {
              if (response.data.user.favoritesArray.length !== 0) {
                this.retrieveFavorites(response.data.user.favoritesArray)
              }
            }
          } else {
            console.log('user query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('User query did not work', error);
        });

        Axios.get('/api/projects', { params: { emailId: member.email } })
        .then((response) => {
          console.log('Projects query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved projects')

              if (response.data.projects) {

                let projects = response.data.projects
                if (projects.length > 0) {
                  console.log('the array is greater than 0')
                  this.setState({ projects })

                }
              } else {
                this.setState({ projects: [] })
              }

            } else {
              console.log('no project data found', response.data.message)
              this.setState({ projects: [] })
            }

        }).catch((error) => {
            console.log('Project query did not work', error);
        });

        Axios.get('/api/experience', { params: { emailId: member.email } })
        .then((response) => {
          console.log('Experience query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved experience')

              if (response.data.experience) {

                const experience = response.data.experience
                if (experience.length > 0) {
                  this.setState({ experience })

                }
              } else {
                this.setState({ experience: [] })
              }

            } else {
              console.log('no experience data found', response.data.message)
              this.setState({ experience: [] })
            }

        }).catch((error) => {
            console.log('Experience query did not work', error);
        });

        // console.log('about to extra')
        Axios.get('/api/extras', { params: { emailId: member.email } })
        .then((response) => {
          console.log('Extras query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved extras')

            if (response.data.extras) {

              const extras = response.data.extras
              if (extras.length > 0) {
                console.log('the array is greater than 0')

                let extracurriculars = []
                let awards = []

                for (let i = 0; i < extras.length; i++) {
                  if (extras[i].type === 'Extracurricular') {
                    extracurriculars.push(extras[i])
                  } else if (extras[i].type === 'Award') {
                    awards.push(extras[i])
                  }
                }

                this.setState({ extracurriculars, awards })

              }
            } else {
              this.setState({ extracurriculars: [], awards: [] })
            }

          } else {
            console.log('no extracurricular data found', response.data.message)
            this.setState({ extracurriculars: [], awards: [] })
          }

        }).catch((error) => {
            console.log('Experience query did not work', error);
        });

        Axios.get('/api/partners', { params: { partnerEmail: member.email } })
        .then((response) => {
          console.log('Partner query attempted', response.data);

          if (response.data.success) {
            console.log('partner query worked')

            if (response.data.partners && response.data.partners.length !== 0) {
              let partners = response.data.partners
              this.setState({ partners });

              for (let i = 1; i <= partners.length; i++) {

                const index = i - 1
                const partner = partners[index]
                let partnerEmail = partner.advisorEmail
                if (member.roleName === 'Mentor') {
                  partnerEmail = partner.adviseeEmail
                }

                Axios.get('/api/users/profile/details', { params: { email: partnerEmail } })
                .then((response) => {
                  console.log('Profile query attempted', response.data);

                  if (response.data.success) {
                    console.log('profile query worked')

                    console.log('show data: ', partner, response.data)

                    if (response.data.user.jobTitle) {
                      partner['jobTitle'] = response.data.user.jobTitle
                    }

                    if (response.data.user.employerName) {
                      partner['employerName'] = response.data.user.employerName
                    }

                    if (response.data.user.politicalAlignment) {
                      partner['politicalAlignment'] = response.data.user.politicalAlignment
                    }

                    if (response.data.user.pictureURL) {
                      partner['pictureURL'] = response.data.user.pictureURL
                    }
                    console.log('show partner: ', partner)

                    const selectedPartner = response.data.user

                    partners[index] = partner
                    this.setState({ partners, selectedPartner })

                    console.log('show partners: ', partners)

                  } else {
                    console.log('query for profile query did not work', response.data.message)
                  }

                }).catch((error) => {
                    console.log('Profile query did not work for some reason', error);
                });

              }
            }

          } else {
            console.log('query for partners query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Partners query did not work for some reason', error);
        });

        Axios.get('/api/documents', { params: { emailId: member.email } })
        .then((response) => {
          console.log('Documents query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved documents')

              let documents = response.data.documents
              this.setState({ documents })

            } else {
              console.log('no documents found', response.data.message)
              this.setState({ projects: [] })
            }

        }).catch((error) => {
            console.log('Documents query did not work', error);
        });

        Axios.get('/api/courses/enrollments', { params: { emailId: member.email }})
       .then((response) => {
         console.log('Enrollments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved enrollments')

           let courseIds = []
           for (let i = 1; i <= response.data.enrollments.length; i++) {
             if (response.data.enrollments[i - 1].courseId) {
               courseIds.push(response.data.enrollments[i - 1].courseId)
             }
           }

           // pullLMSCourses(courseIds)
           if (courseIds.length > 0) {
             let courseQueryObject = { orgCodes: [org], isActive: true, availableToEveryone: true, courseIds, resLimit: 100 }
             // console.log('show the params: ', courseQueryObject)
             Axios.get('/api/courses', { params: courseQueryObject })
             .then((response2) => {
               console.log('Courses query attempted in coursesTwo', response2.data);

               if (response2.data.success) {
                 console.log('successfully retrieved courses')

                 const enrollments = response2.data.courses
                 this.setState({ enrollments })

               } else {
                 console.log('no course data found', response2.data.message)
                 // this.setState({ animating: false, errorMessage: 'Found no courses that match the criteria'})
               }

             }).catch((error) => {
                 console.log('Course query did not work in basic query', error);
                 // this.setState({ animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
             });
           }

         } else {
           console.log('no enrollments data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Enrollments query did not work', error);
       });

        const self = this

        function pullProjectSubmissions(submissions) {
          console.log('pullProjectSubmissions called')

          Axios.get('/api/users/submissions', { params: { emailId: member.email }})
          .then((response) => {

            if (response.data.success) {
              console.log('Submissions query worked', response.data);

              for (let i = 1; i <= response.data.postings.length; i++) {
                let posting = response.data.postings[i - 1]
                posting['subType'] = "Project"
                submissions.push(posting)
              }

              self.setState({ submissions })

            } else {
              console.log('no posting data found', response.data.message)
              self.setState({ submissions })
            }

          }).catch((error) => {
              console.log('Submissions query did not work', error);
          });
        }

        function pullRSVPs(submissions) {
          console.log('pullRSVPs called')

          Axios.get('/api/users/rsvps', { params: { emailId: member.email } })
          .then((response) => {

            if (response.data.success) {
              console.log('Rsvps query worked', response.data);

              // let rsvps = response.data.rsvps
              // submissions = submissions.concat(rsvps)

              for (let i = 1; i <= response.data.rsvps.length; i++) {
                let rsvp = response.data.rsvps[i - 1]
                rsvp['subType'] = "RSVP"
                submissions.push(rsvp)
              }

              pullProjectSubmissions(submissions)

            } else {
              console.log('no rsvp data found', response.data.message)
              pullProjectSubmissions(submissions)
            }

          }).catch((error) => {
              console.log('Passion query did not work', error);
          });
        }

        function pullSubmissions(applications) {
          console.log('pullSubmissions called')

          Axios.get('/api/submissions', { params: { emailId: member.email }})
          .then((response) => {
            console.log('Submissions query attempted', response.data);

            if (response.data.success) {
              console.log('submissions query worked')

              let submissions = response.data.submissions
              submissions = submissions.concat(applications)

              // self.setState({ submissions })
              pullRSVPs(submissions)
            } else {
              console.log('submissions query did not work', response.data.message)
              pullRSVPs(applications)
            }

          }).catch((error) => {
              console.log('submissions query did not work for some reason', error);
          });
        }

        function pullApplications(passedLogs) {
          console.log('pullApplications called')

          Axios.get('/api/applications/byuser', { params: { userEmail: member.email, orgCode } })
          .then((response) => {

            if (response.data.success && response.data.applications) {
              console.log('Applications query worked', response.data);

              let applications = response.data.applications
              for (let i = 1; i <= applications.length; i++) {
                console.log('compare org: ', applications[i - 1].orgCode, org)
                applications[i - 1]['logType'] = 'Application'
                applications[i - 1]['subType'] = 'Application'
              }

              let logs = passedLogs
              logs = logs.concat(applications)
              // console.log('show logs the second time: ', logs)

              let completedItems = applications
              self.setState({ applications, logs, completedItems })
              pullSubmissions(applications)

            } else {
              console.log('no applications found', response.data.message)
              pullSubmissions([])
            }

          }).catch((error) => {
              console.log('Applications query did not work', error);
          });
        }

        Axios.get('/api/logs/user', { params: { email: member.email } })
        .then((response) => {

          if (response.data.success) {
            console.log('Logs query worked', response.data);

            let logs = this.state.logs
            logs = logs.concat(response.data.logs)
            console.log('show logs the first time: ', logs)
            this.setState({ logs })
            pullApplications(logs)

          } else {
            console.log('no logs found', response.data.message)

            let logs = []
            this.setState({ logs })
            pullApplications(logs)
          }

        }).catch((error) => {
            console.log('Logs query did not work', error);
        });

        let orgCode = null
        if (this.state.orgFocus === 'Placement') {
          orgCode = org
        }

        // console.log('compare orgFocuses:', this.state.orgFocus, orgCode, org)

        //pull assessment result data
        Axios.get('/api/assessment/results', { params: { emailId: member.email } })
        .then((response) => {
            console.log('Assessments query worked', response.data);

            if (response.data.success) {

              let workPreferenceAnswers = response.data.results.workPreferenceAnswers
              let interestScoreData = response.data.results.interestScores
              let skillScoreData = response.data.results.skillsScores
              let newSkillAnswers = response.data.results.newSkillAnswers
              if (newSkillAnswers) {
                newSkillAnswers.sort(function(a,b) {
                  return b.score - a.score;
                })
              }

              let personalityScoreData = response.data.results.personalityScores
              const topGravitateValues = response.data.results.topGravitateValues
              const topEmployerValues = response.data.results.topEmployerValues

              console.log('data: ', workPreferenceAnswers)

              this.setState({ workPreferenceAnswers, interestScoreData, skillScoreData, newSkillAnswers, personalityScoreData, topGravitateValues, topEmployerValues })

            } else {
              console.log('there was an error:', response.data.message);
              this.setState({ workPreferenceAnswers: null, interestScoreData: null, skillScoreData: null, newSkillAnswers: null, personalityScoreData: null })
            }
        });

        Axios.get('/api/assessment/questions')
        .then((response) => {
            //console.log('O*Net worked', response.data.body, this);
            console.log('show me response', response.data)
            if ( response.data.success === true ) {
              console.log('o-net query worked')

              let wpQuestions = []
              for (let i = 1; i <= response.data.assessments.workPreferenceQuestions.length; i++) {
                wpQuestions.push({ name: response.data.assessments.workPreferenceQuestions[i - 1].name })
              }

              this.setState({ wpQuestions })

            } else {
              console.log('Assessment question query failed', response.data.message)
            }


        }).catch((error) => {
            console.log('O*NET did not work', error);
        });

        //pull endorsement data
        Axios.get('/api/story', { params: { emailId: member.email } })
        .then((response) => {

            if (response.data.success) {
              console.log('Endorsement query worked', response.data);

              this.setState({ endorsements: response.data.stories })
            } else {
              console.log('Endorsement query did not work:', response.data.message)
              this.setState({ endorsements: [] })
            }
        }).catch((error) => {
            console.log('Endorsement query did not work', error);
        });

        if (window.innerWidth > 768) {

            setTimeout(() => {
                this.setState(state => ({
                    opacity: 1,
                    transform: 'translate(2%)',
                  }));
            }, 0.3)

        } else {

            setTimeout(() => {
                this.setState(state => ({
                    opacity: 1,
                    transform: 'translate(2%)',
                  }));
            }, 0.3)

        }
      }
    }

    retrieveFavorites(favorites) {
      console.log('retrieveFavorites called', favorites)

      Axios.get('/api/favorites/detail', { params: { favorites } })
      .then((response) => {
          console.log('Favorites query attempted', response.data);

          if (response.data.success) {
            console.log('we had success yo')

            let favorites = response.data.favorites
            let types = response.data.types

            this.setState({ favorites, types })

          } else {
            console.log('there was an error:', response.data.message);
          }
      });
    }

    renderLogs() {
      console.log('renderLogs called', this.state.logs.length)

      let rows = [];

      rows.push(
        <div key={0}>

        <div className="row-20">
          <p className="heading-text-2">Goals & Logs</p>
        </div>

        <div>
          <div className="carousel-3 top-margin" onScroll={this.handleScroll}>
            {this.state.logCategories.map((value, index) =>
              <div className="display-inline heading-text-6">
                {(this.state.logCategories[index] === this.state.logCategorySelected) ? (
                  <div className="selected-carousel-item-3 row-10 horizontal-padding">
                    <label key={value}>{value}</label>
                  </div>
                ) : (
                  <button className="menu-button horizontal-padding" onClick={() => this.subNavClicked("log",value)}>
                    <label key={value}>{value}</label>
                  </button>
                )}
              </div>
            )}
            <hr />
          </div>
          <div className="spacer" /><div className="spacer" />
        </div>

        <div className="clear" />

        </div>
      )

      console.log('show existing rows: ', rows.length)

      if ( this.state.logs.length !== 0 ) {

        for (let i = 1; i <= this.state.logs.length; i++) {
          console.log('show logType:', i,this.state.logs[i - 1].logType);

          let prefixLink = '/organizations/' + this.state.org
          let passedState = { logDetails: this.state.logs[i - 1], member: this.state.member }
          // let nativeLinkDisabled = false
          // let nativeLinkStyle = {}
          if (window.location.pathname.includes('/advisor')) {
            prefixLink = '/advisor'
            passedState = { logDetails: this.state.logs[i - 1], advisee: this.state.member }
            // nativeLinkDisabled = true
            // nativeLinkStyle = { pointerEvents: 'none', color: 'rgba(90,90,90,1)'}
          }

          if (this.state.logs[i - 1].logType === 'Session') {

            let subtitle = this.state.logs[i - 1].category
            if (this.state.logs[i - 1].sessionDate) {
              subtitle = convertDateToString(new Date(this.state.logs[i - 1].sessionDate),"datetime-2")
            }

            if (this.state.logCategorySelected === 'All' || this.state.logCategorySelected === 'Advising Sessions') {
              console.log('in session 1:', i, this.state.logs[i - 1])

              rows.push(
                <div key={this.state.logs[i - 1]._id}>
                  <Link className="background-button full-width left-text" to={prefixLink + '/edit-logs/' + this.state.logs[i - 1]._id} state={passedState}>
                    <div className="row-10">
                      <div className="calc-column-offset-40">
                        <p className="heading-text-5 cta-color">W/ {this.state.logs[i - 1].advisorFirstName} {this.state.logs[i - 1].advisorLastName}</p>
                        <p className="description-text-1 description-text-color curtail-text">{subtitle}</p>
                      </div>
                      <div className="fixed-column-40">
                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                </div>
              )
            }
          } else if (this.state.logs[i - 1].logType === 'Goal') {
            if (this.state.logCategorySelected === 'All' || this.state.logCategorySelected === 'Goals') {
              console.log('in session 2:', i, this.state.logs[i - 1])

              rows.push(
                <div key={this.state.logs[i - 1]._id}>
                  <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showGoalDetails: true, selectedGoal: this.state.logs[i - 1] })}>
                    <div className="row-10">
                      <div className="calc-column-offset-40">
                        <p className="heading-text-5 cta-color">{this.state.logs[i - 1].title}</p>
                        <p className="description-text-1 description-text-color curtail-text">Deadline: {convertDateToString(new Date(this.state.logs[i - 1].deadline),"datetime-2")}</p>
                      </div>
                      <div className="fixed-column-40">
                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                      </div>
                      <div className="clear" />
                    </div>
                  </button>
                </div>
              )
            }
          } else if (this.state.logs[i - 1].logType === 'Application') {
            if (this.state.logCategorySelected === 'All' || this.state.logCategorySelected === 'Applications') {

              const year = new Date(this.state.logs[i - 1].createdAt).getFullYear()
              const month = new Date(this.state.logs[i - 1].createdAt).getMonth()
              const day = new Date(this.state.logs[i - 1].createdAt).getDate()
              const formattedMonth = convertDateToString(month,'formatMonth')

              if (this.state.logs[i - 1].postingTitle) {
                rows.push(
                  <div key={this.state.logs[i - 1]._id}>
                    <Link className={(window.location.pathname.includes('/advisor') ? "no-pointers" : "background-button full-width left-text")} onClick={(window.location.pathname.includes('/advisor')) ? e => e.preventDefault() : e => console.log('fake function')} to={'/organizations/' + this.state.org + '/postings/' + this.state.logs[i - 1].postingId + '/applications/' + this.state.logs[i - 1]._id} state={{ selectedJob: this.state.selectedJob, selectedApplication: this.state.logs[i - 1], benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }}>
                      <div className="row-10">
                        <div className="calc-column-offset-40">
                          <p className="heading-text-5 cta-color">{this.state.logs[i - 1].postingTitle} | {this.state.logs[i - 1].postingEmployerName}</p>
                          <p className="description-text-1 description-text-color curtail-text">{formattedMonth} {day}, {year}</p>
                        </div>
                        <div className="fixed-column-40">
                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                        </div>
                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                )
              } else {
                rows.push(
                  <div key={this.state.logs[i - 1]._id}>
                    <Link className="background-button full-width left-text" to={prefixLink + '/logs/' + this.state.logs[i - 1]._id} state={passedState}>
                      <div className="row-10">
                        <div className="calc-column-offset-40">
                          <p className="heading-text-5 cta-color">{this.state.logs[i - 1].positionTitle} | {this.state.logs[i - 1].employerName}</p>
                          <p className="description-text-1 description-text-color curtail-text">{this.state.logs[i - 1].jobFunction} | {formattedMonth} {day}, {year}</p>
                        </div>
                        <div className="fixed-column-40">
                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                        </div>
                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                )
              }
            }
          } else if (this.state.logs[i - 1].logType === 'Interview') {
            if (this.state.logCategorySelected === 'All' || this.state.logCategorySelected === 'Interviews') {

              const year = new Date(this.state.logs[i - 1].createdAt).getFullYear()
              const month = new Date(this.state.logs[i - 1].createdAt).getMonth()
              const day = new Date(this.state.logs[i - 1].createdAt).getDate()
              const formattedMonth = convertDateToString(month,'formatMonth')

              rows.push(
                <div key={this.state.logs[i - 1]._id}>
                  <Link className="background-button full-width left-text" to={prefixLink + '/logs/' + this.state.logs[i - 1]._id} state={passedState}>
                    <div className="row-10">
                      <div className="calc-column-offset-40">
                        <p className="heading-text-5 cta-color">{this.state.logs[i - 1].associatedApplicationPositionTitle} | {this.state.logs[i - 1].associatedApplicationEmployerName}</p>
                        <p className="description-text-1 description-text-color curtail-text">Fit Rating: {this.state.logs[i - 1].fitRating} | {formattedMonth} {day}, {year}</p>
                      </div>
                      <div className="fixed-column-40">
                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                </div>
              )
            }
          } else if (this.state.logs[i - 1].logType === 'Offer') {
            if (this.state.logCategorySelected === 'All' || this.state.resultsCategorySelected === 'Offers') {

              const year = new Date(this.state.logs[i - 1].createdAt).getFullYear()
              const month = new Date(this.state.logs[i - 1].createdAt).getMonth()
              const day = new Date(this.state.logs[i - 1].createdAt).getDate()
              const formattedMonth = convertDateToString(month,'formatMonth')

              rows.push(
                <div key={this.state.logs[i - 1]._id}>
                  <Link className="background-button full-width left-text" to={prefixLink + '/logs/' + this.state.logs[i - 1]._id} state={passedState}>
                    <div className="row-10">
                      <div className="calc-column-offset-40">
                        <p className="heading-text-5 cta-color">{this.state.logs[i - 1].positionTitle} | {this.state.logs[i - 1].employerName}</p>
                        <p className="description-text-1 description-text-color curtail-text">{this.state.logs[i - 1].jobFunction} | {formattedMonth} {day}, {year}</p>
                      </div>
                      <div className="fixed-column-40">
                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                </div>
              )
            }
          } else if (this.state.logs[i - 1].logType === 'Passion') {

            if (this.state.logCategorySelected === 'All' || this.state.logCategorySelected === 'Passions') {

              const year = new Date(this.state.logs[i - 1].createdAt).getFullYear()
              const month = new Date(this.state.logs[i - 1].createdAt).getMonth()
              const day = new Date(this.state.logs[i - 1].createdAt).getDate()
              const formattedMonth = convertDateToString(month,'formatMonth')

              rows.push(
                <div key={this.state.logs[i - 1]._id}>
                  <Link className="background-button full-width left-text" to={prefixLink + '/logs/' + this.state.logs[i - 1]._id} state={passedState}>
                    <div className="row-10">
                      <div className="calc-column-offset-40">
                        <p className="heading-text-5 cta-color">{this.state.logs[i - 1].passionTitle}</p>
                        <p className="description-text-1 description-text-color curtail-text">Added: {formattedMonth} {day}, {year}</p>
                        <p className="description-text-1 description-text-color curtail-text">{this.state.logs[i - 1].passionReason}</p>
                      </div>
                      <div className="fixed-column-40">
                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                </div>
              )
            }
          }
        }
      } else {
          //show empty state screen
      }

      return rows;
    }

    renderSubmissions() {
      console.log('renderSubmissions called')

      let rows = [];

      rows.push(
        <div key="documents|0">
          <div className="top-margin-20">
            <p className="heading-text-2">Submissions & Related Activity</p>
            <p className="top-margin-5 description-text-color">Surveys, assignments, RSVPs, projects, applications, and other submissions.</p>
          </div>

          <div className="carousel-3 top-margin-25">
            {this.state.submissionTypeOptions.map((item,index) =>
              <div key={"docCategorySelected|" + item} className={(this.state.submissionCategorySelected === item) ? (index === 0) ? "selected-item-container-1 display-inline" : "selected-item-container-1 margin-five-percent-left display-inline" : (index === 0) ? "unselected-item-container-1 display-inline" : "unselected-item-container-1 margin-five-percent-left display-inline"}>
                <div className="bottom-padding">
                  <p className={(this.state.submissionCategorySelected === item) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ submissionCategorySelected: item })}>{item}</a></p>
                </div>
              </div>
            )}
          </div>

          <div className="clear" />
          <hr />
          <div className="spacer" /><div className="spacer" />
        </div>
      )

      let counter = 0

      if (this.state.submissions && this.state.submissions.length !== 0 ) {

        for (let i = 1; i <= this.state.submissions.length; i++) {
          // console.log('show logType:', i,this.state.logs[i - 1].logType);

          const item = this.state.submissions[i - 1]
          let url = this.state.pathPrefix + '/submissions/' + item._id
          let passedState = {}
          if (item.subType === 'Application') {
            url = this.state.pathPrefix + '/postings/' + item.postingId + '/applications/' + item._id
            // url = '/organizations/' + this.state.org + '/postings/' + this.state.logs[i - 1].postingId + '/applications/' + this.state.logs[i - 1]._id

            passedState = {selectedApplication: item }
          } else if (item.subType === 'RSVP') {
            if (window.location.pathname.startsWith('/advisor')) {
              url = this.state.pathPrefix + '/opportunities/' + item.postingId
            } else {
              url = this.state.pathPrefix + '/postings/' + item.postingId
            }
          } else if (item.subType === 'Project') {
            if (window.location.pathname.startsWith('/advisor')) {
              url = this.state.pathPrefix + '/opportunities/' + item._id
            } else {
              url = this.state.pathPrefix + '/postings/' + item._id
            }
          }

          if ((this.state.submissionCategorySelected === "All") || (this.state.submissionCategorySelected === (item.subType + "s"))) {
            counter = counter + 1

            rows.push(
              <div key={item._id}>
                <Link className={"background-button full-width left-text"} to={url} state={passedState}>
                  <div className="row-10">
                    <div className="fixed-column-40">
                      <p className="heading-text-5 cta-color curtail-text">{counter}. </p>
                    </div>
                    <div className="calc-column-offset-140">
                      <p className="heading-text-5 cta-color">{(item.title) ? item.title : (item.courseName) ? item.courseName : (item.lessonName) ? item.lessonName : (item.assessmentName) ? item.assessmentName : (item.title && item.employerName) ? item.title + " @ " + item.employerName : (item.name) ? item.name : (item.postingTitle && item.postingEmployerName) ? item.postingTitle + ' @ ' + item.postingEmployerName : (item.postingTitle) ? item.postingTitle : ""}</p>
                      <p className="description-text-1 description-text-color curtail-text top-margin-5">{(item.subType) ? item.subType + " | " : (item.courseName) ? "Course | " : "Submission | "}{(item.courseName) ? item.courseName : (item.lessonName) ? item.lessonName : (item.assessmentName) ? item.assessmentName : "Submission"} submitted {convertDateToString(new Date(item.createdAt),"datetime-2")}</p>
                    </div>
                    {(item.grade) ? (
                      <div className="fixed-column-60">
                        <p className="heading-text-5 cta-color curtail-text top-margin-5">{item.grade}%</p>
                      </div>
                    ) : (
                      <div className="fixed-column-40 height-40" />
                    )}
                    <div className="fixed-column-40 top-margin">
                      <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            )

            // {this.state.submissions.map((item, optionIndex) =>
            //   <div key={optionIndex +  'curriculum item'}>
            //     <button className="background-button full-width left-text" onClick={() => this.scorecardClicked(item, optionIndex)}>
            //       <div className="standard-border">
            //         <div className="horizontal-padding-5 row-20 thick-border-left-cta">
            //           <div className="fixed-column-40">
            //             <p>{optionIndex + 1}.</p>
            //           </div>
            //           <div className={(window.innerWidth > 768) ? "calc-column-offset-160" : "calc-column-offset-130"}>
            //             <div className={(window.innerWidth > 768) ? "relative-column-50" : "relative-column-50"}>
            //               <p>{item.assessmentName}</p>
            //             </div>
            //             <div className={(window.innerWidth > 768) ? "relative-column-50" : "relative-column-50"}>
            //               <p>{item.lessonName}</p>
            //             </div>
            //           </div>
            //           <div className={(window.innerWidth > 768) ? "fixed-column-100" : "fixed-column-70"}>
            //             {(item.createdAt) ? (
            //               <p>{convertDateToString(new Date(item.createdAt),'date-2')}</p>
            //             ) : (
            //               <p>N/A</p>
            //             )}
            //           </div>
            //           <div className="clear" />
            //         </div>
            //       </div>
            //     </button>
            //   </div>
            // )}
          }
        }
      } else {
          //show empty state screen
      }

      return rows;

    }

    renderDocuments() {
      console.log('renderDocuments called')

      let rows = [];

      rows.push(
        <div key="documents|0">
          <div className="top-margin-20">
            <p className="heading-text-2">Documents Created With the Document Builder</p>
          </div>

          <div className="carousel-3 top-margin-25">
            {this.state.documentTypeOptions.map((item,index) =>
              <div key={"docCategorySelected|" + item} className={(this.state.docCategorySelected === item) ? (index === 0) ? "selected-item-container-1 display-inline" : "selected-item-container-1 margin-five-percent-left display-inline" : (index === 0) ? "unselected-item-container-1 display-inline" : "unselected-item-container-1 margin-five-percent-left display-inline"}>
                <div className="bottom-padding">
                  <p className={(this.state.docCategorySelected === item) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ docCategorySelected: item })}>{item}</a></p>
                </div>
              </div>
            )}
          </div>

          <div className="clear" />
          <hr />
          <div className="spacer" /><div className="spacer" />
        </div>
      )

      if (this.state.documents && this.state.documents.length !== 0 ) {

        for (let i = 1; i <= this.state.documents.length; i++) {
          // console.log('show logType:', i,this.state.logs[i - 1].logType);

          const item = this.state.documents[i - 1]

          if ((this.state.docCategorySelected === "All") || (this.state.docCategorySelected === (item.docType + "s"))) {
            rows.push(
              <div key={item._id}>
                <Link className={"background-button full-width left-text"} to={this.state.pathPrefix + '/documents/' + item._id}>
                  <div className="row-10">
                    <div className="calc-column-offset-40">
                      <p className="heading-text-5 cta-color">{(item.fileName) ? item.fileName : (item.docContent) ? item.docContent.docName : ""}</p>
                      <p className="description-text-1 description-text-color curtail-text top-margin-5">{item.docType} created {convertDateToString(new Date(item.createdAt),"datetime-2")}</p>
                    </div>
                    <div className="fixed-column-40">
                      <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            )
          }
        }
      } else {
          //show empty state screen
      }

      return rows;

    }

    renderEnrollments() {
      console.log('renderEnrollments called')

      let rows = [];

      rows.push(
        <div key={0}>
          <div className="row-20">
            <p className="heading-text-2">Course Enrollments</p>
          </div>
        </div>
      )

      if (this.state.enrollments && this.state.enrollments.length !== 0 ) {

        for (let i = 1; i <= this.state.enrollments.length; i++) {
          // console.log('show logType:', i,this.state.logs[i - 1].logType);

          const item = this.state.enrollments[i - 1]

          rows.push(
            <div key={item._id}>
              <Link className={"background-button full-width left-text"} to={this.state.pathPrefix + '/courses/' + item._id}>
                <div className="row-10">
                  <div className="fixed-column-70">
                    <img src={(item.imageURL) ? item.imageURL : (item.image_125_H) ? item.image_125_H : courseIconDark} alt="GC" className="image-60-auto"/>
                  </div>
                  <div className="calc-column-offset-110">
                    <p className="heading-text-5 cta-color">{(item.name) ? item.name : item.title}</p>
                    <p className="description-text-1 description-text-color curtail-text">{(item.description) ? item.description : item.headline}</p>
                  </div>
                  <div className="fixed-column-40">
                    <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                  </div>
                  <div className="clear" />
                </div>
              </Link>
            </div>
          )
        }
      } else {
          //show empty state screen
      }

      return rows;

    }

    renderActionItems() {
      console.log('renderActionItems called', this.state.months)

      //current action items, past action items
      let rows = [];

      rows.push(
        <div key={0}>
          <div className="clear"/>
          <div className="card-title-container-4">
            <h3>Action Items</h3>
          </div>
          <div className="clear"/>
          <div className="spacer" /><div className="spacer" />
        </div>
      )

      if (this.state.months) {
        if ( this.state.months.length !== 0 ) {

          let monthIndex = new Date().getMonth()
          let monthIndex2 = 0

          for (let i = 1; i <= 12; i++) {
            console.log(i);
            console.log('lets see monthIndex first', monthIndex)
            monthIndex = monthIndex + 1
            monthIndex2 = monthIndex2 + 1

            if (monthIndex > 11) {
              monthIndex = 12 - monthIndex
            }

            if (monthIndex2 > 11) {
              monthIndex2 = 12 - monthIndex2
            }
            console.log('lets see monthIndex', monthIndex)

            const actionItemObjects = this.state.months[monthIndex2]
            const month = convertDateToString(monthIndex,'formatMonth')

            console.log('show me what we have', monthIndex, monthIndex2, actionItemObjects)

            rows.push(
              <div key={i}>
                <div className="clear"/>
                <p className="description-text-color">{month}</p>
                <div className="spacer" />
                {this.renderActionItemsDetail(monthIndex, actionItemObjects)}
                <div className="spacer" /><div className="spacer" />
                <hr className="app-separator"/>
                <div className="spacer" />
              </div>
            )
          }
        }
      }

      return rows
    }

    renderActionItemsDetail(monthIndex, actionItemObjects) {
      console.log('renderActionItemsDetail called')

      let rows = []

      if (actionItemObjects) {
        for (let i = 1; i <= actionItemObjects.length; i++) {
          console.log('let me see member', this.state.member)
          rows.push(
            <div key={'actionItemDetail' + i}>
              <div className="relative-column-80">
                <p>{actionItemObjects[i - 1].actionItem}</p>
              </div>
              <div className="relative-column-20">
                {this.state.member && (
                  <div>
                    {(actionItemObjects[i - 1].completers.includes(this.state.member.email)) ? (
                      <p className="description-text-2 description-text-color bold-text">Completed</p>
                    ) : (
                      <p className="description-text-2 description-text-color bold-text">Incomplete</p>
                    )}
                  </div>
                )}
              </div>
              <div className="clear" />
            </div>
          )
        }
      }

      return rows
    }

    renderAssessments() {
      console.log('renderAssessments called')

      let rows = [];

      rows.push(
        <div key={0}>
          <div className="top-margin-20">
            <p className="heading-text-2">Assessment Results</p>
          </div>
          <div>
            <div className={(this.state.resultsCategorySelected === 0) ? "app-title-container-1-of-2-1 selected-item-container-1" : "app-title-container-1-of-2-1 unselected-item-container-1"}>
              <p className={(this.state.resultsCategorySelected === 0) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ resultsCategorySelected: 0 })}>Interests</a></p>
            </div>
            <div className={(this.state.resultsCategorySelected === 1) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
              <p className={(this.state.resultsCategorySelected === 1) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ resultsCategorySelected: 1 })}>Skills</a></p>
            </div>
            <div className={(this.state.resultsCategorySelected === 2) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
              <p className={(this.state.resultsCategorySelected === 2) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ resultsCategorySelected: 2 })}>Personality</a></p>
            </div>
            <div className={(this.state.resultsCategorySelected === 3) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
              <p className={(this.state.resultsCategorySelected === 3) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ resultsCategorySelected: 3 })}>Values</a></p>
            </div>
            <div className={(this.state.resultsCategorySelected === 4) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
              <p className={(this.state.resultsCategorySelected === 4) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ resultsCategorySelected: 4 })}>Work Preferences</a></p>
            </div>
          </div>
          <div className="clear" />
          <hr />
          <div className="spacer" /><div className="spacer" />
        </div>
      )

      if (this.state.resultsCategorySelected === 0) {
        //interests
        if (this.state.interestScoreData) {
          if ( this.state.interestScoreData.length !== 0 ) {

            rows.push(
              <div key={"workInterests"}>
                <div className="spacer" /><div className="spacer" />
                <p className="heading-text-3">Work Interests</p>
                <div className="half-spacer" />
                <a href="https://en.wikipedia.org/wiki/Strong_Interest_Inventory" target="_blank" rel="noopener noreferrer"className="description-text-1">Learn More About Strong Interest Inventory</a>
                <div className="spacer" /><div className="spacer" />
              </div>
            )

            for (let i = 1; i <= this.state.interestScoreData.length; i++) {
              console.log(i);

              rows.push(
                <div key={i} className="advisor-clearing-container">
                  <div className="spacer" />
                  <div className="fixed-column-100">
                    {(this.state.interestScoreData[i - 1].score) ? (
                      <p className="heading-text-2 cta-color half-bold-text">{this.state.interestScoreData[i - 1].score / 40 * 100}%</p>
                    ) : (
                      <p className="heading-text-2 cta-color half-bold-text">N/A</p>
                    )}
                  </div>
                  <div className="calc-column-offset-100">
                      <p className="heading-text-3">{this.state.interestScoreData[i - 1].name}</p>
                      <p>{this.state.interestScoreData[i - 1].description}</p>
                  </div>
                  <div className="clear" />
                  <div className="spacer" /><div className="spacer" />
                  <hr />
                  <div className="spacer" />
                </div>
              )
            }
          } else {
            //for some odd reason, data is not there. show empty state screen
          }

        } else {
          //empty state screen
        }
      } else if (this.state.resultsCategorySelected === 1) {

        if (this.state.skillScoreData) {
          if (this.state.newSkillAnswers && this.state.newSkillAnswers.length > 0) {
            for (let i = 1; i <= this.state.newSkillAnswers.length; i++) {
              rows.push(
                <div key={i}>

                  <div>
                    <div className="fixed-column-100">
                      {(this.state.newSkillAnswers[i - 1].score) ? (
                        <p className="heading-text-2 cta-color half-bold-text">{this.state.newSkillAnswers[i - 1].score * 20}%</p>
                      ) : (
                        <p className="heading-text-2 cta-color half-bold-text">N/A</p>
                      )}
                    </div>
                    <div className="calc-column-offset-100 top-margin-5">
                      <p className="heading-text-3">{this.state.newSkillAnswers[i - 1].name}</p>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="clear" />
                  <div className="spacer" />
                  <hr className="clear-margin clear-padding" />
                  <div className="spacer" />
                </div>
              )
            }
          } else {
            if ( this.state.skillScoreData.length !== 0 ) {

              rows.push(
                <div key={0}>
                  <div className="clear" />
                  <div className="spacer"/><div className="spacer"/>
                  <p className="heading-text-2">Top 3 Skills:</p>
                  <div className="spacer"/><div className="spacer"/>
                </div>
              )

              for (let i = 1; i <= this.state.skillScoreData.length; i++) {
                console.log(i);

                rows.push(
                  <div key={i} className="advisor-clearing-container">
                    <hr className="app-separator"/>
                    <div className="spacer"/><div className="spacer"/>
                    <div>
                        <p>{this.state.skillScoreData[i - 1].name}</p>
                        <p className="heading-text-5">Score: {this.state.skillScoreData[i - 1].answer}</p>
                    </div>
                    <div className="spacer"/><div className="spacer"/>
                  </div>
                )
              }
            } else {
              //for some odd reason, data is not there. show empty state screen
            }
          }
        }
      } else if (this.state.resultsCategorySelected === 2) {
        if (this.state.personalityScoreData) {

          // Myers Briggs
          if (this.state.personalityScoreData.myersBriggs) {
            rows.push(
              <div key={"myersBriggs"}>
                <div className="spacer" /><div className="spacer" />
                <p className="heading-text-3">Myers Briggs</p>
                <div className="half-spacer" />
                <a href="https://en.wikipedia.org/wiki/Myers–Briggs_Type_Indicator" target="_blank" rel="noopener noreferrer"className="description-text-1">Learn More About Myers Briggs</a>
                <div className="spacer" /><div className="spacer" />

                <div className="spacer" />
                <div className="fixed-column-100">
                  {(this.state.personalityScoreData.myersBriggs) ? (
                    <p className="heading-text-2 cta-color half-bold-text">{this.state.personalityScoreData.myersBriggs}</p>
                  ) : (
                    <p className="heading-text-2 cta-color half-bold-text">N/A</p>
                  )}
                </div>
                <div className="calc-column-offset-100 top-margin-5">
                  {(this.state.personalityScoreData.myersBriggs[0] === 'E') ? (
                    <label className="heading-text-3 right-padding">Extraverted,</label>
                  ) : (
                    <label className="heading-text-3 right-padding">Intraverted,</label>
                  )}

                  {(this.state.personalityScoreData.myersBriggs[0] === 'N') ? (
                    <label className="heading-text-3 right-padding">Intuitive,</label>
                  ) : (
                    <label className="heading-text-3 right-padding">Sensing,</label>
                  )}

                  {(this.state.personalityScoreData.myersBriggs[0] === 'F') ? (
                    <label className="heading-text-3 right-padding">Feeling,</label>
                  ) : (
                    <label className="heading-text-3 right-padding">Thinking,</label>
                  )}

                  {(this.state.personalityScoreData.myersBriggs[0] === 'J') ? (
                    <label className="heading-text-3 right-padding">Judging</label>
                  ) : (
                    <label className="heading-text-3 right-padding">Perceiving</label>
                  )}

                </div>
                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" />
              </div>
            )
          }

          // Five Factors
          if (this.state.personalityScoreData.fiveFactors) {
            rows.push(
              <div key={"bigFive"}>
                <div className="spacer" /><div className="spacer" />
                <p className="heading-text-3">Big 5 Personality Traits</p>
                <div className="half-spacer" />
                <a href="https://en.wikipedia.org/wiki/Big_Five_personality_traits" target="_blank" rel="noopener noreferrer"className="description-text-1">Learn More About The Big 5</a>
                <div className="spacer" /><div className="spacer" />

                <div className="spacer" />
                <div className="fixed-column-100">
                  {(this.state.personalityScoreData.fiveFactors.opennessScore) ? (
                    <p className="heading-text-2 cta-color half-bold-text">{(this.state.personalityScoreData.fiveFactors.conscientiousnessScore / 15 * 100).toFixed()}%</p>
                  ) : (
                    <p className="heading-text-2 cta-color half-bold-text">N/A</p>
                  )}
                </div>
                <div className="calc-column-offset-100 top-margin-5">
                  <p className="heading-text-3">Openness</p>
                </div>
                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" />

                <div className="spacer" />
                <div className="fixed-column-100">
                  {(this.state.personalityScoreData.fiveFactors.conscientiousnessScore) ? (
                    <p className="heading-text-2 cta-color half-bold-text">{(this.state.personalityScoreData.fiveFactors.conscientiousnessScore / 15 * 100).toFixed()}%</p>
                  ) : (
                    <p className="heading-text-2 cta-color half-bold-text">N/A</p>
                  )}
                </div>
                <div className="calc-column-offset-100 top-margin-5">
                  <p className="heading-text-3">Conscientiousness</p>
                </div>
                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" />

                <div className="spacer" />
                <div className="fixed-column-100">
                  {(this.state.personalityScoreData.fiveFactors.extraversionScore) ? (
                    <p className="heading-text-2 cta-color half-bold-text">{(this.state.personalityScoreData.fiveFactors.extraversionScore / 15 * 100).toFixed()}%</p>
                  ) : (
                    <p className="heading-text-2 cta-color half-bold-text">N/A</p>
                  )}
                </div>
                <div className="calc-column-offset-100 top-margin-5">
                  <p className="heading-text-3">Extraversion</p>
                </div>
                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" />

                <div className="spacer" />
                <div className="fixed-column-100">
                  {(this.state.personalityScoreData.fiveFactors.agreeablenessScore) ? (
                    <p className="heading-text-2 cta-color half-bold-text">{(this.state.personalityScoreData.fiveFactors.agreeablenessScore / 15 * 100).toFixed()}%</p>
                  ) : (
                    <p className="heading-text-2 cta-color half-bold-text">N/A</p>
                  )}
                </div>
                <div className="calc-column-offset-100 top-margin-5">
                  <p className="heading-text-3">Agreeableness</p>
                </div>
                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" />

                <div className="spacer" />
                <div className="fixed-column-100">
                  {(this.state.personalityScoreData.fiveFactors.neuroticismScore) ? (
                    <p className="heading-text-2 cta-color half-bold-text">{(this.state.personalityScoreData.fiveFactors.neuroticismScore / 15 * 100).toFixed()}%</p>
                  ) : (
                    <p className="heading-text-2 cta-color half-bold-text">N/A</p>
                  )}
                </div>
                <div className="calc-column-offset-100 top-margin-5">
                  <p className="heading-text-3">Neuroticism</p>
                </div>
                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" />


              </div>
            )
          }
        }
      } else if (this.state.resultsCategorySelected === 3) {
        if (this.state.topGravitateValues && this.state.topGravitateValues.length > 0) {

          rows.push(
            <div key="topGravitateValues|0">
              <div className="spacer" /><div className="spacer" />
              <p className="heading-text-3">People I Gravitate Towards:</p>
              <div className="spacer" /><div className="spacer" />
            </div>
          )
          for (let i = 1; i <= this.state.topGravitateValues.length; i++) {
            rows.push(
              <div key={"topGravitateValues|" + i}>
                <div className="spacer" />
                <p className="heading-text-6 cta-color half-bold-text">{i}. {this.state.topGravitateValues[i - 1]}</p>

                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" />
              </div>
            )
          }
        }

        if (this.state.topEmployerValues && this.state.topEmployerValues.length > 0) {

          rows.push(
            <div key="topEmployerValues|0">
              <div className="spacer" /><div className="spacer" />
              <p className="heading-text-3">Employers Should Provide Me:</p>
              <div className="spacer" /><div className="spacer" />
            </div>
          )
          for (let i = 1; i <= this.state.topEmployerValues.length; i++) {
            rows.push(
              <div key={"topEmployerValues|" + i}>
                <div className="spacer" />
                <p className="heading-text-6 cta-color half-bold-text">{i}. {this.state.topEmployerValues[i - 1]}</p>

                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" />
              </div>
            )
          }
        }
      } else if (this.state.resultsCategorySelected === 4) {
        console.log('wpAnswers?', this.state.workPreferenceAnswers)
        if (this.state.workPreferenceAnswers) {

          if (this.state.workPreferenceAnswers && this.state.workPreferenceAnswers.length > 0) {
            for (let i = 1; i <= this.state.wpQuestions.length; i++) {

              if (this.state.workPreferenceAnswers[i - 1]) {
                rows.push(
                  <div key={i.toString() + "wp"} className="row-10">
                    <div className="clear" />
                    <div className="row-5">
                      <label>{this.state.wpQuestions[i - 1].name}</label>
                      {(this.state.workPreferenceAnswers[i - 1].startsWith('[') && JSON.parse(this.state.workPreferenceAnswers[i - 1])) ? (
                        <div>
                          {JSON.parse(this.state.workPreferenceAnswers[i - 1]).map((value, mapIndex) =>
                            <div key={"mapAnswers" + mapIndex.toString()}>
                              {(mapIndex !== JSON.parse(this.state.workPreferenceAnswers[i - 1]).length - 1) ? (
                                <div className="float-left right-padding">
                                  <label className="heading-text-3 cta-color half-bold-text">{value},</label>
                                </div>
                              ) : (
                                <div className="float-left">
                                  <label className="heading-text-3 cta-color half-bold-text">{value}</label>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className="heading-text-3 cta-color half-bold-text">{this.state.workPreferenceAnswers[i - 1]}</p>
                      )}
                      <div className="clear" />
                    </div>
                  </div>
                )
              }
            }
          }
        }
      }

      return rows
    }

    renderFavorites() {
      console.log('renderFavorites called')

      let rows = [];

      let prefixLink = '/organizations/' + this.state.org
      if (window.location.pathname.includes('/advisor')) {
        prefixLink = '/advisor'
      }

      rows.push(
        <div key={0}>
          <div className="row-20">
            <p className="heading-text-2">Favorites</p>
            <p className="top-margin-5 description-text-color">Liked careers, events, projects, and work opportunities.</p>
          </div>
          {/*
          <div className="advisor-card-subheader half-bold-text">
            <div className={(this.state.favoritesCategorySelected === 0) ? "app-title-container-1-of-2-1 selected-item-container-1" : "app-title-container-1-of-2-1 unselected-item-container-1"}>
              <p className={(this.state.favoritesCategorySelected === 0) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ favoritesCategorySelected: 0 })}>All</a></p>
            </div>
            <div className={(this.state.favoritesCategorySelected === 1) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
              <p className={(this.state.favoritesCategorySelected === 1) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ favoritesCategorySelected: 1 })}>Careers</a></p>
            </div>
            <div className={(this.state.favoritesCategorySelected === 2) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
              <p className={(this.state.favoritesCategorySelected === 2) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ favoritesCategorySelected: 2 })}>Career Events</a></p>
            </div>
            <div className={(this.state.favoritesCategorySelected === 3) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
              <p className={(this.state.favoritesCategorySelected === 3) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ favoritesCategorySelected: 3 })}>Project Inspiration</a></p>
            </div>
            <div className={(this.state.favoritesCategorySelected === 4) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
              <p className={(this.state.favoritesCategorySelected === 4) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ favoritesCategorySelected: 4 })}>Work</a></p>
            </div>
          </div>*/}
        </div>
      )

      if (this.state.favorites) {
        if ( this.state.favorites.length !== 0 ) {

          for (let i = 1; i <= this.state.favorites.length; i++) {
            console.log(i);

            const item = this.state.favorites[i - 1]

            let pathname = ''
            let passedState = {}
            let title = ''
            let subtitle = ''
            let viewAsModal = false
            let changedState = { modalIsOpen: true }
            // done: careers, pathway, work, project, event
            // to do: course, project, goal, employer

            if (this.state.types[i - 1] === 'career') {
              pathname = prefixLink + '/careers/' + this.state.favorites[i - 1].name
              passedState = { directLink: true }
              title = item.name
              subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
              if (item.jobFamily) {
                subtitle = subtitle + " | " + item.jobFamily
              }

            } else if (this.state.types[i - 1] === 'pathway') {
              pathname = prefixLink + '/pathways/' + this.state.favorites[i - 1].title
              passedState = { selectedPathway: this.state.favorites[i - 1] }
              title = item.title
              subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
              if (item.jobFunction) {
                subtitle = subtitle + " | " + item.jobFunction
                if (item.industry) {
                  subtitle = subtitle + " | " + item.industry
                }
              }
            } else if (this.state.types[i - 1] === 'goal') {
              pathname = prefixLink + '/goals/' + this.state.favorites[i - 1].title
              title = item.title
              subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
              if (item.goalType) {
                subtitle = subtitle + " | " + item.goalType.description
              }
              viewAsModal = true
              changedState = { modalIsOpen: true, showGoalDetails: true }
            } else if (this.state.types[i - 1] === 'employer') {
              pathname = prefixLink + '/employers/' + this.state.favorites[i - 1].title
              title = item.employerName

              subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
              if (item.employerURL) {
                subtitle = subtitle + " | " + item.employerURL
              }
              viewAsModal = true
              changedState = { modalIsOpen: true, showEmployerDetails: true, selectedEmployer: item }
            } else if (this.state.types[i - 1] === 'project') {
              pathname = prefixLink + '/projects/' + this.state.favorites[i - 1].title
              title = item.name
              subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
              if (item.description) {
                subtitle = subtitle + " | " + item.description
              }
              viewAsModal = true
              changedState = { modalIsOpen: true, showProjectDetails: true }
            } else if (this.state.types[i - 1] === 'student') {
              pathname = prefixLink + '/members/' + this.state.favorites[i - 1].username
              passedState = { directLink: true }
              title = item.firstName + ' ' + item.lastName
              subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
              if (item.headline) {
                subtitle = subtitle + " | " + item.headline
              }
            } else if (this.state.types[i - 1] === 'course') {
              pathname = prefixLink + '/courses/' + this.state.favorites[i - 1].title
              title = item.title
              subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
              if (item.source) {
                subtitle = subtitle + " | " + item.source
              }
              viewAsModal = true
              changedState = { modalIsOpen: true, showCourseDetails: true, selectedCourse: item }
            } else if (this.state.types[i - 1] === 'work') {
              if (item.postType === 'Event') {
                pathname = prefixLink + '/postings/' + this.state.favorites[i - 1]._id
                passedState = { directLink: true }
                title = item.title
                subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
                if (item.eventType) {
                  subtitle = subtitle + " | " + item.eventType
                }
              } else if (item.postType === 'Project' || item.postType === 'Assignment' || item.postType === 'Problem' || item.postType === 'Challenges') {
                pathname = prefixLink + '/postings/' + this.state.favorites[i - 1]._id
                passedState = { directLink: true }
                title = item.name
                subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
                if (item.postType) {
                  subtitle = subtitle + " | " + item.postType
                }
              } else if (item.postType === 'Work' || item.postType === 'Internship' || item.postType === 'Track' || item.postType === 'Individual') {
                let pathLink = prefixLink + '/postings/'
                if (window.location.pathname.includes('/advisor')) {
                  pathLink = prefixLink + '/opportunities/'
                }

                pathname = pathLink + this.state.favorites[i - 1]._id
                passedState = { objectId: this.state.favorites[i - 1]._id, postingId: this.state.favorites[i - 1]._id }
                title = item.title
                subtitle = this.state.types[i - 1].charAt(0).toUpperCase() + this.state.types[i - 1].slice(1);
                if (item.postType) {
                  subtitle = subtitle + " | " + item.postType
                }
              }
            }
            // console.log('show me the title: ', title, item.postType, item.name, item.title, this.state.types[i - 1], item)

            rows.push(
              <div key={item._id} className="row-10">
                {(viewAsModal) ? (
                  <button onClick={() => this.setState(changedState)} className="background-button full-width left-text cta-color">
                    <div className="calc-column-offset-50">
                      <p className="heading-text-5">{title}</p>
                      <p className="description-text-2 standard-color top-padding-5">{subtitle}</p>
                    </div>
                    <div className="fixed-column-50">
                      <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                    </div>
                    <div className="clear" />
                  </button>
                ) : (
                  <Link to={pathname} state={passedState} className="background-link">
                    <div className="calc-column-offset-50">
                      <p className="heading-text-5">{title}</p>
                      <p className="description-text-2 standard-color top-padding-5">{subtitle}</p>
                    </div>
                    <div className="fixed-column-50">
                      <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                    </div>
                    <div className="clear" />
                  </Link>
                )}

              </div>
            )
          }
        }
      }

      return rows
    }

    renderEndorsements() {
      console.log('renderEndorsements called')

      let rows = []

      if (this.state.endorsements) {

        rows.push(
          <div key={0}>
            <div className="row-20">
              <p className="heading-text-2">Endorsements</p>
              <p className="top-margin-5 description-text-color">Endorsements from teachers, mentors, and supervisors on relevant competencies for specific pathways or opportunities.</p>
            </div>
          </div>
        )

        for (let i = 1; i <= this.state.endorsements.length; i++) {

          const detailIndex = i - 1

          let createdAt = this.state.endorsements[i - 1].createdAt
          let year = createdAt.substring(0,4);
          let subCA = createdAt.substring(5,10);
          let replacedCA = subCA.replace("-","/")
          let formattedCA = replacedCA + "/" + year

          rows.push(
            <div key={i}>
              <a className="background-button full-width" onClick={() => this.setState({ modalIsOpen: true, showEndorsementDetails: true, detailIndex })}>
                <div className="table-row-header-2">
                  <div className="table-row-subheader">
                      <span className="fixed-column-70">
                        <img src={profileIconDark} alt="GC Icon" className="row-icon"/>
                      </span>
                      <span className="calc-column-offset-170">
                        <div className="table-row-content-title-2 curtail-text">
                            <p>{this.state.endorsements[i - 1].senderFirstName} {this.state.endorsements[i - 1].senderLastName}</p>
                        </div>
                        <div className="clear" />
                        <p className="description-text-2 curtail-text">{this.state.endorsements[i - 1].relationship}  |  {this.state.endorsements[i - 1].pathway}</p>
                      </span>
                      <div className="fixed-column-100">
                          <p className="description-text-3">{formattedCA}</p>
                      </div>
                  </div>
                  <div className="clear" />
                  <div className="spacer"/>
                  <hr className=" horizontal-margin-3" />
                  <div className="spacer"/>

                  <div>
                    <div className="spacer"/><div className="spacer"/>
                    {(this.state.endorsements[i - 1].examples[0]) && (
                      <div className="table-row-subheader-2">
                        <div className="table-row-content-title-2">
                          <p>{this.state.endorsements[i - 1].examples[0].example}</p>
                        </div>
                      </div>
                    )}

                    <div className="clear"/>
                    <div className="row-10 horizontal-padding">
                      {this.renderEndorsedSkills(this.state.endorsements[i - 1].skillTraits)}
                    </div>
                  </div>

                  <div className="clear"/>
                  <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                </div>
              </a>
            </div>
          )
        }
      }

      return rows

    }

    renderEndorsedSkills(skillTraits) {
      console.log('renderEndorsedSkills called', skillTraits)

      let rows = []

      if (skillTraits) {

        for (let i = 1; i <= skillTraits.length; i++) {

          rows.push(
            <div key={i.toString() + 'skills'} className="unselected-tag-container-2 float-left description-text-2 right-margin top-margin">
              <p className="white-text">{skillTraits[i - 1].name} | {skillTraits[i - 1].rating}</p>
            </div>
          )
        }
      }

      return rows
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showEndorsementDetails: false, showGoalDetails: false,
        enlargeImage: false, selectedImage: null, showHelpProfile: false, showEditProfile: false,
        showProjectDetails: false,
        showCourseDetails: false, selectedCourse: null,
        showEmployerDetails: false, selectedEmployer: null

      });
    }

    renderSpecificSkillTraits(skillTraits) {
      console.log('renderSpecificSkillTraits called')

      let rows = []

      if (skillTraits) {

        let hardSkills = []
        let softSkills = []
        let traits = []

        for (let i = 1; i <= skillTraits.length; i++) {
          console.log('looping skillTraits: ', skillTraits[i - 1])
          let skillTrait = skillTraits[i - 1]

          if (!skillTrait.rating) {
            skillTrait['rating'] = 0
          }
          if (skillTraits[i - 1].skillType === 'Hard Skill') {
            hardSkills.push(skillTrait)
          } else if (skillTraits[i - 1].skillType === 'Soft Skill') {
            softSkills.push(skillTrait)
          } else {
            traits.push(skillTrait)
          }
        }

        rows.push(
          <div key="skills">
            <p>Hard Skills</p>
            {hardSkills.map(value =>
              <div>
                {(value.rating !== "I'm Not Sure") && (
                  <div>
                    <div className="relative-column-30">
                      <p className="description-text-2 bold-text">{value.name}</p>
                    </div>
                    <div className="relative-column-65">
                      <div className="half-spacer" />
                      <div className="progress-bar primary-border">
                        <div className={"filler primary-background"} style={{ width: (value.rating * 20).toString() + '%' }} />
                      </div>
                    </div>
                    <div className="relative-column-5 center-text">
                      <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                    </div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}
              </div>
            )}

            <div className="spacer"/><div className="half-spacer"/>

            {(softSkills && softSkills.length > 0) && (
              <div>
                <p className="row-5">Soft Skills</p>
                {softSkills.map(value =>
                  <div>
                    {(value.rating !== "I'm Not Sure") && (
                      <div>
                        <div className="relative-column-30">
                          <p className="description-text-2 bold-text">{value.name}</p>
                        </div>
                        <div className="relative-column-65">
                          <div className="half-spacer" />
                          <div className="progress-bar secondary-border">
                            <div className={"filler secondary-background"} style={{ width: (value.rating * 20).toString() + '%' }} />
                          </div>
                        </div>
                        <div className="relative-column-5 center-text">
                          <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                        </div>
                        <div className="clear" />
                        <div className="spacer" />
                      </div>
                    )}
                  </div>
                )}

                <div className="spacer"/><div className="half-spacer"/>
              </div>
            )}

            {(traits && traits.length > 0) && (
              <div>
                <p className="row-5">Traits</p>
                {traits.map(value =>
                  <div>
                    {(value.rating !== "I'm Not Sure") && (
                      <div>
                        <div className="relative-column-30">
                          <p className="description-text-2 bold-text">{value.name}</p>
                        </div>
                        <div className="relative-column-65">
                          <div className="half-spacer" />
                          <div className="progress-bar tertiary-border">
                            <div className={"filler tertiary-background"} style={{ width: (value.rating * 20).toString() + '%' }} />
                          </div>
                        </div>
                        <div className="relative-column-5 center-text">
                          <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                        </div>
                        <div className="clear" />
                        <div className="spacer" />
                      </div>
                    )}

                  </div>
                )}
              </div>
            )}

          </div>
        )
      }

      return rows
    }

    renderProjects() {
      console.log('renderProjects called')

      let rows = [];

      if ( this.state.projects.length !== 0 ) {

        for (let i = 1; i <= this.state.projects.length; i++) {
          // console.log(i,this.state.logs[i - 1].logType);

          const index = i - 1
          let subtitle = ''
          // if (this.state.projects[i - 1].hours) {
          //   subtitle = this.state.projects[i - 1].hours + ' Hours'
          // }

          if (this.state.projects[i - 1].category) {
            if (subtitle === '') {
              subtitle = subtitle + this.state.projects[i - 1].category
            } else {
              subtitle = subtitle + ' | ' + this.state.projects[i - 1].category
            }
          }

          if (this.state.projects[i - 1].jobFunction) {
            if (subtitle === '') {
              subtitle = subtitle + this.state.projects[i - 1].jobFunction
            } else {
              subtitle = subtitle + ' | ' + this.state.projects[i - 1].jobFunction
            }
          }

          if (this.state.projects[i - 1].skillTags) {
            if (subtitle === '') {
              subtitle = subtitle + this.state.projects[i - 1].skillTags.toString().replace(/,/g,", ")
            } else {
              subtitle = subtitle + ' | ' + this.state.projects[i - 1].skillTags.toString().replace(/,/g,", ")
            }
          }

          rows.push(
            <div>
              <div key={this.state.projects[i - 1]._id} className="row-10">
                <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showProjectDetails: true, selectedIndex: index })}>
                  <div className="calc-column-offset-200">
                    <p className="heading-text-5 cta-color">{this.state.projects[i - 1].name}</p>
                    <p className="description-text-2 standard-color curtail-text top-padding-5">{subtitle}</p>
                    {(this.state.projects[i - 1].collaborators && this.state.projects[i - 1].collaborators.length > 0) && (
                      <div className="top-padding-5">
                        <p className="description-text-2">{this.state.projects[i - 1].collaborators.length} Collaborators</p>
                      </div>
                    )}
                  </div>
                  <div className="fixed-column-170">
                    <p className="heading-text-5 cta-color right-text">{this.state.projects[i - 1].hours} Hours</p>
                  </div>
                  <div className="fixed-column-30">
                    <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right" />
                  </div>
                  <div className="clear" />
                </button>
                <div className="clear" />
              </div>

              <div className="row-10">
                <hr />
              </div>

            </div>
          )
        }
      } else {
          //show empty state screen
      }

      return rows;
    }

    renderProfile() {
      console.log('renderProfile called')

      return (
        <div key="renderProfile">
          <div className="spacer" /><div className="spacer" />

          <div className="full-width center-text bottom-margin">
            <div className="fixed-column-80">
              <button className="background-button full-width" onClick={() => this.setState({ modalIsOpen: true, showEditProfile: true })}>
                <div className="cta-border flex-container flex-center padding-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Edit " + this.state.member.firstName + "'s Profile Information"}>
                  <div>
                    <img src={editIconBlue} alt="GC" className="image-auto-18 center-horizontally" />
                    <p className="top-margin-7 description-text-3 full-width center-text cta-color">Edit</p>
                  </div>
                </div>
              </button>
            </div>
            <div className="calc-column-offset-160">
              <button className="background-button center-text" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: this.state.member.pictureURL })}>
                <img src={this.state.member.pictureURL ? this.state.member.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-6 center-horizontally"/>
              </button>
            </div>
            <div className="fixed-column-80">
              <button className="background-button full-width" onClick={() => this.setState({ modalIsOpen: true, showHelpProfile: true })}>
                <div className="cta-border flex-container flex-center padding-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Help " + this.state.member.firstName}>
                  <div>
                    <img src={resourcesIconBlue} alt="GC" className="image-20-fit center-horizontally" />
                    <p className="top-margin-5 description-text-3 full-width center-text cta-color">Help</p>
                  </div>
                </div>
              </button>
            </div>
            <div className="clear" />
          </div>

          <p className="heading-text-2 center-text">{this.state.member.firstName} {this.state.member.lastName}</p>

          {(this.state.member.headline) && (
            <div className="top-padding bottom-padding-5">
              <p className="center-text">{this.state.member.headline}</p>
            </div>
          )}

          <div className="row-5">
            <p className="description-text-2 description-text-color center-text">{this.state.member.email}{(this.state.member.address) && " | " + this.state.member.address}{(this.state.member.city) && " " + this.state.member.city}{(this.state.member.zipcode) && ", " + this.state.member.zipcode}</p>
          </div>

          {(this.state.partners && this.state.partners.length > 0 && this.state.selectedPartner) && (
            <div className="row-10 center-text">
              <div>
                <Link to={'/organizations/' + this.state.org + '/members/' + this.state.selectedPartner.username} state={{ selectedMentor: this.state.selectedMentor }}>
                  <div className="flex-container row-direction flex-center">
                    <img src={(this.state.selectedPartner.pictureURL) ? this.state.selectedPartner.pictureURL : mentoringIconBlue} alt="GC" className="image-auto-20" />
                    <p className="description-text-2 cta-color left-padding-5">{this.state.selectedPartner.firstName} {this.state.selectedPartner.lastName}</p>
                  </div>
                </Link>
              </div>
            </div>
          )}

          <div className="profile-links row-10">
            <ul>
              { this.state.member.videoResumeURL && <li><a href={this.state.member.videoResumeURL} target="_blank"><img src={videoIconDark} alt="GC" className="image-auto-30" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Video Resume"/></a></li> }
              { this.state.member.resumeURL && <li><a href={this.state.member.resumeURL} target="_blank"><img src={resumeIconDark} alt="Compass resume icon" className="image-auto-30" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Resume"/></a></li> }
              { this.state.member.customWebsiteURL && <li><a href={this.state.customWebsiteURL} target="_blank"><img src={websiteIconDark} alt="Compass website icon" className="image-auto-30" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Portfolio Website"/></a></li> }
              { this.state.member.linkedInURL && <li><a href={this.state.member.linkedInURL} target="_blank"><img src={linkedinIconDark} alt="Compass linkedin icon" className="image-auto-30" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn"/></a></li> }
              { this.state.member.letterOfRecommendationURL && <li><a href={this.state.member.letterOfRecommendationURL} target="_blank"><img src={letterOfRecIcon} alt="GC" className="image-auto-30" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Letter of Recommendation"/></a></li> }
              { this.state.member.transcriptURL && <li><a href={this.state.member.transcriptURL} target="_blank"><img src={transcriptIcon} alt="GC" className="image-auto-30" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Transcript"/></a></li> }
              { this.state.member.identificationURL && <li><a href={this.state.member.identificationURL} target="_blank"><img src={idIcon} alt="GC" className="image-auto-30" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Identification"/></a></li> }
            </ul>
          </div>

          <div className="spacer" />

          <div className="row-30">
            <p className="heading-text-3">Personal & Demographic Information</p>
            <div className="spacer" />
            <hr />

            <div className="spacer" />

            {(this.state.personalInformation) && (
              <div>
                {this.state.personalInformation.map((item,index) =>
                  <div key={item} className="top-margin-20 bottom-margin-20">
                    <div className="relative-column-33">
                      <label className="description-text-2 description-text-color row-10">{item.name}</label>
                      <p>{item.value}</p>
                    </div>
                    {(index % 3 === 2) && (
                      <div className="clear" />
                    )}
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            {(this.state.member.adversityList && this.state.member.adversityList.length > 3) && (
              <div key={"adversityList"} className="top-margin-20 bottom-margin-20">
                <div className="full-width">
                  <label className="description-text-2 description-text-color row-10">Background Information</label>
                  <p>{this.state.member.adversityList.toString().replace(/,/g,", ")}</p>
                </div>
              </div>
            )}

          </div>

          <div className="row-30">
            <p className="heading-text-3">Education</p>
            <div className="spacer" />
            <hr />

            <div className="spacer" />

            {(this.state.member.education && this.state.member.education.length > 0) ? (
              <div>
                {this.state.member.education.map((item,index) =>
                  <div key={item}>
                    <div className="full-width top-margin-30">
                      <div className="bottom-margin-5">
                        <div className="relative-column-70">
                          <p className="heading-text-5">{item.name}</p>
                        </div>
                        <div className="relative-column-30">
                          <p className="heading-text-5 full-width right-text">{item.location}</p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div className="bottom-margin-5">
                        <div className="relative-column-70">
                          <p className="description-text-2">{item.degree}{(item.major) && " - " + item.major}</p>
                        </div>
                        <div className="relative-column-30">
                          <p className="description-text-2 full-width right-text">{item.startDate} - {(item.isContinual) ? "Present" : item.endDate}</p>
                        </div>
                        <div className="clear" />
                      </div>

                      {(item.summary) && (
                        <div className="bottom-margin-5">
                          <p className="description-text-2 description-text-color full-width">{item.summary}</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="error-color top-margin">No education entries have been added.</p>
              </div>
            )}

          </div>

          <div className="row-30">
            <p className="heading-text-3">Certificates, Badges, & Certifications</p>
            <div className="spacer" />
            <hr />

            <div className="spacer" />

            {(this.state.member.certificates && this.state.member.certificates.length > 0) ? (
              <div>
                {this.state.member.certificates.map((item,index) =>
                  <div key={item}>
                    <div className="full-width top-margin-30">
                      <div className="bottom-margin-5">
                        <div className="relative-column-70">
                          <p className="heading-text-5">{item.name}</p>
                        </div>
                        <div className="relative-column-30">
                          <p className="heading-text-5 full-width right-text">{item.estimatedHours} Hours</p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div className="bottom-margin-5">
                        <div className="relative-column-70">
                          <p className="description-text-2">{item.issuer}</p>
                          {(item.pathway || item.workFunction || item.industry) && (
                            <p className="description-text-2">{(item.pathway) && item.pathway}{(item.workFunction) && " | " + item.workFunction}{(item.industry) && " | " + item.industry}</p>
                          )}
                        </div>
                        <div className="relative-column-30">
                          <p className="description-text-2 full-width right-text">{item.dateIssued}</p>
                        </div>
                        <div className="clear" />
                      </div>

                      {(item.description) && (
                        <div className="bottom-margin-5">
                          <p className="description-text-2 description-text-color full-width">{item.description}</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="error-color top-margin">No certification entries have been added.</p>
              </div>
            )}
          </div>

          <div className="row-30">
            <p className="heading-text-3">Projects</p>
            <div className="spacer" />
            <hr />

            <div className="spacer" />

            {this.renderProjects()}
            <div className="clear" />

          </div>

          <div className="row-30">
            <p className="heading-text-3">Experience</p>
            <div className="spacer" />

            <hr />

            <div className="spacer" /><div className="spacer" />

            <SubRenderExperience
              history={this.props.navigate} experience={this.state.experience}
              org={this.state.org}
            />

            <div className="clear" />
          </div>

          <div className="row-30">
            <p className="heading-text-3">Awards</p>
            <div className="spacer" />
            <hr />

            <div className="spacer" />

            {(this.state.awards) && (
              <div>
                {this.state.awards.map((item,index) =>
                  <div key={item} className="top-margin-20 bottom-margin-20">
                    <div className="calc-column-offset-200">
                      <p className="heading-text-5">{item.name}</p>
                      <p className="description-text-2 standard-color curtail-text top-padding-5">{item.description}</p>
                      {(item.bullets) && (
                        <div>
                          {item.bullets.map((item2,index2) =>
                            <div>
                              <p className="description-text-2 standard-color curtail-text top-padding-5">{item2}</p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="fixed-column-200">
                      <p className="heading-text-5 right-text">{item.awardDate}</p>
                    </div>
                    <div className="clear" />
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            <div className="clear" />
          </div>

          <div className="row-30">
            <p className="heading-text-3">Extracurriculars</p>
            <div className="spacer" />
            <hr />

            <div className="spacer" />

            {(this.state.extracurriculars) && (
              <div>
                {this.state.extracurriculars.map((item,index) =>
                  <div key={item} className="top-margin-20 bottom-margin-20">
                    <div className="calc-column-offset-200">
                      <p className="heading-text-5">{item.activityName}</p>
                      <p className="description-text-2 standard-color curtail-text top-padding-5">{item.roleName} | {item.hoursPerWeek}</p>
                      <p className="description-text-2 standard-color curtail-text top-padding-5">{item.description}</p>
                      {(item.bullets) && (
                        <div>
                          {item.bullets.map((item2,index2) =>
                            <div>
                              <p className="description-text-2 standard-color curtail-text top-padding-5">{item2}</p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="fixed-column-200">
                      <p className="heading-text-5 right-text">{item.startDate} - {item.endDate}</p>
                    </div>
                    <div className="clear" />
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            <div className="clear" />
          </div>

          {(this.state.member.customFields && this.state.member.customFields.length > 0) && (
            <div className="row-30">
              <p className="heading-text-3">Custom Information</p>
              <div className="spacer" />
              <hr />

              <div className="spacer" />

              {this.state.member.customFields.map((item,index) =>
                <div key={item} className="top-margin-20 bottom-margin-20">
                  <label className="description-text-2 description-text-color row-10">{(item.displayName) ? item.displayName : item.question}</label>
                  <p>{item.answer}</p>
                </div>
              )}

              <div className="clear" />
            </div>
          )}

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    subNavClicked(type, pageName) {
      console.log('subNavClicked called', type, pageName)

      if (type === 'log') {
        this.setState({ logCategorySelected: pageName })
      }
    }

    renderRoleName(roleName) {
      console.log('renderRoleName called', roleName, this.state.mentorAliasSingular)

      if ((roleName === 'Student' || roleName === 'Career-Seeker') && this.state.studentAliasSingular) {
        return this.state.studentAliasSingular
      } else if (roleName === 'Mentor' && this.state.mentorAliasSingular) {
        return this.state.mentorAliasSingular
      } else {
        return roleName
      }
    }

    render() {

        let pathPrefix = '/organizations/' + this.state.org
        if (window.location.pathname.includes('/advisor')) {
          pathPrefix = '/advisor'
        }

        return (
            <div>
              {(this.state.member) && (
                <div>
                  <nav>
                    <div className='thin-side-nav'>
                        <div className="center-text">
                          <div>
                            <div className="spacer" />

                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: this.state.member.pictureURL })}>
                              <img src={this.state.member.pictureURL ? this.state.member.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-4 center-horizontally"/>
                            </button>

                            <p className="heading-text-3 row-10">{this.state.member.firstName} {this.state.member.lastName}</p>

                            <p className="bottom-margin description-text-1">[{this.renderRoleName(this.state.member.roleName)}]</p>

                            {this.state.member.headline && (
                              <div>
                                <p className="bottom-margin description-text-1">{this.state.member.headline}</p>
                              </div>
                            )}
                          </div>
                        </div>

                        {(this.state.member.roleName === 'Mentor') ? (
                          <div className={"full-width top-margin center-text row-10 flex-container row-direction flex-space-between"}>
                            <button className="btn btn-squarish-flex" onClick={() => this.props.navigate(pathPrefix + '/members/' + this.state.member.email + '/evaluations')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Case Manage " + this.state.member.firstName}>{(this.state.org === 'unite-la') ? "Case Manage" : "Evaluate"}</button>
                            <button className="btn btn-squarish-white-flex" onClick={() => this.setState({ modalIsOpen: true, showHelpProfile: true })} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Help " + this.state.member.firstName}>Help</button>
                            <button className="btn btn-squarish senary-background cta-color" onClick={() => this.props.navigate(pathPrefix + '/members/match/' + this.state.member._id, { state: { selectedMentor: this.state.member }})} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Match " + this.state.member.firstName}>Match</button>
                          </div>
                        ) : (
                          <div className={"full-width top-margin center-text row-10 flex-container row-direction"}>
                            <div className="flex-50 right-padding-5">
                              <button className="btn btn-squarish-flex full-width" onClick={() => this.props.navigate(pathPrefix + '/members/' + this.state.member.email + '/evaluations')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Case Manage " + this.state.member.firstName}>{(this.state.org === 'unite-la') ? "Case Manage" : "Evaluate"}</button>
                            </div>
                            <div className="flex-50 left-padding-5">
                              <button className="btn btn-squarish-white-flex full-width" onClick={() => this.setState({ modalIsOpen: true, showHelpProfile: true })} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Help " + this.state.member.firstName}>Help</button>
                            </div>
                          </div>
                        )}

                        <hr className="cell-separator-advisor"/>

                        <div className="bottom-margin-20">
                          <p className="description-text-3 description-text-color top-margin-30">CORE INFO</p>
                          {this.state.sideNavData.map((item,index) =>
                            <div key={item}>
                              {((this.state.showTwoSideNavSections && item.section === 1) || (!this.state.showTwoSideNavSections)) && (
                                <div className={(index === 0) ? "full-width top-margin-20" : "full-width top-margin-30"}>
                                  <a onClick={() => this.setState({ categorySelected: item })} className="background-link">
                                    <div className="fixed-column-30">
                                      <img src={(this.state.categorySelected.title === item.title) ? item.selectedIcon : item.unselectedIcon} alt="GC" className="image-auto-26 float-left" />
                                    </div>
                                    <div className="calc-column-offset-30 left-padding-15">
                                      <p className={(this.state.categorySelected.title === item.title) ? "cta-color half-bold-text heading-text-6" : "standard-color half-bold-text heading-text-6"}>{item.title}</p>
                                    </div>
                                    <div className="clear" />
                                  </a>
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        {(this.state.showTwoSideNavSections) && (
                          <div className="row-10">
                            <hr />
                            <div>
                              <p className="description-text-3 description-text-color top-margin-30">OTHER PROFILE INFO</p>

                              {this.state.sideNavData.map((item,index) =>
                                <div key={item}>
                                  {(item.section === 2) && (
                                    <div className={(index === 0) ? "full-width top-margin-20" : "full-width top-margin-30"}>
                                      <a onClick={() => this.setState({ categorySelected: item })} className="background-link">
                                        <div className="fixed-column-30">
                                          <img src={(this.state.categorySelected.title === item.title) ? item.selectedIcon : item.unselectedIcon} alt="GC" className="image-auto-26 float-left" />
                                        </div>
                                        <div className="calc-column-offset-30 left-padding-15">
                                          <p className={(this.state.categorySelected.title === item.title) ? "cta-color half-bold-text heading-text-5" : "standard-color half-bold-text heading-text-5"}>{item.title}</p>
                                        </div>
                                        <div className="clear" />
                                      </a>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </nav>

                  {(this.state.categorySelected.title === "Basic Profile Info") && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                      <div className="spacer"/><div className="half-spacer"/>

                      {this.renderProfile()}
                    </div>
                  )}

                  {(this.state.categorySelected.title === "Goals & Logs") && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                      <div className="spacer"/><div className="half-spacer"/>
                      {this.renderLogs()}
                    </div>
                  )}

                  {(this.state.categorySelected === 2 || this.state.categorySelected.title === "Career Assessments") && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                    <div className="spacer"/><div className="half-spacer"/>
                      {this.renderAssessments()}
                    </div>
                  )}

                  {/*
                  { (this.state.categorySelected === 1) && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                    <div className="spacer"/><div className="half-spacer"/>
                      {this.renderActionItems()}
                    </div>
                  )}*/}

                  {(this.state.categorySelected === 4 || this.state.categorySelected.title === "Endorsements") && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                      <div className="spacer"/><div className="half-spacer"/>
                      {this.renderEndorsements()}
                    </div>
                  )}

                  {(this.state.categorySelected === 3 || this.state.categorySelected.title === "Favorites") && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                      <div className="spacer"/><div className="half-spacer"/>
                      {this.renderFavorites()}
                    </div>
                  )}

                  {(this.state.categorySelected === 5 || this.state.categorySelected.title === "Documents") && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                      <div className="spacer"/><div className="spacer"/>
                      {this.renderDocuments()}
                    </div>
                  )}

                  {(this.state.categorySelected === 6 || this.state.categorySelected.title === "Top Matches") && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                      <div className="padding-20">
                        <SubMatches history={this.props.navigate} member={this.state.member} inModal={this.state.modalIsOpen} />
                      </div>
                    </div>
                  )}

                  {(this.state.categorySelected.title === "Enrollments") && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                      <div className="spacer"/><div className="half-spacer"/>
                      {this.renderEnrollments()}
                    </div>
                  )}

                  {(this.state.categorySelected.title === "Submissions") && (
                    <div className="main-side-card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                      <div className="spacer"/><div className="half-spacer"/>
                      {this.renderSubmissions()}
                    </div>
                  )}

                  {(this.state.showGoalDetails || this.state.showHelpOutWidget) ? (
                    <div>
                      <SubGoalDetails history={this.props.navigate} selectedGoal={this.state.selectedGoal} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} showGoalDetails={this.state.showGoalDetails} showHelpOutWidget={this.state.showHelpOutWidget} profileData={this.state.member}/>
                    </div>
                  ) : (
                   <div>
                     {(this.state.showProjectDetails) ? (
                       <div>
                         <ProjectDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedProject={this.state.projects[this.state.selectedIndex]} orgCode={this.state.org} />
                       </div>
                     ) : (
                       <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        className="modal"
                        overlayClassName="modal-overlay"
                        contentLabel="Example Modal"
                        >

                        <div className="padding-20">
                          {(this.state.showEndorsementDetails && this.state.endorsements[this.state.detailIndex]) && (
                            <div>

                              <p className="heading-text-4 row-10">Endorsement Details</p>

                              <div key="specificEndorsement" className="row-10">
                                <hr />
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                <div className="fixed-column-90">
                                  <div className="dark-background width-90 all-padding-10">
                                    <p className="heading-text-2 bold-text white-text center-text">{(this.state.endorsements[this.state.detailIndex].overallRecommendation) ? Number(this.state.endorsements[this.state.detailIndex].overallRecommendation) * 20 : "N/A"}%</p>
                                    <p className="description-text-5 white-text center-text">OVERALL</p>
                                  </div>
                                </div>
                                <div className="endorsement-name">
                                  <p className="heading-text-4">{this.state.endorsements[this.state.detailIndex].senderFirstName} {this.state.endorsements[this.state.detailIndex].senderLastName}</p>
                                  <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                  <p className="description-text-2">{this.state.endorsements[this.state.detailIndex].relationship}</p>
                                </div>
                                <div className="endorsement-pathway">
                                  <p>{this.state.endorsements[this.state.detailIndex].pathway} Pathway</p>
                                  <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                  <p className="description-text-2">{convertDateToString(new Date(this.state.endorsements[this.state.detailIndex].createdAt),"datetime-2")}</p>
                                </div>

                                <div className="clear" />
                                <div className="spacer" /><div className="spacer" />

                                <div>
                                  {this.renderSpecificSkillTraits(this.state.endorsements[this.state.detailIndex].skillTraits)}
                                </div>

                                <div className="clear" />
                                <div className="spacer" /><div className="spacer" />

                                <div>
                                  <p className="heading-text-6 bold-text">Examples of Above Skills & Traits</p>
                                  <div className="spacer" /><div className="spacer" />
                                  {this.state.endorsements[this.state.detailIndex].examples.map(value =>
                                    <div>
                                      <p className="description-text-1">{value.skillTrait} Example</p>
                                      <div className="half-spacer" />
                                      <p className="description-text-3 description-text-color bold-text">{value.example}</p>
                                      <div className="spacer" /><div className="spacer" />
                                    </div>
                                  )}

                                </div>

                                <div className="clear" />
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />

                              </div>
                            </div>
                          )}

                          {(this.state.enlargeImage) && (
                            <div>
                              <img src={(this.state.selectedImage) ? this.state.selectedImage : profileIconDark} alt="GC" className="image-full-auto" />
                            </div>
                          )}

                          {(this.state.showHelpProfile) && (
                            <div>
                              <p className="heading-text-2 center-text">Help {this.state.member.firstName}</p>
                              <div className="spacer" /><div className="spacer" />

                              <div className="standard-border top-margin-20">
                                <div className="row-20 dark-background white-text horizontal-padding">
                                  <p className="heading-text-5">Guide</p>
                                </div>

                                <div className="spacer" />

                                <div className="horizontal-padding">
                                  {this.state.helpOptions.map((item, index) =>
                                    <div className="full-width top-margin bottom-margin">
                                      <button className="background-button full-width left-text row-10" onClick={(item.action === 'Link') ? () => this.props.navigate(item.url, { state: item.passedState }) : () => this.setState(item.passedState)}>
                                        <div>
                                          <div className="fixed-column-30">
                                            <p className="description-text-1">{index + 1}.</p>
                                          </div>
                                          <div className="calc-column-offset-50">
                                            <p className="description-text-1">{item.name}</p>
                                          </div>
                                          <div className="fixed-column-20">
                                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12" />
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </button>

                                      <div className="spacer" />
                                      <hr />
                                    </div>
                                  )}
                                </div>

                                <div className="spacer" /><div className="spacer" />

                              </div>

                              <div className="standard-border top-margin-20">
                                <div className="row-20 dark-background white-text horizontal-padding">
                                  <p className="heading-text-5">Communicate</p>
                                </div>

                                <div className="spacer" />

                                <div className="horizontal-padding">
                                  {this.state.communicationOptions.map((item, index) =>
                                    <div className="full-width top-margin bottom-margin">
                                      <button className="background-button full-width left-text row-10" onClick={(item.action === 'Link') ? () => this.props.navigate(item.url, { state: item.passedState }) : () => this.setState(item.passedState)}>
                                        <div>
                                          <div className="fixed-column-30">
                                            <p className="description-text-1">{index + 1}.</p>
                                          </div>
                                          <div className="calc-column-offset-50">
                                            <p className="description-text-1">{item.name}</p>
                                          </div>
                                          <div className="fixed-column-20">
                                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12" />
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </button>

                                      <div className="spacer" />
                                      <hr />
                                    </div>
                                  )}
                                </div>

                                <div className="spacer" /><div className="spacer" />

                              </div>
                              {/*
                              <div className="standard-border top-margin-20">
                                <div className="row-20 dark-background white-text horizontal-padding">
                                  <p className="heading-text-5">Track</p>
                                </div>

                                <div className="spacer" />

                                <div className="horizontal-padding">
                                  {this.state.trackOptions.map((item, index) =>
                                    <div className="full-width top-margin bottom-margin">
                                      <button className="background-button full-width left-text row-10" onClick={(item.action === 'Link') ? () => this.props.navigate(item.url, { state: item.passedState }) : () => this.setState(item.passedState)}>
                                        <div>
                                          <div className="fixed-column-30">
                                            <p className="description-text-1">{index + 1}.</p>
                                          </div>
                                          <div className="calc-column-offset-50">
                                            <p className="description-text-1">{item.name}</p>
                                          </div>
                                          <div className="fixed-column-20">
                                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12" />
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </button>

                                      <div className="spacer" />
                                      <hr />
                                    </div>
                                  )}
                                </div>

                                <div className="spacer" /><div className="spacer" />

                              </div>*/}
                              {/*
                              <p>Evaluate: Add interview scores, edit notes, mark workforce ready (has work permit, is workforce ready, is work ready), compare to pathways</p>
                              <p>Help: feedback on projects, suggestions for goals/courses, endorse competencies, refer for roles</p>
                              <p>Communicate: send message, share profile with outsiders, give a shoutout on the newsfeed</p>
                              <p>Track: log a meeting/session, create a goal on their behalf, assign a learning module,</p>*/}
                            </div>
                          )}

                          {(this.state.showEditProfile) && (
                            <div>
                              <div>
                                <div className="calc-column-offset-20 right-padding">
                                  <p className="heading-text-2 center-text">Edit {this.state.member.firstName} Profile Information</p>
                                </div>
                                <div className="fixed-column-20">
                                  <button className="background-button top-margin-5" onClick={() => this.closeModal()}>
                                    <img src={closeIcon} alt="GC" className="image-auto-20" />
                                  </button>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="spacer" /><div className="spacer" />
                              <SubEditProfileDetails activeOrg={this.state.org} passedProfile={this.state.member} passedType="Basic" />
                            </div>
                          )}

                          {(this.state.showCourseDetails) && (
                            <div>
                              <SubCourseDetails history={this.props.navigate} courseId={this.state.selectedCourse.udemyId} inModal={this.state.modalIsOpen} />
                            </div>
                          )}

                          {(this.state.showEmployerDetails) && (
                            <div>
                              <SubEmployerDetails history={this.props.navigate} accountCode={this.state.selectedEmployer.accountCode} inModal={this.state.modalIsOpen} />
                            </div>
                          )}
                        </div>
                        {(!this.state.showEditProfile) && (
                          <div className="row-20 full-width center-text">
                            <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                          </div>
                        )}

                       </Modal>
                     )}
                  </div>
                 )}
                </div>
              )}

            </div>
        )
    }
}

export default withRouter(StudentDetails);
