export const configureDefaultReports = (showModule)=>{
  console.log('called configureDefaultReports')

  let postingFields = []
  let applicationFields = []
  let programApplicationFields = []
  let projectSubmissionFields = []
  let rsvpFields = []
  let attendeeFields = []
  let assessmentFields = []
  let assessmentResultsFields = []
  let benchmarkFields = []
  let memberFields = []
  let participantFields = []
  let employerFields = []
  let goalFields = []
  let mentorMatchFields = []
  let scoreFields = []
  let challengeFields = []
  let placementFields = []
  let messageFields = []
  let timesheetFields = []
  let courseFields = []
  let enrollmentFields = []
  let endorsementFields = []
  let sessionFields = []
  let referralFields = []
  let caseNoteFields = []
  let messageBlastFields = []
  let pathwayFields = []
  let lessonFields = []
  let surveySubmissionFields = []
  let keyMetricFields = []

  if (showModule) {
    postingFields = [
      { name: "Post Id", type: "String", shortname: "_id"},
      { name: "Post Type", type: "String", shortname: "postType"},
      { name: "Title", type: "String", shortname: "title"},
      { name: "Featured", type: "Boolean", shortname: "featured"},
      { name: "Function", type: "Splitter", shortname: "field|0", altCondition: "postType|ne|Work", altType: "Simple Join", altShortname: "functions" },
      { name: "Location", type: "String", shortname: "location"},
      { name: "Application Components", type: "Simple Join", shortname: "applicationComponents"},
      { name: "Application Count", type: "Count", shortname: "applications"},
      { name: "Has Custom Assessment", type: "Boolean", shortname: "hasCustomAssessment"},
      { name: "Status", type: "Boolean", shortname: "isActive"},
      { name: "Description", type: "String", shortname: "description"},
      { name: "Hours per Week", type: "String", shortname: "hoursPerWeek"},
      { name: "Employer Name", type: "String", shortname: "employerName"},
      { name: "Industry", type: "Splitter", shortname: "field|1", altCondition: "postType|ne|Work", altType: "String", altShortname: "industry"},
      { name: "Employer Type", type: "String", shortname: "employerType"},
      { name: "Employer URL", type: "String", shortname: "employerURL"},
      { name: "Employer Employees", type: "String", shortname: "employerEmployees"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
      { name: "Employer Contact First Name", type: "String", shortname: "employerContactFirstName"},
      { name: "Employer Contact Last Name", type: "String", shortname: "employerContactLastName"},
      { name: "Employer Contact Email", type: "String", shortname: "employerContactEmail"},
      { name: "Employer Contact Phone", type: "String", shortname: "employerContactPhone"},
      { name: "Contributor Email", type: "String", shortname: "posterEmail"},
      { name: "Contributor First Name", type: "String", shortname: "contributorFirstName"},
      { name: "Contributor Last Name", type: "String", shortname: "contributorLastName"},
      { name: "Contributor Title", type: "String", shortname: "contributorTitle"},
      { name: "Video Link", type: "String", shortname: "videoLink"},
      { name: "Submission Count", type: "String", shortname: "submissionCount"},
      { name: "Resources", type: "Simple Join", shortname: "resources"},
      { name: "Difficulty Level", type: "String", shortname: "difficultyLevel"},
      { name: "Summary", type: "String", shortname: "summary"},
      { name: "Employer Info", type: "String", shortname: "employerInfo"},
      { name: "Guidelines", type: "String", shortname: "guidelines"},
      { name: "Exhibit Count", type: "Count", shortname: "exhibits"},
      { name: "Lessons", type: "String", shortname: "lessons"},
      { name: "Disable Downvoting", type: "Boolean", shortname: "disableDownvoting"},
      { name: "Submission Deadline", type: "Date", shortname: "submissionDeadline"},
      { name: "Announcement Date", type: "Date", shortname: "announcementDate"},
      { name: "Evaluation Method", type: "String", shortname: "evaluationMethod"},
      { name: "Rules", type: "String", shortname: "rules"},
      { name: "Event Type", type: "String", shortname: "eventType"},
      { name: "Start Date", type: "Date", shortname: "startDate"},
      { name: "End Date", type: "Date", shortname: "endDate"},
      { name: "Location", type: "String", shortname: "location"},
      { name: "Survey ID", type: "String", shortname: "surveyId"},
      { name: "Will Employer Lead", type: "String", shortname: "willEmployerLead"},
      { name: "Location Type", type: "String", shortname: "locationType"},
      { name: "Transportation Details", type: "String", shortname: "transportationDetails"},
      { name: "Org Contact First Name", type: "String", shortname: "orgContactFirstName"},
      { name: "Org Contact Last Name", type: "String", shortname: "orgContactLastName"},
      { name: "Org Contact Email", type: "String", shortname: "orgContactEmail"},
      { name: "Org Name", type: "String", shortname: "orgName"},
      { name: "App Requirements", type: "String", shortname: "appRequirements"},
      { name: "Application Method", type: "String", shortname: "applicationMethod"},
      { name: "Job Link", type: "String", shortname: "jobLink"},
      { name: "Subject Line", type: "String", shortname: "subjectLine"},
      { name: "Pathways", type: "Splitter Join", shortname: "pathways|title"},
      { name: "Departments", type: "Splitter Join", shortname: "departments|name"},
    ]

    applicationFields = [
      { name: "Email", type: "String", shortname: "email"},
      { name: "First Name", type: "String", shortname: "firstName"},
      { name: "Last Name", type: "String", shortname: "lastName"},
      { name: "Posting ID", type: "String", shortname: "postingId"},
      { name: "Posting Title", type: "String", shortname: "postingTitle"},
      { name: "Posting Employer", type: "String", shortname: "postingEmployerName"},
      { name: "Posting Location", type: "String", shortname: "postingLocation"},
      { name: "Picture URL", type: "String", shortname: "pictureURL"},
      { name: "Cell Number", type: "String", shortname: "phoneNumber"},
      { name: "Alternative Email", type: "String", shortname: "alternativeEmail"},
      { name: "Alternative Phone Number", type: "String", shortname: "alternativePhoneNumber"},
      { name: "Education Status", type: "String", shortname: "educationStatus"},
      { name: "School Name", type: "String", shortname: "schoolName"},
      { name: "School Degree", type: "String", shortname: "degree"},
      { name: "School Major", type: "String", shortname: "major"},
      { name: "School Grad Year", type: "String", shortname: "gradYear"},
      { name: "School End Date", type: "String", shortname: "endDate"},
      { name: "Previously Interned", type: "Boolean", shortname: "previouslyInterned"},
      { name: "Stage", type: "String", shortname: "stage"},
      { name: "Resume URL", type: "String", shortname: "resumeURL"},
      { name: "Cover Letter URL", type: "String", shortname: "coverLetterURL"},
      { name: "LinkedIn URL", type: "String", shortname: "linkedInURL"},
      { name: "Portfolio URL", type: "String", shortname: "customWebsiteURL"},
      { name: "Identification URL", type: "String", shortname: "identificationURL"},
      { name: "Letter of Recommendation URL", type: "String", shortname: "letterOfRecommendationURL"},
      { name: "Transcript URL", type: "String", shortname: "transcriptURL"},
      { name: "Date of Birth", type: "String", shortname: "dateOfBirth"},
      { name: "Pathway", type: "String", shortname: "pathway"},
      { name: "Address", type: "String", shortname: "address"},
      { name: "City", type: "String", shortname: "city"},
      { name: "State", type: "String", shortname: "state"},
      { name: "Zip Code", type: "String", shortname: "zipcode"},
      { name: "Country", type: "String", shortname: "country"},
      { name: "Language", type: "String", shortname: "language"},
      { name: "Race", type: "String", shortname: "race"},
      { name: "Races", type: "Simple Join", shortname: "races"},
      { name: "Self Described Race", type: "String", shortname: "selfDescribedRace"},
      { name: "Gender", type: "String", shortname: "gender"},
      { name: "Preferred Pronoun", type: "String", shortname: "preferredPronoun"},
      { name: "Veteran", type: "String", shortname: "veteran"},
      { name: "Work Authorization", type: "String", shortname: "workAuthorization"},
      { name: "Number of Members in Household", type: "String", shortname: "numberOfMembers"},
      { name: "Household Income", type: "String", shortname: "householdIncome"},
      { name: "Foster Youth", type: "String", shortname: "fosterYouth"},
      { name: "Homeless", type: "String", shortname: "homeless"},
      { name: "Incarcerated", type: "String", shortname: "incarcerated"},
      { name: "Completed Training", type: "Boolean", shortname: "isWorkforceReady"},
      { name: "Interviewed", type: "Boolean", shortname: "interviewed"},
      { name: "Referred", type: "Boolean", shortname: "referred"},
      { name: "Referral Code", type: "String", shortname: "referralCode"},
      { name: "Interest Scores", type: "Splitter Join", shortname: "interestScores|name|score"},
      { name: "Trait Scores", type: "Object", shortname: "personalityResults|fiveFactors"},
      { name: "Endorsement Count", type: "Count", shortname: "endorsements"},
      { name: "Experience Hours", type: "Number", shortname: "experienceHours"},
      { name: "Project Hours", type: "Number", shortname: "projectHours"},
      { name: "Volunteer Hours", type: "Number", shortname: "volunteerHours"},
      { name: "Notes", type: "String", shortname: "notes"},
      { name: "Match Score", type: "Number", shortname: "match"},
      { name: "Referral Rank", type: "Number", shortname: "rank"},
      { name: "Org Feedback", type: "String", shortname: "feedback"},
      { name: "Is Subsidy Eligible", type: "Boolean", shortname: "isSubsidyEligible"},
      { name: "Referral Message to Employer", type: "String", shortname: "employerMessage"},
      { name: "Employer Ranking", type: "String", shortname: "employerRanking"},
      { name: "Employer Feedback", type: "String", shortname: "employerFeedback"},
      { name: "Liked by Employer", type: "Boolean", shortname: "liked"},
      { name: "Disliked by Employer", type: "Boolean", shortname: "disliked"},
      { name: "Offer Decision by Candidate", type: "String", shortname: "decision"},
      { name: "Departments", type: "Splitter Join", shortname: "departments|name"},
      { name: "Pathways", type: "Splitter Join", shortname: "pathways|title"},
      { name: "Is Vaccinated", type: "Boolean", shortname: "isVaccinated"},
      // { name: "Adversity List", type: "Simple Join", shortname: "adversityList"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt" },
      { name: "Demographic Info (List)", type: "Simple Join", shortname: "adversityList"},
      { name: "Background Information", type: "Multiple Answers Transposed", shortname: "adversityList"}, // list adversityListOptions
      { name: "Custom Assessment Results", type: "Survey", shortname: "newCustomAssessmentResults"},
    ]

    programApplicationFields = [
      { name: "Email", type: "String", shortname: "email"},
      { name: "First Name", type: "String", shortname: "firstName"},
      { name: "Last Name", type: "String", shortname: "lastName"},
      { name: "Posting ID", type: "String", shortname: "postingId"},
      { name: "Posting Title", type: "String", shortname: "postingTitle"},
      { name: "Posting Employer", type: "String", shortname: "postingEmployerName"},
      { name: "Posting Location", type: "String", shortname: "postingLocation"},
      { name: "Picture URL", type: "String", shortname: "pictureURL"},
      { name: "Cell Number", type: "String", shortname: "phoneNumber"},
      { name: "Alternative Email", type: "String", shortname: "alternativeEmail"},
      { name: "Alternative Phone Number", type: "String", shortname: "alternativePhoneNumber"},
      { name: "Education Status", type: "String", shortname: "educationStatus"},
      { name: "School Name", type: "String", shortname: "schoolName"},
      { name: "School Degree", type: "String", shortname: "degree"},
      { name: "School Major", type: "String", shortname: "major"},
      { name: "School Grad Year", type: "String", shortname: "gradYear"},
      { name: "School End Date", type: "String", shortname: "endDate"},
      { name: "Previously Interned", type: "Boolean", shortname: "previouslyInterned"},
      { name: "Stage", type: "String", shortname: "stage"},
      { name: "Resume URL", type: "String", shortname: "resumeURL"},
      { name: "Cover Letter URL", type: "String", shortname: "coverLetterURL"},
      { name: "LinkedIn URL", type: "String", shortname: "linkedInURL"},
      { name: "Portfolio URL", type: "String", shortname: "customWebsiteURL"},
      { name: "Identification URL", type: "String", shortname: "identificationURL"},
      { name: "Letter of Recommendation URL", type: "String", shortname: "letterOfRecommendationURL"},
      { name: "Transcript URL", type: "String", shortname: "transcriptURL"},
      { name: "Date of Birth", type: "String", shortname: "dateOfBirth"},
      { name: "Pathway", type: "String", shortname: "pathway"},
      { name: "Address", type: "String", shortname: "address"},
      { name: "City", type: "String", shortname: "city"},
      { name: "State", type: "String", shortname: "state"},
      { name: "Zip Code", type: "String", shortname: "zipcode"},
      { name: "Country", type: "String", shortname: "country"},
      { name: "Language", type: "String", shortname: "language"},
      { name: "Race", type: "String", shortname: "race"},
      { name: "Races", type: "Simple Join", shortname: "races"},
      { name: "Self Described Race", type: "String", shortname: "selfDescribedRace"},
      { name: "Gender", type: "String", shortname: "gender"},
      { name: "Preferred Pronoun", type: "String", shortname: "preferredPronoun"},
      { name: "Veteran", type: "String", shortname: "veteran"},
      { name: "Work Authorization", type: "String", shortname: "workAuthorization"},
      { name: "Number of Members in Household", type: "String", shortname: "numberOfMembers"},
      { name: "Household Income", type: "String", shortname: "householdIncome"},
      { name: "Foster Youth", type: "String", shortname: "fosterYouth"},
      { name: "Homeless", type: "String", shortname: "homeless"},
      { name: "Incarcerated", type: "String", shortname: "incarcerated"},
      { name: "Completed Training", type: "Boolean", shortname: "isWorkforceReady"},
      { name: "Interviewed", type: "Boolean", shortname: "interviewed"},
      { name: "Referred", type: "Boolean", shortname: "referred"},
      { name: "Referral Code", type: "String", shortname: "referralCode"},
      { name: "Interest Scores", type: "Splitter Join", shortname: "interestScores|name|score"},
      { name: "Trait Scores", type: "Object", shortname: "personalityResults|fiveFactors"},
      { name: "Endorsement Count", type: "Count", shortname: "endorsements"},
      { name: "Experience Hours", type: "Number", shortname: "experienceHours"},
      { name: "Project Hours", type: "Number", shortname: "projectHours"},
      { name: "Volunteer Hours", type: "Number", shortname: "volunteerHours"},
      { name: "Notes", type: "String", shortname: "notes"},
      { name: "Match Score", type: "Number", shortname: "match"},
      { name: "Referral Rank", type: "Number", shortname: "rank"},
      { name: "Org Feedback", type: "String", shortname: "feedback"},
      { name: "Is Subsidy Eligible", type: "Boolean", shortname: "isSubsidyEligible"},
      { name: "Referral Message to Employer", type: "String", shortname: "employerMessage"},
      { name: "Employer Ranking", type: "String", shortname: "employerRanking"},
      { name: "Employer Feedback", type: "String", shortname: "employerFeedback"},
      { name: "Liked by Employer", type: "Boolean", shortname: "liked"},
      { name: "Disliked by Employer", type: "Boolean", shortname: "disliked"},
      { name: "Offer Decision by Candidate", type: "String", shortname: "decision"},
      { name: "Departments", type: "Splitter Join", shortname: "departments|name"},
      { name: "Pathways", type: "Splitter Join", shortname: "pathways|title"},
      { name: "Is Vaccinated", type: "Boolean", shortname: "isVaccinated"},
      // { name: "Adversity List", type: "Simple Join", shortname: "adversityList"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt" },
      { name: "Demographic Info (List)", type: "Simple Join", shortname: "adversityList"},
      { name: "Background Information", type: "Multiple Answers Transposed", shortname: "adversityList"}, // list adversityListOptions
      { name: "Custom Assessment Results", type: "Survey", shortname: "newCustomAssessmentResults"},
    ]

    projectSubmissionFields = [
      { name: "User First Name", type: "String", shortname: "userFirstName"},
      { name: "User Last Name", type: "String", shortname: "userLastName"},
      { name: "User Email", type: "String", shortname: "userEmail"},
      { name: "Image URL", type: "String", shortname: "imageURL"},
      { name: "Video URL", type: "String", shortname: "videoURL"},
      { name: "Project Id", type: "String", shortname: "projectId"},
      { name: "Project Name", type: "String", shortname: "name"},
      { name: "Project URL", type: "String", shortname: "url"},
      { name: "Project Category", type: "String", shortname: "category"},
      { name: "Start Date", type: "Date", shortname: "startDate"},
      { name: "End Date", type: "End Date", shortname: "endDate"},
      { name: "Description", type: "String", shortname: "description"},
      { name: "Hours", type: "String", shortname: "hours"},
      { name: "Total Hours", type: "String", shortname: "totalHours"},
      { name: "Focus", type: "String", shortname: "focus"},
      { name: "Collaborator Count", type: "Count", shortname: "collaborators"},
      { name: "Grades", type: "Splitter Join", shortname: "grades|grade"},
      { name: "Upvote Count", type: "Count", shortname: "upvotes"},
      { name: "Downvote Count", type: "Count", shortname: "downvotes"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
      { name: "Departments", type: "Splitter Join", shortname: "departments|name"},
      { name: "Pathways", type: "Splitter Join", shortname: "pathways|title"},
    ]

    rsvpFields = [
      { name: "First Name", type: "String", shortname: "firstName"},
      { name: "Last Name", type: "String", shortname: "lastName"},
      { name: "Email", type: "String", shortname: "email"},
      { name: "Phone Number", type: "String", shortname: "phoneNumber"},
      { name: "Role Name", type: "String", shortname: "roleName"},
      { name: "Other Role Name", type: "String", shortname: "otherRoleName"},
      { name: "Existing User", type: "Boolean", shortname: "existingUser"},
      { name: "Posting Title", type: "String", shortname: "postingTitle"},
      { name: "School Name", type: "String", shortname: "schoolName"},
      { name: "Job Title", type: "String", shortname: "jobTitle"},
      { name: "Employer Name", type: "String", shortname: "employerName"},
      { name: "Collaborator Count", type: "Count", shortname: "collaborators"},
      { name: "Activities", type: "Simple Join", shortname: "studentActivityInterests"},
      { name: "Other Answers", type: "Simple Join", shortname: "otherAnswers"},
      { name: "Approved", type: "Boolean", shortname: "approved"},
      { name: "Org Code", type: "String", shortname: "orgCode"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
      { name: "Departments", type: "Splitter Join", shortname: "departments|name"},
      { name: "Pathways", type: "Splitter Join", shortname: "pathways|title"},
      // { name: "Demographic Info (List)", type: "Simple Join", shortname: "adversityList"},
      // { name: "Background Information", type: "Multiple Answers Transposed", shortname: "adversityList"}, // list adversityListOptions
      { name: "Survey Responses", type: "Survey", shortname: "rsvpResponses"},
    ]

    attendeeFields = rsvpFields

    // change to list by question
    assessmentFields = [
      { name: "Custom Assessment Id", type: "String", shortname: "_id"},
      { name: "Custom Assessment Title", type: "String", shortname: "title"},
      { name: "Question Titles", type: "Splitter Join", shortname: "questions|name"},
      { name: "Question Type", type: "Splitter Join", shortname: "questions|questionType"},
      { name: "Question Answer Choices", type: "Splitter Join", shortname: "questions|answerChoices"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    assessmentResultsFields = [
      { name: "Email", type: "String", shortname: "userId"},
      { name: "First Name", type: "String", shortname: "userFirstName"},
      { name: "Last Name", type: "String", shortname: "userLastName"},
      { name: "Username", type: "String", shortname: "userUsername"},
      { name: "Work Preference Answers", type: "Simple Join", shortname: "workPreferenceAnswers"},
      { name: "Interest Scores", type: "Splitter Join", shortname: "interestScores|name|score"},
      { name: "Skills Answers", type: "Splitter Join", shortname: "newSkillAnswers|name|score"},
      { name: "Personality Scores", type: "Object", shortname: "personalityScores|fiveFactors"},
      { name: "Top Values (Gravitate)", type: "Simple Join", shortname: "topGravitateValues"},
      { name: "Top Values (Employer)", type: "Simple Join", shortname: "topEmployerValues"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    benchmarkFields = [
      { name: "Benchmark ID", type: "String", shortname: "_id"},
      { name: "Title", type: "String", shortname: "title"},
      { name: "Associated Job Title", type: "String", shortname: "jobTitle"},
      { name: "Associated Job Function", type: "String", shortname: "jobFunction"},
      { name: "Work Preference Weight", type: "Number", shortname: "workPreferenceWeight"},
      { name: "Interests Weight", type: "Number", shortname: "interestsWeight"},
      { name: "Personality Weight", type: "Number", shortname: "personalityWeight"},
      { name: "Values Weight", type: "Number", shortname: "valuesWeight"},
      { name: "Skills Weight", type: "Number", shortname: "skillsWeight"},
      { name: "Third Party Assessment Weight", type: "Number", shortname: "thirdPartyAssessmentWeight"},
      { name: "Endorsement Weight", type: "Number", shortname: "endorsementWeight"},
      { name: "Education Weight", type: "Number", shortname: "educationWeight"},
      { name: "Project Weight", type: "Number", shortname: "projectWeight"},
      { name: "Experience Weight", type: "Number", shortname: "experienceWeight"},
      { name: "Interview Weight", type: "Number", shortname: "interviewWeight"},
      { name: "Resume Weight", type: "Number", shortname: "resumeWeight"},
      { name: "Cover Letter Weight", type: "Number", shortname: "coverLetterWeight"},
      { name: "Adversity Score Weight", type: "Number", shortname: "adversityScoreWeight"},
      { name: "Political Alignment Weight", type: "Number", shortname: "politicalAlignmentWeight"},
      { name: "Hometown Weight", type: "Number", shortname: "hometownWeight"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    memberFields = [
      { name: "First Name", type: "String", shortname: "firstName"},
      { name: "Last Name", type: "String", shortname: "lastName"},
      { name: "Email", type: "String", shortname: "email"},
      { name: "Role Name", type: "String", shortname: "roleName"},
      { name: "Resume", type: "String", shortname: "resumeURL"},
      { name: "Picture URL", type: "String", shortname: "pictureURL"},
      { name: "Cohort", type: "String", shortname: "cohort"},
      { name: "Birth Date", type: "String", shortname: "dateOfBirth"},
      { name: "Age", type: "Age", shortname: "dateOfBirth"},
      { name: "Education Status", type: "String", shortname: "educationStatus"},
      { name: "School", type: "String", shortname: "school"},
      { name: "Degree", type: "String", shortname: "degree"},
      { name: "Grad Year", type: "String", shortname: "gradYear"},
      { name: "Major", type: "String", shortname: "major"},
      { name: "Pathway", type: "String", shortname: "pathway"},
      { name: "Job Title", type: "String", shortname: "jobTitle"},
      { name: "Employer Name", type: "String", shortname: "employerName"},
      { name: "LinkedIn", type: "String", shortname: "linkedInURL"},
      { name: "Portfolio", type: "String", shortname: "customWebsiteURL"},
      { name: "Instagram URL", type: "String", shortname: "instagramURL"},
      { name: "Facebook URL", type: "String", shortname: "facebookURL"},
      { name: "Twitter URL", type: "String", shortname: "twitterURL"},
      { name: "Education", type: "Object Join", shortname: "education"},
      { name: "Badges & Certificates", type: "Object Join", shortname: "certificates"},
      { name: "Alternative Email", type: "String", shortname: "alternativeEmail"},
      { name: "Phone Number", type: "String", shortname: "phoneNumber"},
      { name: "Alternative Number", type: "String", shortname: "alternativePhoneNumber"},
      { name: "Address", type: "String", shortname: "address"},
      { name: "City", type: "String", shortname: "city"},
      { name: "State", type: "String", shortname: "state"},
      { name: "Zip Code", type: "String", shortname: "zipcode"},
      { name: "Country", type: "String", shortname: "country"},
      { name: "Language", type: "String", shortname: "language"},
      { name: "Race", type: "String", shortname: "race"},
      { name: "Races", type: "Simple Join", shortname: "races"},
      { name: "Self Described Race", type: "String", shortname: "selfDescribedRace"},
      { name: "Gender", type: "String", shortname: "gender"},
      { name: "Preferred Pronoun", type: "String", shortname: "preferredPronoun"},
      { name: "Veteran Status", type: "String", shortname: "veteranStatus"},
      { name: "Work Authorization", type: "String", shortname: "workAuthorization"},
      { name: "Number of Members in Household", type: "String", shortname: "numberOfMembers"},
      { name: "Household Income", type: "String", shortname: "householdIncome"},
      { name: "Foster Youth", type: "String", shortname: "fosterYouth"},
      { name: "Homeless", type: "String", shortname: "homeless"},
      { name: "Incarcerated", type: "String", shortname: "incarcerated"},
      { name: "Referrer Name", type: "String", shortname: "referrerName"},
      { name: "Referrer Email", type: "String", shortname: "referrerEmail"},
      { name: "Referrer Org", type: "String", shortname: "referrerOrg"},
      { name: "Auto Enroll", type: "Boolean", shortname: "autoEnrollInProgram"},
      { name: "Primary Career Interest", type: "String", shortname: "primaryCareerInterest"},
      { name: "Secondary Career Interest", type: "String", shortname: "secondaryCareerInterest"},
      { name: "Tertiary Career Interest", type: "String", shortname: "tertiaryCareerInterest"},
      { name: "Dream Career", type: "String", shortname: "dreamCareer"},
      { name: "Dream Company", type: "String", shortname: "dreamCompany"},
      { name: "Career Status", type: "String", shortname: "careerStatus"},
      { name: "Five-Year Goal", type: "String", shortname: "fiveYearGoal"},
      { name: "Industry", type: "String", shortname: "industry"},
      { name: "Unsubscribed", type: "Boolean", shortname: "unsubscribed"},
      { name: "Unsub Reason", type: "String", shortname: "unsubscribeReason"},
      { name: "Unsub Other Explanation", type: "String", shortname: "otherExplanation"},
      { name: "Unsub Placed Job Title", type: "String", shortname: "placedJobTitle"},
      { name: "Unsub Placed Employer Name", type: "String", shortname: "placedEmployerName"},
      { name: "Profile Is Public", type: "Boolean", shortname: "publicProfile"},
      { name: "Headline", type: "String", shortname: "headline"},
      { name: "Matching Preferences", type: "Splitter Join", shortname: "matchingPreferences|name|value"},
      { name: "Matching Use Cases", type: "Splitter Join", shortname: "matchingUseCases|name|selected"},
      { name: "Interviews", type: "Splitter Join", shortname: "evaluations|interviews|score"},
      { name: "Notes", type: "Splitter Join", shortname: "evaluations|notes"},
      { name: "Feedback", type: "Splitter Join", shortname: "evaluations|feedback"},
      { name: "Subsidy Eligible", type: "Splitter Join", shortname: "evaluations|isSubsidyEligible"},
      { name: "Passed Workforce Ready Courses", type: "Splitter Join", shortname: "evaluations|isWorkforceReady"},
      { name: "Is WorkReady Certified", type: "Splitter Join", shortname: "evaluations|isWorkforceCertified"},
      { name: "Has Work Permit", type: "Splitter Join", shortname: "evaluations|notes"},
      { name: "Is High Potential/Priority", type: "Boolean", shortname: "hasWorkPermit"},
      { name: "Is Vaccinated", type: "Boolean", shortname: "isVaccinated"},
      { name: "Pathways", type: "Splitter Join", shortname: "pathways|title"},
      { name: "Departments", type: "Splitter Join", shortname: "departments|name"},
      { name: "Enrollment Status", type: "String", shortname: "enrollmentStatus"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
      { name: "Demographic Info (List)", type: "Simple Join", shortname: "adversityList"},
      { name: "Background Information", type: "Multiple Answers Transposed", shortname: "adversityList"},
    ]

    participantFields = [
      { name: "First Name", type: "String", shortname: "firstName"},
      { name: "Last Name", type: "String", shortname: "lastName"},
      { name: "Email", type: "String", shortname: "email"},
      { name: "Role Name", type: "String", shortname: "roleName"},
      { name: "Participation Score", type: "String", shortname: "participationScore"},
      { name: "Participation", type: "MappedItems", shortname: "participationArray"},
      // { name: "Resume", type: "String", shortname: "resumeURL"},
      // { name: "Picture URL", type: "String", shortname: "pictureURL"},
      // { name: "Cohort", type: "String", shortname: "cohort"},
      // { name: "Birth Date", type: "String", shortname: "dateOfBirth"},
      // { name: "Age", type: "Age", shortname: "dateOfBirth"},
      // { name: "Education Status", type: "String", shortname: "educationStatus"},
      // { name: "School", type: "String", shortname: "school"},
      // { name: "Degree", type: "String", shortname: "degree"},
      // { name: "Grad Year", type: "String", shortname: "gradYear"},
      // { name: "Major", type: "String", shortname: "major"},
      // { name: "Pathway", type: "String", shortname: "pathway"},
      // { name: "Job Title", type: "String", shortname: "jobTitle"},
      // { name: "Employer Name", type: "String", shortname: "employerName"},
      // { name: "LinkedIn", type: "String", shortname: "linkedInURL"},
      // { name: "Portfolio", type: "String", shortname: "customWebsiteURL"},
      // { name: "Instagram URL", type: "String", shortname: "instagramURL"},
      // { name: "Facebook URL", type: "String", shortname: "facebookURL"},
      // { name: "Twitter URL", type: "String", shortname: "twitterURL"},
      // { name: "Education", type: "Object Join", shortname: "education"},
      // { name: "Badges & Certificates", type: "Object Join", shortname: "certificates"},
      // { name: "Alternative Email", type: "String", shortname: "alternativeEmail"},
      // { name: "Phone Number", type: "String", shortname: "phoneNumber"},
      // { name: "Alternative Number", type: "String", shortname: "alternativePhoneNumber"},
      // { name: "Address", type: "String", shortname: "address"},
      // { name: "City", type: "String", shortname: "city"},
      // { name: "State", type: "String", shortname: "state"},
      // { name: "Zip Code", type: "String", shortname: "zipcode"},
      // { name: "Country", type: "String", shortname: "country"},
      // { name: "Language", type: "String", shortname: "language"},
      // { name: "Race", type: "String", shortname: "race"},
      // { name: "Races", type: "Simple Join", shortname: "races"},
      // { name: "Self Described Race", type: "String", shortname: "selfDescribedRace"},
      // { name: "Gender", type: "String", shortname: "gender"},
      // { name: "Preferred Pronoun", type: "String", shortname: "preferredPronoun"},
      // { name: "Veteran Status", type: "String", shortname: "veteranStatus"},
      // { name: "Work Authorization", type: "String", shortname: "workAuthorization"},
      // { name: "Number of Members in Household", type: "String", shortname: "numberOfMembers"},
      // { name: "Household Income", type: "String", shortname: "householdIncome"},
      // { name: "Foster Youth", type: "String", shortname: "fosterYouth"},
      // { name: "Homeless", type: "String", shortname: "homeless"},
      // { name: "Incarcerated", type: "String", shortname: "incarcerated"},
      // { name: "Referrer Name", type: "String", shortname: "referrerName"},
      // { name: "Referrer Email", type: "String", shortname: "referrerEmail"},
      // { name: "Referrer Org", type: "String", shortname: "referrerOrg"},
      // { name: "Auto Enroll", type: "Boolean", shortname: "autoEnrollInProgram"},
      // { name: "Primary Career Interest", type: "String", shortname: "primaryCareerInterest"},
      // { name: "Secondary Career Interest", type: "String", shortname: "secondaryCareerInterest"},
      // { name: "Tertiary Career Interest", type: "String", shortname: "tertiaryCareerInterest"},
      // { name: "Dream Career", type: "String", shortname: "dreamCareer"},
      // { name: "Dream Company", type: "String", shortname: "dreamCompany"},
      // { name: "Career Status", type: "String", shortname: "careerStatus"},
      // { name: "Five-Year Goal", type: "String", shortname: "fiveYearGoal"},
      // { name: "Industry", type: "String", shortname: "industry"},
      // { name: "Unsubscribed", type: "Boolean", shortname: "unsubscribed"},
      // { name: "Unsub Reason", type: "String", shortname: "unsubscribeReason"},
      // { name: "Unsub Other Explanation", type: "String", shortname: "otherExplanation"},
      // { name: "Unsub Placed Job Title", type: "String", shortname: "placedJobTitle"},
      // { name: "Unsub Placed Employer Name", type: "String", shortname: "placedEmployerName"},
      // { name: "Profile Is Public", type: "Boolean", shortname: "publicProfile"},
      // { name: "Headline", type: "String", shortname: "headline"},
      // { name: "Matching Preferences", type: "Splitter Join", shortname: "matchingPreferences|name|value"},
      // { name: "Matching Use Cases", type: "Splitter Join", shortname: "matchingUseCases|name|selected"},
      // { name: "Interviews", type: "Splitter Join", shortname: "evaluations|interviews|score"},
      // { name: "Notes", type: "Splitter Join", shortname: "evaluations|notes"},
      // { name: "Feedback", type: "Splitter Join", shortname: "evaluations|feedback"},
      // { name: "Subsidy Eligible", type: "Splitter Join", shortname: "evaluations|isSubsidyEligible"},
      // { name: "Passed Workforce Ready Courses", type: "Splitter Join", shortname: "evaluations|isWorkforceReady"},
      // { name: "Is WorkReady Certified", type: "Splitter Join", shortname: "evaluations|isWorkforceCertified"},
      // { name: "Has Work Permit", type: "Splitter Join", shortname: "evaluations|notes"},
      // { name: "Is High Potential/Priority", type: "Boolean", shortname: "hasWorkPermit"},
      // { name: "Is Vaccinated", type: "Boolean", shortname: "isVaccinated"},
      // { name: "Pathways", type: "Splitter Join", shortname: "pathways|title"},
      // { name: "Departments", type: "Splitter Join", shortname: "departments|name"},
      // { name: "Enrollment Status", type: "String", shortname: "enrollmentStatus"},
      // { name: "Created At", type: "Date", shortname: "createdAt"},
      // { name: "Updated At", type: "Date", shortname: "updatedAt"},
      // { name: "Demographic Info (List)", type: "Simple Join", shortname: "adversityList"},
      // { name: "Background Information", type: "Multiple Answers Transposed", shortname: "adversityList"},
    ]

    let showNestedHeaders = true
    if (showNestedHeaders) {
      participantFields = [
        [
          { name: "", type: "String", shortname: ""},
          { name: "", type: "String", shortname: ""},
          { name: "", type: "String", shortname: ""},
          { name: "", type: "String", shortname: ""},
          { name: "", type: "String", shortname: ""},
          { name: "Participation", type: "MappedItems", shortname: "participationArray"},
        ], [
          { name: "First Name", type: "String", shortname: "firstName"},
          { name: "Last Name", type: "String", shortname: "lastName"},
          { name: "Email", type: "String", shortname: "email"},
          { name: "Role Name", type: "String", shortname: "roleName"},
          { name: "Participation Score", type: "Number", shortname: "participationScore"},
          { name: "Participation", type: "MappedItems", shortname: "participationArray"},
        ]
      ]
    }

    employerFields = [
      { name: "Employer Name", type: "String", shortname: "employerName"},
      { name: "Employer Type", type: "String", shortname: "employerType"},
      { name: "Employer URL", type: "String", shortname: "employerURL"},
      { name: "Employer Location", type: "String", shortname: "employerLocation"},
      { name: "Employer Industry", type: "String", shortname: "employerIndustry"},
      { name: "Employee Count", type: "String", shortname: "employeeCount"},
      { name: "Employee Growth", type: "String", shortname: "employeeGrowth"},
      { name: "Projected Hires", type: "String", shortname: "projectedHires"},
      { name: "Contact First Name", type: "String", shortname: "contactFirstName"},
      { name: "Contact Last Name", type: "String", shortname: "contactLastName"},
      { name: "Contact Email", type: "String", shortname: "contactEmail"},
      { name: "Contact Phone", type: "String", shortname: "contactPhone"},
      { name: "Contact Title", type: "String", shortname: "contactTitle"},
      { name: "Subsidy Approved", type: "Boolean", shortname: "subsidyApproved"},
      { name: "Subsidy Award", type: "String", shortname: "subsidyAward"},
      { name: "Success Metrics", type: "Simple Join", shortname: "successMetrics"},
      { name: "Volunteer Preferences", type: "Simple Join", shortname: "volunteerPreferences"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    goalFields = [
      { name: "Title", type: "String", shortname: "title"},
      { name: "First Name", type: "String", shortname: "creatorFirstName"},
      { name: "Last Name", type: "String", shortname: "creatorLastName"},
      { name: "Email", type: "String", shortname: "creatorEmail"},
      { name: "Goal Type", type: "Object", shortname: "goalType|name"},
      { name: "Description", type: "String", shortname: "description"},
      { name: "Start Date", type: "Date", shortname: "startDate"},
      { name: "Deadline", type: "Date", shortname: "deadline"},
      { name: "Status", type: "String", shortname: "status"},
      { name: "Intensity", type: "String", shortname: "intensity"},
      { name: "Budget", type: "Number", shortname: "budget"},
      { name: "Competencies", type: "Splitter Join", shortname: "competencies|name"},
      { name: "Success Defined", type: "String", shortname: "successDefined"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    mentorMatchFields = [
      { name: "Mentee First Name", type: "String", shortname: "adviseeFirstName"},
      { name: "Mentee Last Name", type: "String", shortname: "adviseeLastName"},
      { name: "Mentee Email", type: "String", shortname: "adviseeEmail"},
      { name: "Mentor First Name", type: "String", shortname: "advisorFirstName"},
      { name: "Mentor Last Name", type: "String", shortname: "advisorLastName"},
      { name: "Mentor Email", type: "String", shortname: "advisorEmail"},
      { name: "Match Score", type: "Number", shortname: "matchScore"},
      { name: "Active", type: "Boolean", shortname: "active"},
      { name: "Cohort", type: "Number", shortname: "cohort"},
      { name: "Start Dates", type: "Simple Join", shortname: "startDates"},
      { name: "End Dates", type: "Simple Join", shortname: "endDates"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    scoreFields = []
    challengeFields = []

    placementFields = [
      { name: "First Name", type: "String", shortname: "firstName"},
      { name: "Last Name", type: "String", shortname: "lastName"},
      { name: "Email", type: "String", shortname: "email"},
      { name: "Title", type: "String", shortname: "title"},
      { name: "Employer Name", type: "String", shortname: "employerName"},
      { name: "Work Type", type: "String", shortname: "workType"},
      { name: "Employer URL", type: "String", shortname: "employerURL"},
      { name: "Hours Per Week", type: "String", shortname: "hoursPerWeek"},
      { name: "Industry", type: "String", shortname: "industry"},
      { name: "Work Function", type: "String", shortname: "workFunction"},
      { name: "Location", type: "String", shortname: "location"},
      { name: "Start Date", type: "String", shortname: "startDate"},
      { name: "End Date", type: "String", shortname: "endDate"},
      { name: "Pay Type", type: "String", shortname: "payType"},
      { name: "Pay", type: "Number", shortname: "pay"},
      { name: "Description", type: "String", shortname: "description"},
      { name: "Hiring Manager First Name", type: "String", shortname: "hiringManagerFirstName"},
      { name: "Hiring Manager Last Name", type: "String", shortname: "hiringManagerLastName"},
      { name: "Hiring Manager Email", type: "String", shortname: "hiringManagerEmail"},
      { name: "Internal Contact Email", type: "String", shortname: "orgContactEmail"},
      { name: "Pathways", type: "Splitter Join", shortname: "pathways|title"},
      { name: "Departments", type: "Splitter Join", shortname: "departments|name"},
      // { name: "Employment Hour Details", type: "Stringify", shortname: "employmentHourDetails"},
      // { name: "Academic Learning Objectives", type: "Stringify", shortname: "academicLearningObjectives"},
      // { name: "Career Development Objectives", type: "Stringify", shortname: "careerDevelopmentObjectives"},
      // { name: "Site Visit Schedule", type: "Stringify", shortname: "siteVisitSchedule"},
      { name: "Pathway Placement Type", type: "String", shortname: "pathwayPlacementType"},
      { name: "Picture URL", type: "String", shortname: "pictureURL"},
      { name: "Birth Date", type: "String", shortname: "dateOfBirth"},
      { name: "Age", type: "String", shortname: "age"},
      { name: "Education Status", type: "String", shortname: "educationStatus"},
      { name: "Education", type: "Splitter Join", shortname: "education|name"},
      { name: "Alternative Email", type: "String", shortname: "alternativeEmail"},
      { name: "Phone Number", type: "String", shortname: "phoneNumber"},
      { name: "Alternative Number", type: "String", shortname: "alternativePhoneNumber"},
      { name: "Address", type: "String", shortname: "addreess"},
      { name: "City", type: "String", shortname: "city"},
      { name: "Zip Code", type: "String", shortname: "zipcode"},
      { name: "Race", type: "String", shortname: "race"},
      { name: "Races", type: "Simple Join", shortname: "races"},
      { name: "Self Described Race", type: "String", shortname: "selfDescribedRace"},
      { name: "Gender", type: "String", shortname: "gender"},
      { name: "Veteran Status", type: "String", shortname: "veteranStatus"},
      { name: "Work Authorization", type: "String", shortname: "workAuthorization"},
      { name: "Number of Members in Household", type: "String", shortname: "numberOfMembers"},
      { name: "Household Income", type: "String", shortname: "householdIncome"},
      // { name: "Not enrolled/attending school", type: "String", shortname: "adviseeFirstName"},
      // { name: "Not currently working", type: "String", shortname: "adviseeFirstName"},
      // { name: "LGBTQIA+", type: "String", shortname: "adviseeFirstName"},
      // { name: "First-Generation Immigrant (born in another country without U.S. citizenship)", type: "String", shortname: "adviseeFirstName"},
      // { name: "No parent/guardian completed a four-year college degree (Bachelor’s degree)", type: "String", shortname: "adviseeFirstName"},
      // { name: "Currently/formerly a foster youth", type: "String", shortname: "adviseeFirstName"},
      // { name: "Justice Involved", type: "String", shortname: "adviseeFirstName"},
      // { name: "Currently without stable housing (home insecure or homeless)", type: "String", shortname: "adviseeFirstName"},
      // { name: "Pregnant/Parenting", type: "String", shortname: "adviseeFirstName"},
      // { name: "Person with disability", type: "String", shortname: "adviseeFirstName"},
      // { name: "Veteran", type: "String", shortname: "adviseeFirstName"},
      // { name: "Receiving Free or Reduced-Price Lunch", type: "String", shortname: "adviseeFirstName"},
      // { name: "Receiving Food Stamps", type: "String", shortname: "adviseeFirstName"},
      // { name: "Receiving TANF", type: "String", shortname: "adviseeFirstName"},
      // { name: "Receiving General Assistance", type: "String", shortname: "adviseeFirstName"},
      // { name: "Receiving SSI", type: "String", shortname: "adviseeFirstName"},
      // { name: "Receiving Refugee Cash Assistance", type: "String", shortname: "adviseeFirstName"},
      // { name: "Other Demographic Information", type: "String", shortname: "adviseeFirstName"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    messageFields = [
      { name: "ID", type: "String", shortname: "_id"},
      { name: "Sender First Name", type: "String", shortname: "senderFirstName"},
      { name: "Sender Last Name", type: "String", shortname: "senderLastName"},
      { name: "Sender Email", type: "String", shortname: "senderEmail"},
      { name: "Recipient First Name", type: "Splitter Join", shortname: "recipients|firstName"},
      { name: "Recipient Last Name", type: "Splitter Join", shortname: "recipients|lastName"},
      { name: "Recipient Email", type: "Splitter Join", shortname: "recipients|email"},
      { name: "Message", type: "String", shortname: "message"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    timesheetFields = [
      { name: "Worker First Name", type: "String", shortname: "workerFirstName"},
      { name: "Worker Last Name", type: "String", shortname: "workerLastName"},
      { name: "Worker Email", type: "String", shortname: "emailId"},
      { name: "Work Type", type: "String", shortname: "workType"},
      { name: "Title", type: "String", shortname: "title"},
      { name: "Employer Name", type: "String", shortname: "employerName"},
      { name: "Manager First Name", type: "String", shortname: "managerFirstName"},
      { name: "Manager Last Name", type: "String", shortname: "managerLastName"},
      { name: "Manager Email", type: "String", shortname: "managerEmail"},
      { name: "Assignments", type: "Splitter Join", shortname: "assignments|name"},
      { name: "Projects", type: "Splitter Join", shortname: "projects|name"},
      { name: "Worker Rating", type: "Number", shortname: "workerRating"},
      { name: "Worker Feedback", type: "String", shortname: "message"},
      { name: "Manager Rating", type: "Numbeer", shortname: "managerRating"},
      { name: "Manager Feedback", type: "String", shortname: "message"},
      { name: "Message", type: "String", shortname: "message"},
      { name: "Is Submitted", type: "Boolean", shortname: "isSubmitted"},
      { name: "Is Approved", type: "Boolean", shortname: "isApproved"},
      { name: "Is Rejected", type: "Boolean", shortname: "isRejected"},
      { name: "Cards", type: "Stringify", shortname: "cards"},
      { name: "Days", type: "Splitter Join", shortname: "cards|day"},
      { name: "Hours", type: "Splitter Join", shortname: "cards|hours"},
      { name: "Tasks", type: "Splitter Join", shortname: "cards|tasks"},
      { name: "Start Date", type: "Date", shortname: "startDate"},
      { name: "End Date", type: "Date", shortname: "endDate"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    courseFields = [
      { name: "Title", type: "String", shortname: "name"},
      { name: "Course Unique ID", type: "String", shortname: "_id"},
      { name: "Program URL", type: "String", shortname: "url"},
      { name: "Image URL", type: "String", shortname: "imageURL"},
      { name: "Category", type: "String", shortname: "category"},
      { name: "School Name", type: "String", shortname: "schoolName"},
      { name: "School URL", type: "String", shortname: "schoolURL"},
      { name: "Degree Type", type: "String", shortname: "degreeType"},
      { name: "Delivery Method", type: "String", shortname: "programMethod"},
      { name: "Location", type: "String", shortname: "location"},
      { name: "Estimated Hours", type: "String", shortname: "estimatedHours"},
      { name: "Grade Level", type: "String", shortname: "gradeLevel"},
      { name: "Knowledge Level", type: "String", shortname: "knowledgeLevel"},
      { name: "Main Contact First Name", type: "String", shortname: "mainContactFirstName"},
      { name: "Main Contact Last Name", type: "String", shortname: "mainContactLastName"},
      { name: "Main Contact Email", type: "String", shortname: "mainContactEmail"},
      { name: "Main Contact Title", type: "String", shortname: "mainContactTitle"},
      { name: "Is Active", type: "Boolean", shortname: "isActive"},
      { name: "Price", type: "Number", shortname: "price"},
      { name: "Industry", type: "String", shortname: "industry"},
      { name: "Functions", type: "Simple Join", shortname: "functions"},
      { name: "Pathways", type: "Splitter Join", shortname: "pathways|title"},
      { name: "Departments", type: "Splitter Join", shortname: "departments|name"},
      { name: "Sample Job Titles", type: "String", shortname: "sampleJobTitles"},
      { name: "Outcomes", type: "String", shortname: "outcomes"},
      { name: "Source", type: "String", shortname: "source"},
      { name: "Description", type: "String", shortname: "description"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    enrollmentFields = [
      { name: "First Name", type: "String", shortname: "firstName"},
      { name: "Last Name", type: "String", shortname: "lastName"},
      { name: "Email", type: "String", shortname: "email"},
      { name: "Username", type: "String", shortname: "username"},
      { name: "Picture URL", type: "String", shortname: "pictureURL"},
      { name: "Course ID", type: "String", shortname: "courseId"},
      { name: "Course Name", type: "String", shortname: "courseName"},
      { name: "Course Image URL", type: "String", shortname: "courseImageURL"},
      { name: "Course Category", type: "String", shortname: "courseCategory"},
      { name: "Course Degree Type", type: "String", shortname: "courseDegreeType"},
      { name: "Course School Name", type: "String", shortname: "courseSchoolName"},
      { name: "Course School URL", type: "String", shortname: "courseSchoolName"},
      { name: "Course Estimated Hours", type: "String", shortname: "courseEstimatedHours"},
      { name: "Course Program Method", type: "String", shortname: "courseProgramMethod"},
      { name: "Course Description", type: "String", shortname: "courseDescription"},
      { name: "Is Completed", type: "Boolean", shortname: "isCompleted"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    endorsementFields = [
      { name: "Recipient First Name", type: "String", shortname: "recipientFirstName"},
      { name: "Recipient Last Name", type: "String", shortname: "recipientLastName"},
      { name: "Recipient Email", type: "String", shortname: "recipientEmail"},
      { name: "Recipient Username", type: "String", shortname: "recipientUsername"},
      { name: "Recipient Picture URL", type: "String", shortname: "recipientPictureURL"},
      { name: "Sender First Name", type: "String", shortname: "senderFirstName"},
      { name: "Sender Last Name", type: "String", shortname: "senderLastName"},
      { name: "Sender Email", type: "String", shortname: "senderEmail"},
      { name: "Sender Job Title", type: "String", shortname: "senderJobTitle"},
      { name: "Sender Employer Name", type: "String", shortname: "senderEmployerName"},
      { name: "Sender Username", type: "String", shortname: "senderUsername"},
      { name: "Sender Picture URL", type: "String", shortname: "senderPictureURL"},
      { name: "Sender Headline", type: "String", shortname: "senderHeadline"},
      { name: "Sender LinkedIn", type: "String", shortname: "senderLinkedIn"},
      { name: "Sender Has Studied", type: "String", shortname: "hasStudied"},
      { name: "Sender Hours Studied", type: "String", shortname: "hoursStudied"},
      { name: "Sender Has Worked", type: "String", shortname: "hasWorked"},
      { name: "Sender Hours Worked", type: "String", shortname: "hoursWorked"},
      { name: "Sender Has Managed", type: "String", shortname: "hasManaged"},
      { name: "Sender Hours Managed", type: "String", shortname: "hoursManaged"},
      { name: "Sender Has Taught", type: "String", shortname: "hasTaught"},
      { name: "Sender Hours Taught", type: "String", shortname: "hoursTaught"},
      { name: "Relationship", type: "String", shortname: "relationship"},
      { name: "Overall Rating", type: "Number", shortname: "overallRating"},
      { name: "Average Rating", type: "Number", shortname: "averageRating"},
      { name: "Pathway", type: "String", shortname: "pathway"},
      { name: "Competencies", type: "Stringify", shortname: "competencies"},
      { name: "Examples", type: "Stringify", shortname: "examples"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    sessionFields = [
      { name: "Advisee First Name", type: "String", shortname: "adviseeFirstName"},
      { name: "Advisee Last Name", type: "String", shortname: "adviseeLastName"},
      { name: "Advisee Email", type: "String", shortname: "adviseeEmail"},
      { name: "Advisor First Name", type: "String", shortname: "advisorFirstName"},
      { name: "Advisor Last Name", type: "String", shortname: "advisorLastName"},
      { name: "Advisor Email", type: "String", shortname: "advisorEmail"},
      { name: "Category", type: "String", shortname: "category"},
      { name: "Session Date", type: "Date", shortname: "sessionDate"},
      { name: "Session Method", type: "String", shortname: "sessionMethod"},
      { name: "Hidden to Career-Seeker", type: "Boolean", shortname: "hiddenToCareerSeeker"},
      { name: "Meeting Purpose", type: "String", shortname: "meetingPurpose"},
      { name: "Meeting Outcome", type: "String", shortname: "meetingOutcome"},
      { name: "Meeting Tags", type: "String", shortname: "meetingTags"},
      { name: "Notes", type: "String", shortname: "notes"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    referralFields = [
      { name: "Recipient First Name", type: "String", shortname: "recipientFirstName"},
      { name: "Recipient Last Name", type: "String", shortname: "recipientLastName"},
      { name: "Recipient Email", type: "String", shortname: "recipientEmail"},
      { name: "Recipient Username", type: "String", shortname: "recipientUsername"},
      { name: "Recipient Picture URL", type: "String", shortname: "recipientPictureURL"},
      { name: "Referrer First Name", type: "String", shortname: "referrerFirstName"},
      { name: "Referrer Last Name", type: "String", shortname: "referrerLastName"},
      { name: "Referrer Email", type: "String", shortname: "referrerEmail"},
      { name: "Referrer Username", type: "String", shortname: "referrerUsername"},
      { name: "Referrer Picture URL", type: "String", shortname: "referrerPictureURL"},
      { name: "Referrer LinkedIn URL", type: "String", shortname: "referrerLinkedInURL"},
      { name: "Referrer Job Title", type: "String", shortname: "referrerJobTitle"},
      { name: "Referrer Employer Name", type: "String", shortname: "referrerEmployerName"},
      { name: "Referrer Partner Org", type: "String", shortname: "referrerPartnerOrg"},
      { name: "Referrer Is Existing User", type: "Boolean", shortname: "existingUser"},
      { name: "Relationship", type: "String", shortname: "relationship"},
      { name: "Opportunity ID", type: "String", shortname: "opportunityId"},
      { name: "Opportunity Title", type: "String", shortname: "opportunityTitle"},
      { name: "Opportunity Employer Name", type: "String", shortname: "opportunityEmployerName"},
      { name: "Referral Code", type: "String", shortname: "_id"},
      { name: "Endorsement Attached", type: "Boolean", shortname: "attachEndorsement"},
      { name: "Endorsement Id", type: "String", shortname: "endorsementId"},
      { name: "Reason", type: "String", shortname: "reason"},
      { name: "Referral Bonus", type: "String", shortname: "referralBonus"},
      { name: "Status", type: "String", shortname: "status"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    caseNoteFields = [
      { name: "First Name", type: "String", shortname: "firstName"},
      { name: "Last Name", type: "String", shortname: "lastName"},
      { name: "Email", type: "String", shortname: "email"},
      { name: "Username", type: "String", shortname: "username"},
      { name: "Role Name", type: "String", shortname: "roleName"},
      { name: "Case Date", type: "Date", shortname: "caseDate"},
      { name: "Case Manager", type: "String", shortname: "workerName"},
      { name: "Title", type: "String", shortname: "title"},
      { name: "Program", type: "String", shortname: "program"},
      { name: "Tags", type: "Stringify", shortname: "tags"},
      { name: "Notes", type: "String", shortname: "notes"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    messageBlastFields = [
      { name: "Recipient First Name", type: "String", shortname: "recipientFirstName"},
      { name: "Recipient Last Name", type: "String", shortname: "recipientLastName"},
      { name: "Recipient Email", type: "String", shortname: "recipientEmail"},
      { name: "Recipient Type", type: "String", shortname: "recipientType"},
      { name: "Message Type", type: "String", shortname: "messageType"},
      { name: "Message Title", type: "String", shortname: "messageTitle"},
      { name: "Message Text", type: "String", shortname: "messageText"},
      { name: "Sent", type: "Boolean", shortname: "sent"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    pathwayFields = [
      { name: "Title", type: "String", shortname: "title"},
      { name: "Job Title", type: "String", shortname: "jobTitle"},
      { name: "Job Function", type: "String", shortname: "jobFunction"},
      { name: "Industry", type: "String", shortname: "industry"},
      { name: "Description", type: "String", shortname: "description"},
      { name: "Benchmark Id", type: "String", shortname: "benchmarkId"},
      { name: "Image URL", type: "String", shortname: "imageURL"},
      { name: "Videos", type: "Simple Join", shortname: "videos"},
      { name: "Checklist", type: "Splitter Join", shortname: "checklist|name"},
      { name: "Is Active", type: "Boolean", shortname: "isActive"},
      { name: "Documentation", type: "String", shortname: "documentation"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    lessonFields = [
      { name: "Name", type: "String", shortname: "name"},
      { name: "Description", type: "String", shortname: "description"},
      { name: "Minutes", type: "Number", shortname: "minutes"},
      { name: "Internal URL", type: "String", shortname: "url"},
      { name: "Student URL", type: "String", shortname: "studentURL"},
      { name: "Submission Deadline", type: "Date", shortname: "submissionDeadline"},
      { name: "Custom Assessment Id", type: "String", shortname: "customAssessmentId"},
      { name: "Mentor Assessment ID", type: "String", shortname: "mentorAssessmentId"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
    ]

    surveySubmissionFields = [
      { name: "First Name", type: "String", shortname: "firstName"},
      { name: "Last Name", type: "String", shortname: "lastName"},
      { name: "Email", type: "String", shortname: "emailId"},
      { name: "Role Name", type: "String", shortname: "roleName"},
      { name: "Cohort", type: "String", shortname: "cohort"},
      { name: "Lesson Name", type: "String", shortname: "lessonName"},
      { name: "Survey Name", type: "String", shortname: "assessmentName"},
      { name: "Created At", type: "Date", shortname: "createdAt"},
      { name: "Updated At", type: "Date", shortname: "updatedAt"},
      { name: "Survey Responses", type: "Survey", shortname: "answers"},
    ]

    keyMetricFields = [
      { name: "Month-End Date", type: "Date", shortname: "createdAt"},
      { name: "Sign Ups", type: "Number", shortname: "signUpCount"},
      { name: "Active Users", type: "Number", shortname: "activeUsers"},
      { name: "Submissions", type: "Number", shortname: "submissionCount"},
      { name: "Grades", type: "Number", shortname: "gradeCount"},
      { name: "Certificates", type: "Number", shortname: "certificateCount"},
      { name: "Placements", type: "Number", shortname: "placementCount"},
      { name: "Referrals", type: "Number", shortname: "referralCode"},
      { name: "Endorsements", type: "Number", shortname: "endorsementCount"},
      { name: "Documents", type: "Number", shortname: "documentCount"},
      { name: "Goals", type: "Number", shortname: "goalCount"},
      { name: "Advising Sessions", type: "Number", shortname: "advisingSessionCount"},
      { name: "RSVPs", type: "Number", shortname: "rsvpCount"},
      { name: "Applications", type: "Number", shortname: "applicationCount"},
      { name: "Career Assessments", type: "Number", shortname: "assessmentResultCount"},
    ]
  }

  let reportOptions = [
    { name: '' },
    { name: 'Postings Report', description: 'All event, project, and work postings', shortname: 'postings', fields: postingFields },
    { name: 'Applications to Work Report', description: 'All applications to work postings', shortname: 'applications', fields: applicationFields },
    { name: 'Applications to Programs Report', description: 'All applications to program postings', shortname: 'programApplications', fields: programApplicationFields },
    { name: 'Project Submissions Report', description: 'All project submissions to project postings', shortname: 'projectSubmissions', fields: projectSubmissionFields },
    { name: 'RSVP Report', description: 'All RSVPs to career events and challenges (postings)', shortname: 'rsvps', fields: rsvpFields },
    { name: 'Attendees Report', description: 'All attendees to career events', shortname: 'attendees', fields: attendeeFields },
    { name: 'Assessments Report', description: 'All custom survey / assessment templates created', shortname: 'assessments', fields: assessmentFields },
    { name: 'Assessment Results Report', description: 'All career assessment results', shortname: 'assessmentResults', fields: assessmentResultsFields },
    { name: 'Benchmarks Report', description: 'All benchmark (i.e., ideal candidate) templates', shortname: 'benchmarks', fields: benchmarkFields },
    { name: 'Members Report', description: 'Everyone who has signed up to your workspace / portal', shortname: 'members', fields: memberFields },
    { name: 'Employers Report', description: 'Employers who have been added to your workspace / portal', shortname: 'employers', fields: employerFields },
    { name: 'Goals Report', description: 'All logged goals within your org', shortname: 'goals', fields: goalFields },
    { name: 'Mentor Match Report', description: 'All mentor-mentee matches', shortname: 'mentorMatches', fields: mentorMatchFields },
    // { name: 'Scores Report', description: '3rd party scores on applicants', shortname: 'scores', fields: scoreFields },
    // { name: 'Challenge Voting Report', description: 'All votes from challenges', shortname: 'challengeVoting'},
    { name: 'Placements Report', description: 'All placements into work opportunities', shortname: 'placements', fields: placementFields },
    { name: 'Messages Report', description: 'All messages within your org', shortname: 'messages', fields: messageFields },
    { name: 'Timesheets Report', description: 'All timesheets within your org', shortname: 'timesheets', fields: timesheetFields },
    { name: 'Courses Report', description: 'All training programs and courses within your org', shortname: 'courses', fields: courseFields },
    { name: 'Enrollments Report', description: "All enrollments in your org's training programs and courses", shortname: 'enrollments', fields: enrollmentFields },
    { name: 'Endorsements Report', description: "All endorsements in your org", shortname: 'endorsements', fields: endorsementFields },
    { name: 'Advising Sessions Report', description: "All advising sessions in your org", shortname: 'sessions', fields: sessionFields },
    { name: 'Referrals Report', description: "All 3rd party referrals to your org", shortname: 'referrals', fields: referralFields },
    { name: 'Case Notes Report', description: "All case notes associated with a career-seeker", shortname: 'caseNotes', fields: caseNoteFields },
    // { name: 'Message Blasts Report', description: "All message blasts sent out", shortname: 'messageBlasts', fields: messageBlastFields },
    { name: 'Pathways Report', description: "All custom pathways created", shortname: 'pathways', fields: pathwayFields },
    { name: 'Lessons Report', description: "All custom lessons created", shortname: 'lessons', fields: lessonFields },
    { name: 'Survey Submissions Report', description: "All custom lessons created", shortname: 'surveySubmissions', fields: surveySubmissionFields },
    { name: 'Key Metrics Report', description: "All key metrics created", shortname: 'keyMetrics', fields: keyMetricFields },
    { name: 'Participants Report', description: 'Everyone who has signed up, and their participation', shortname: 'participants', fields: participantFields },
  ]
  if (window.location.pathname.includes('/employers')) {
    reportOptions = [
      { name: '' },
      { name: 'Postings Report', description: 'All event, project, and work postings', shortname: 'postings', fields: postingFields },
      { name: 'Applications Report', description: 'All applications to work postings', shortname: 'applications', fields: applicationFields },
      { name: 'Submissions Report', description: 'All project submissions to project postings', shortname: 'projectSubmissions', fields: projectSubmissionFields },
      { name: 'RSVP Report', description: 'All RSVPs to career events and challenges (postings)', shortname: 'rsvps', fields: rsvpFields },
      { name: 'Attendees Report', description: 'All attendees to career events', shortname: 'attendees', fields: attendeeFields },
      { name: 'Assessments Report', description: 'All custom survey / assessment templates created', shortname: 'assessments', fields: assessmentFields },
      { name: 'Benchmarks Report', description: 'All benchmark (i.e., ideal candidate) templates', shortname: 'benchmarks', fields: benchmarkFields },
      // { name: 'Challenge Voting Report', description: 'All votes from challenges', shortname: 'challengeVoting'},
      { name: 'Placements Report', description: 'All placements into work opportunities', shortname: 'placements', fields: placementFields },
      { name: 'Timesheets Report', description: 'All timesheets', shortname: 'timesheets', fields: timesheetFields },
    ]
  }

  reportOptions.sort(function(a, b) {
      const textA = a.name;
      const textB = b.name;
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  return reportOptions
}
