import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Switch from 'react-switch';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import withRouter from '../Functions/WithRouter';
import SubNotificationPreferenes from './NotificationPreferences';
import {convertDateToString} from '../Functions/ConvertDateToString';

const addIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const courseIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png";
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";

class EditStudent extends Component {
    constructor(props) {
        super(props)

        this.state = {

          selectedJob: { postType: 'Individual', title: 'Sample Title'},
          source: 'Student',
          personalityResults: { myersBriggs: null, fiveFactors: { opennessScore: null, conscientiousnessScore: null, extraversionScore: null, agreeablenessScore: null, neuroticismScore: null } },
          interestResults: [{ score: 0},{ score: 0},{ score: 0},{ score: 0},{ score: 0},{ score: 0}],
          ready: true,

          // profileContents: { placeholder: 0 },
          selectedBenchmark: null,

          benchmarkOptions: [],
          trackOptions: [],
          matchIndex: 0,
          scoreOptions: ['0','1','2','3','4','5'],
          gradeOptions: [],
          stageOptions: [],
          enrollmentStatusOptions: [],
          staffMemberOptions: [],
          programOptions: [],
          workshopOptions: [],
          passOptions: ['','Passed','Did Not Pass'],

          caseNoteTagOptions: []

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.fetchAllProfileData = this.fetchAllProfileData.bind(this)
        // this.getEndorsements = this.getEndorsements.bind(this)
        // this.getProjects = this.getProjects.bind(this)
        // this.getExperience = this.getExperience.bind(this)
        // this.calculateMatches = this.calculateMatches.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderInterviews = this.renderInterviews.bind(this)
        this.addInterview = this.addInterview.bind(this)
        this.saveStudent = this.saveStudent.bind(this)

        // this.renderProjects = this.renderProjects.bind(this)
        // this.showGrade = this.showGrade.bind(this)
        this.closeModal = this.closeModal.bind(this)
        // this.renderEndorsements = this.renderEndorsements.bind(this)
        // this.renderSkillTraits = this.renderSkillTraits.bind(this)
        // this.saveFeedback = this.saveFeedback.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.tagClicked = this.tagClicked.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.testMatch = this.testMatch.bind(this)
        this.countCaseNotes = this.countCaseNotes.bind(this)

    }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subEditStudent ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.org !== prevProps.org) {
        this.retrieveData()
      } else if (this.props.student !== prevProps.student) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.activeOrg)

      // log a meeting/session

      const org = this.props.org
      const profileUserId = this.props.profileUserId
      const student = this.props.student

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      if (email) {

        const gradeOptions = ['','A+','A','A-','B+','B','B-','C+','C','C-','D+','D','D-','F']

        this.setState({ emailId: email, cuFirstName, cuLastName, org, student, profileUserId, gradeOptions });

        const orgCodes = [org,'general']

        Axios.get('/api/benchmarks', { params: { orgCodes } })
        .then((response) => {
          console.log('Benchmarks query attempted', response.data)

          if (response.data.success) {
            console.log('benchmark query worked')

            if (response.data.benchmarks.length !== 0) {
              //jobs = response.data.postings

              let trackOptions = []
              let benchmarkOptions = []
              for (let i = 1; i <= response.data.benchmarks.length; i++) {
                trackOptions.push(response.data.benchmarks[i - 1].title.replace('General','').replace('Benchmark', '').trim())
                benchmarkOptions.push(response.data.benchmarks[i - 1])
              }

              let selectedJob = { postType: 'Individual', title: 'Sample Title'}
              if (trackOptions[0]) {
                selectedJob = { postType: 'Individual', title: trackOptions[0]}
              }

              this.setState({ trackOptions, benchmarkOptions, selectedJob })
            }
          }
        })

        Axios.get('/api/programs', { params: { orgCode: org } })
        .then((response) => {
          console.log('Programs query attempted', response.data)

          if (response.data.success) {
            console.log('program query worked')

            if (response.data.programs.length !== 0) {
              //jobs = response.data.postings

              let programOptions = [{ title: "" }]
              for (let i = 1; i <= response.data.programs.length; i++) {
                programOptions.push(response.data.programs[i - 1])
              }

              this.setState({ programOptions })

            }
          }
        })

        Axios.get('/api/postings', { params: { orgCode: org, postType: 'Event', postSubType: 'Workshop', latest: true, resLimit: 250 } })
        .then((response) => {
          console.log('Postings query attempted', response.data)

          if (response.data.success) {
            console.log('posting query worked')

            if (response.data.postings.length !== 0) {
              //jobs = response.data.postings

              let workshopOptions = [{ title: "" }]
              for (let i = 1; i <= response.data.postings.length; i++) {
                workshopOptions.push(response.data.postings[i - 1])
              }

              this.setState({ workshopOptions })

            }
          }
        })

        if (profileUserId && org) {
          Axios.get('/api/applications', { params: { emailId: profileUserId, orgCode: org, isSelectedProgram: true }})
          .then((response) => {
            console.log('Applications query attempted', response.data)

            if (response.data.success) {
              console.log('applications query worked')

              if (response.data.applications.length !== 0) {
                //jobs = response.data.postings

                let programInvolvement = []
                for (let i = 1; i <= response.data.applications.length; i++) {
                  programInvolvement.push(response.data.applications[i - 1])
                }

                this.setState({ programInvolvement })

              }
            }
          })
        }

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const orgURL = response.data.orgInfo.orgURL
            const orgMission = response.data.orgInfo.orgMission

            const orgContactFirstName = response.data.orgInfo.contactFirstName
            const orgContactLastName = response.data.orgInfo.contactLastName
            const orgContactEmail = response.data.orgInfo.contactEmail
            const orgContactTitle = response.data.orgInfo.contactTitle
            const orgProgramName = response.data.orgInfo.orgProgramName

            const caseNoteTagOptions = response.data.orgInfo.caseNoteTagOptions

            this.setState({
                orgName, orgURL, orgMission, orgProgramName,
                orgContactFirstName, orgContactLastName, orgContactTitle, orgContactEmail, caseNoteTagOptions
            });

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        if (org) {
          const roleNames = ['Admin']
          Axios.get('/api/org/members', { params: { orgCode: org, roleNames } })
          .then((response) => {

            if (response.data.success) {
              console.log('Member query worked', response.data);

              if (response.data.members && response.data.members.length > 0) {

                let staffMemberOptions = response.data.members
                if (staffMemberOptions) {
                  staffMemberOptions.unshift({})

                  // if (individualFilters[individualFilters.length - 1].name === 'Assigned Staff') {
                  //   for (let j = 1; j <= staffMemberOptions.length; j++) {
                  //     if (staffMemberOptions[j - 1].email && staffMemberOptions[j - 1].email !== '') {
                  //       console.log('show staff member: ', staffMemberOptions[j - 1].email, individualFilters[individualFilters.length - 1]['options'])
                  //       individualFilters[individualFilters.length - 1]['options'].push(staffMemberOptions[j - 1].email)
                  //     }
                  //   }
                  // }

                  this.setState({ staffMemberOptions })
                }
              }

            } else {
              console.log('no members found', response.data.message)

            }

          }).catch((error) => {
              console.log('Members query did not work', error);
          });
        }

        Axios.get('/api/assessment/questions')
        .then((response) => {
            //console.log('O*Net worked', response.data.body, this);
            console.log('show me response', response.data)
            if ( response.data.success === true ) {
              console.log('o-net query worked')

              let wpQuestions = []
              for (let i = 1; i <= response.data.assessments.workPreferenceQuestions.length; i++) {
                wpQuestions.push({ name: response.data.assessments.workPreferenceQuestions[i - 1].name })
              }

              this.setState({ wpQuestions })

            } else {
              console.log('Assessment question query failed', response.data.message)
            }


        }).catch((error) => {
            console.log('O*NET did not work', error);
        });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            const pathwayOptions = response.data.workOptions[0].functionOptions
            const stageOptions = [''].concat(response.data.workOptions[0].careerSeekerStageOptions)
            const enrollmentStatusOptions = [''].concat(response.data.workOptions[0].enrollmentStatusOptions)
            // exploringCareerPaths, definingGoals, activelyLookingForWork, lookingToChangeEmployers, happilyEmployed

            this.setState({ pathwayOptions, stageOptions, enrollmentStatusOptions })
          }
        }).catch((error) => {
            console.log('O*NET did not work', error);
        });
      }

      this.fetchAllProfileData(profileUserId, org)

    }

    fetchAllProfileData (userId, orgCode) {
      console.log('what is this username value:', userId, orgCode)

      Axios.get('/api/users/profile/details/' + userId)
      .then((response) => {
        if (response.data) {

          console.log('User details fetch worked', response.data)
          if (response.data.success) {

            const profilePictureURL = response.data.user.pictureURL
            const profileFirstName = response.data.user.firstName
            const profileLastName = response.data.user.lastName
            const profileEmail = response.data.user.email
            const profileUsername = response.data.user.username

            const selectedProfile = response.data.user
            // const linkedInURL = response.data.user.linkedInURL
            // const resumeURL = response.data.user.resumeURL
            // const customWebsiteURL = response.data.user.customWebsiteURL
            // const schoolName = response.data.user.school
            // const gradYear = response.data.user.gradYear
            // const academyYear = response.data.user.academyYear
            // const pictureURL = response.data.user.pictureURL
            // const politicalAlignment = response.data.user.politicalAlignment
            // const hometown = response.data.user.hometown
            // const homeCongressionalDistrict = response.data.user.homeCongressionalDistrict

            // const profileContents = response.data.user

            const queriedProfile = true

            let interviews = [{ interviewerName: '', date: null, score: null, program: null, passed: null, workshop: null }]
            let notes = null
            let feedback = null
            let isSubsidyEligible = response.data.user.isSubsidyEligible
            let isWorkforceReady = response.data.user.isWorkforceReady
            let isWorkReadyCertified = response.data.user.isWorkReadyCertified
            let hasWorkPermit = response.data.user.hasWorkPermit
            let workAuthorization = response.data.user.workAuthorization
            let isHighPotential = response.data.user.isHighPotential

            if (response.data.user.evaluations) {
              for (let i = 1; i <= response.data.user.evaluations.length; i++) {
                if (response.data.user.evaluations[i - 1].orgCode === orgCode) {
                  interviews = response.data.user.evaluations[i - 1].interviews
                  notes = response.data.user.evaluations[i - 1].notes
                  feedback = response.data.user.evaluations[i - 1].feedback
                  isSubsidyEligible = response.data.user.evaluations[i - 1].isSubsidyEligible
                  isWorkforceReady = response.data.user.evaluations[i - 1].isWorkforceReady
                  if (orgCode !== 'unite-la') {
                    isWorkReadyCertified = response.data.user.evaluations[i - 1].isWorkReadyCertified
                  }
                }
              }
            }

            let stage = response.data.user.stage
            let enrollmentStatus = response.data.user.enrollmentStatus
            let caseNotes = response.data.user.caseNotes

            let assignedStaffName = null
            if (response.data.user.assignedStaff) {
              const assignedStaff = response.data.user.assignedStaff
              assignedStaffName = assignedStaff.firstName + ' ' + assignedStaff.lastName
            }

            this.setState({
                profileFirstName, profileLastName, profileEmail, profileUsername, profilePictureURL,
                selectedProfile,
                // linkedInURL, resumeURL, customWebsiteURL, schoolName, gradYear, academyYear, pictureURL,
                queriedProfile, interviews, notes, feedback, isSubsidyEligible, isWorkforceReady, isWorkReadyCertified,
                hasWorkPermit,
                workAuthorization, isHighPotential, stage, enrollmentStatus, caseNotes, assignedStaffName
            });

            // this.fetchRemainingProfileData(response.data.user.email, response.data.user);

          } else {
            console.log('there was an error', response.data.message)
          }

        }
      }).catch((error) => {
          console.log('User details fetch did not work', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name.includes('interview')) {
        let interviews = this.state.interviews
        const nameArray = event.target.name.split('|')
        const name = nameArray[1]
        const index = Number(nameArray[2]) - 1
        interviews[index][name] = event.target.value

        let feedback = this.state.feedback
        if (name === 'feedback') {
          feedback = event.target.value
        }
        this.setState({ interviews, feedback })
        console.log('show everything: ', nameArray, name, index, interviews, feedback)
      } else if (event.target.name.includes("caseNotes|")) {
        let caseNotes = this.state.caseNotes

        const nameArray = event.target.name.split('|')
        const name = nameArray[1]
        const index = Number(nameArray[2])

        caseNotes[index][name] = event.target.value

        this.setState({ caseNotes })
      // } else if (event.target.name === 'projectGrade') {
      //   let profileContents = this.state.profileContents
      //   profileContents['projects'][this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['grade'] = event.target.value
      //   this.setState({ profileContents })
      // } else if (event.target.name === 'projectFeedback') {
      //   let profileContents = this.state.profileContents
      //   profileContents['projects'][this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['feedback'] = event.target.value
      //   this.setState({ profileContents })
      // } else if (event.target.name === 'projectIsTransparent') {
      //   let profileContents = this.state.profileContents
      //   profileContents['projects'][this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['isTransparent'] = event.target.value
      //   this.setState({ profileContents })
      // } else if (event.target.name === 'track') {
      //
      //   let trackSelected = event.target.value
      //   let matchIndex = 0
      //   for (let i = 1; i <= this.state.trackOptions.length; i++) {
      //     console.log(i);
      //     if (trackSelected === this.state.trackOptions[i - 1]) {
      //       matchIndex = i - 1
      //     }
      //   }
      //
      //   // let trackBenchmark = null
      //   // for (let k = 1; k <= this.state.benchmarkOptions.length; k++) {
      //   //   // console.log('option: ', this.state.trackOptions[matchIndex]._id, this.state.benchmarkOptions[k - 1]._id)
      //   //   if (this.state.selectedJob.tracks[matchIndex].benchmark._id === this.state.benchmarkOptions[k - 1]._id) {
      //   //     trackBenchmark = this.state.benchmarkOptions[k - 1]
      //   //   }
      //   // }
      //   // this.state.benchmarkOptions[this.state.matchIndex]
      //   console.log('matchIndex: ', matchIndex, this.state.trackOptions)
      //
      //   const selectedJob = { postType: 'Individual', title: trackSelected }
      //
      //   this.setState({ trackSelected, matchIndex, selectedJob })
      //   this.calculateMatches(this.state.profileContents, selectedJob, this.state.benchmarkOptions, this.state.benchmarkOptions[matchIndex])
      } else if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value })
        // this.filterResults(event.target.name,event.target.value)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    filterResults(name,value) {
      console.log('filterResults called')

    }

    countCaseNotes() {
      console.log('countCaseNotes called')

      let countText = ""
      let counter = 0
      if (this.state.caseNotes) {
        for (let i = 1; i <= this.state.caseNotes.length; i++) {
          if ((!this.state.searchString) || (this.state.searchString && this.testMatch(this.state.searchString,this.state.caseNotes[i - 1]))) {
            counter = counter + 1
          }
        }
      }

      if (counter > 0) {
        countText = counter.toString() + " "
      }

      return countText
    }

    testMatch(searchString,item) {
      console.log('testMatch called', searchString)

      if (!searchString) return false; // Handle empty search case

      const lowerSearch = searchString.toLowerCase();

      return Object.values(item).some(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(lowerSearch);
        } else if (Array.isArray(value)) {
          return value.some(item => typeof item === 'string' && item.toLowerCase().includes(lowerSearch));
        }
        return false;
      });
    }

    renderInterviews() {
      console.log('renderInterviews called', this.state.interviews)

      let rows = []

      if (this.state.interviews) {
        for (let i = 1; i <= this.state.interviews.length; i++) {

          const index = i - 1

          let nameName = "interview|interviewerName|" + i
          let dateName = "interview|date|" + i
          let scoreName = "interview|score|" + i
          let programName = "interview|program|" + i
          let passName = "interview|passed|" + i
          let workshopName = "interview|workshop|" + i
          let notesName = "interview|notes|" + i
          let feedbackName = "interview|feedback|" + i

          let interviewerName = ''
          let interviewDate = ''
          let interviewScore = ''
          let interviewProgram = ''
          let interviewPassed = ''
          let interviewWorkshop = ''
          let interviewNotes = ''
          let interviewFeedback = ''

          if (this.state.interviews[i - 1]) {
            if (this.state.interviews[i - 1].interviewerName) {
              interviewerName = this.state.interviews[i - 1].interviewerName
            }
            if (this.state.interviews[i - 1].date) {
              interviewDate = this.state.interviews[i - 1].date
            }
            if (this.state.interviews[i - 1].score) {
              interviewScore = this.state.interviews[i - 1].score
            }
            if (this.state.interviews[i - 1].program) {
              interviewProgram = this.state.interviews[i - 1].program
            }
            if (this.state.interviews[i - 1].passed) {
              interviewPassed = this.state.interviews[i - 1].passed
            }
            if (this.state.interviews[i - 1].workshop) {
              interviewWorkshop = this.state.interviews[i - 1].workshop
            }
            if (this.state.interviews[i - 1].notes) {
              interviewNotes = this.state.interviews[i - 1].notes
            }
            if (this.state.interviews[i - 1].feedback) {
              interviewFeedback = this.state.interviews[i - 1].feedback
            }
          }

          rows.push(
            <div key={i}>
              <div className="row-5">
                <div className="close-button-container-2" >
                  <button type="button" onClick={() => this.deleteItem(index)} className="background-button">
                    <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>

                <div className="left-padding">
                  <div className="highlighted-container">
                    <div className="relative-column-60">
                      <p className="profile-descriptor">#{i} Interviewer Name</p>
                      <input type="text" className="text-field" placeholder="Add the name..." name={nameName} value={interviewerName} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="relative-column-20">
                      <p className="profile-descriptor">Interview Date</p>
                      <input type="date" className="date-field half-bold-text" placeholder="Add a date..." name={dateName} value={interviewDate} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="relative-column-20">
                      <p className="profile-descriptor">Interview Score</p>
                      <select name={scoreName} value={interviewScore} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.scoreOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear"/>

                    {(this.state.org === 'unite-la') && (
                      <div>
                        <div className="row-10 top-margin">
                          <div className="container-left">
                            <p className="profile-descriptor">Program</p>
                            <select name={programName} value={interviewProgram} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.programOptions.map(value =>
                                <option key={value.title} value={value.title}>{value.title}</option>
                              )}
                            </select>
                          </div>
                          <div className="container-right">
                            <p className="profile-descriptor">Passed Interview?</p>
                            <select name={passName} value={interviewPassed} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.passOptions.map(value =>
                                <option key={value} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="row-10 top-margin">
                          <div className="container-left">
                            <p className="profile-descriptor">Workshop</p>
                            <select name={workshopName} value={interviewWorkshop} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.workshopOptions.map(value =>
                                <option key={(value.title) && value.title + " on " + convertDateToString(new Date(value.startDate),'date-2')} value={(value.title) && value.title + " on " + convertDateToString(new Date(value.startDate),'date-2')}>{(value.title) && value.title + " on " + convertDateToString(new Date(value.startDate),'date-2')}</option>
                              )}
                            </select>
                          </div>
                          <div className="container-right">

                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    )}

                    <div className="row-10">
                      <p className="profile-descriptor">Interview Notes</p>
                      <textarea type="text" className="text-field" placeholder="Interview notes..." name={notesName} value={interviewNotes} onChange={(this.formChangeHandler)}></textarea>
                    </div>

                    <div className="row-10">
                      <p className="profile-descriptor">Interview Feedback</p>
                      <textarea type="text" className="text-field" placeholder="Interview feedback..." name={feedbackName} value={interviewFeedback} onChange={(this.formChangeHandler)}></textarea>
                    </div>

                    <div className="clear"/>
                    <div className="half-spacer" />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      }

      return rows
    }

    deleteItem(index,type) {
      console.log('deleteItem called', index,type)

      if (type === 'caseNote') {
        let caseNotes = this.state.caseNotes
        caseNotes.splice(index, 1)
        this.setState({ caseNotes, formHasChanged: true })
      } else {
        let interviews = this.state.interviews
        interviews.splice(index, 1)
        this.setState({ interviews, formHasChanged: true })
      }
    }

    addInterview() {
      console.log('addInterview called')

      let interviews = this.state.interviews
      if (interviews) {
        interviews.push({ interviewerName: '', date: null, score: null, program: '', passed: '', notes: '', feedback: '' })
      } else {
        interviews = [{ interviewerName: '', date: null, score: null, program: '', passed: '', notes: '', feedback: '' }]
      }

      this.setState({ interviews })

    }

    saveStudent() {
      console.log('saveStudent called', this.state.selectedApplication)

      const interviews = this.state.interviews
      const notes = this.state.notes
      const isSubsidyEligible = this.state.isSubsidyEligible
      const isWorkforceReady = this.state.isWorkforceReady
      const isWorkReadyCertified = this.state.isWorkReadyCertified
      const hasWorkPermit = this.state.hasWorkPermit
      const workAuthorization = this.state.workAuthorization
      const isHighPotential = this.state.isHighPotential

      const email = this.state.profileEmail
      const firstName = this.state.profileFirstName
      const lastName = this.state.profileLastName
      const username = this.state.profileUsername
      const pictureURL = this.state.profilePictureURL

      const orgName = this.state.orgName
      const orgContactFirstName = this.state.cuFirstName
      const orgContactLastName = this.state.cuLastName
      const orgContactEmail = this.state.emailId
      const orgContactTitle = this.state.orgContactTitle
      const orgProgramName = this.state.orgProgramName
      let isPlacementPartner = false
      if (this.state.selectedJob.placementPartners && this.state.selectedJob.placementPartners.includes(this.state.org)) {
        isPlacementPartner = true
      }

      const feedback = this.state.feedback
      const stage = this.state.stage
      const enrollmentStatus = this.state.enrollmentStatus
      const caseNotes = this.state.caseNotes
      let assignedStaff = this.state.assignedStaff
      if (this.state.staffMemberOptions && this.state.staffMemberOptions.length > 0) {
        for (let i = 1; i <= this.state.staffMemberOptions.length; i++) {
          const fullName = this.state.staffMemberOptions[i - 1].firstName + ' ' + this.state.staffMemberOptions[i - 1].lastName
          if (this.state.assignedStaffName && fullName === this.state.assignedStaffName) {
            assignedStaff = this.state.staffMemberOptions[i - 1]
          }
        }
      }

      const orgCode = this.state.org
      const updatedAt = new Date()

      Axios.post('/api/members/evaluations', { //sessions: sessions
        interviews, notes, isSubsidyEligible, isWorkforceReady, isWorkReadyCertified, hasWorkPermit,
        workAuthorization, isHighPotential,
        orgName, orgContactEmail,
        firstName, lastName, email, username, pictureURL,
        orgContactFirstName, orgContactLastName, orgContactTitle, orgProgramName, isPlacementPartner, orgCode,
        feedback, stage, enrollmentStatus, caseNotes, assignedStaff,
        updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Student save worked', response.data);
          this.setState({
            serverPostSuccess: true,
            serverSuccessMessage: 'Student updated successfully!'
          })

        } else {
          console.error('there was an error updating the student');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('Student save did not work', error);
      });
    }

    closeModal() {
      console.log('closeModal in projects: ', this.state.showProjectDetail)

      this.setState({ modalIsOpen: false, showGrade: false, showProjectDetail: false, showSubEndorsementDetails: false });

    }

    addItem(type) {
      console.log('addItem called')

      let caseNotes = this.state.caseNotes
      if (caseNotes) {
        caseNotes.unshift({ caseDate: '', workerName: '', notes: '' })
        // console.log('is it really creating a copy: ', caseNotes)
      } else {
        caseNotes = [{ caseDate: null, workerName: null, notes: null }]
      }

      this.setState({ caseNotes })

    }

    tagClicked(itemClicked, cIndex) {
      console.log('tagClicked called', this.state.caseNotes.tags)

      let caseNotes = this.state.caseNotes
      if (!caseNotes[cIndex].tags) {
        caseNotes[cIndex]['tags'] = [itemClicked]
      } else {

        if (!caseNotes[cIndex].tags.includes(itemClicked)) {

          caseNotes[cIndex]['tags'].push(itemClicked)

        } else {

          const index = caseNotes[cIndex].tags.indexOf(itemClicked)
          caseNotes[cIndex]['tags'].splice(index,1)
        }
      }

      this.setState({ caseNotes })
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    render() {
        console.log('renderEditStudent was called')

        let pathname = '/organizations/' + this.state.org + '/members/' + this.state.profileUsername
        let passedState = { member: this.state.selectedProfile }
        if (this.props.roleName === 'Admin') {
          pathname = '/organizations/' + this.state.org + '/members/' + this.state.profileUsername
        } else if (this.props.path && this.props.path.includes('/advisor')) {
          pathname = '/advisor/advisees/' + this.state.profileEmail
          passedState = { advisee: this.state.selectedProfile, favorites: this.state.favorites }
        }

        return (
            <div>
              <div className="standard-container-2">
                <div className="row-10">
                  <div className="full-width center-text center-horizontally">
                    <Link className="background-button" to={pathname} state={passedState}>
                      <img src={this.state.profilePictureURL ? this.state.profilePictureURL : profileIconDark} alt="GC" className="profile-image-medium center-horizontally"/>
                      <p className="heading-text-3 top-margin">Evaluate {this.state.profileFirstName} {this.state.profileLastName}</p>
                      {(this.state.profileEmail) && (
                        <p className="description-text-1 top-margin-5">({this.state.profileEmail})</p>
                      )}
                      <p className="description-text-3 top-margin-5">[Click to View Full Profile]</p>

                    </Link>
                  </div>

                  <div className="row-10">
                    <div className="row-15 top-margin">
                      <p className="heading-text-3">Configurations & Approvals</p>
                    </div>

                    <div className="row-15">
                      <div className="container-left">
                        <p className="row-10 normal-weight">Assigned Case Manager</p>
                        <select name="assignedStaffName" className="dropdown" value={this.state.assignedStaffName} onChange={this.formChangeHandler}>
                          {this.state.staffMemberOptions.map(value => <option key={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""} value={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}>{(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}</option>)}
                        </select>
                      </div>
                      <div className="container-right">
                        <p className="row-10 normal-weight">Enrollment Status</p>
                        <select name="enrollmentStatus" className="dropdown" value={this.state.enrollmentStatus} onChange={this.formChangeHandler}>
                          {this.state.enrollmentStatusOptions.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>

                    {/*
                    <div className="row-15">
                      <div className="container-left">
                        <p className="row-10 normal-weight">Stage in Career Journey</p>
                        <select name="stage" className="dropdown" value={this.state.stage} onChange={this.formChangeHandler}>
                          {this.state.stageOptions.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                      </div>
                      <div className="container-right">
                        <p className="row-10 normal-weight">Assigned Case Manager</p>
                        <select name="assignedStaffName" className="dropdown" value={this.state.assignedStaffName} onChange={this.formChangeHandler}>
                          {this.state.staffMemberOptions.map(value => <option key={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""} value={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}>{(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}</option>)}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-15">
                      <div className="container-left">
                        <p className="row-10 normal-weight">Enrollment Status</p>
                        <select name="enrollmentStatus" className="dropdown" value={this.state.enrollmentStatus} onChange={this.formChangeHandler}>
                          {this.state.enrollmentStatusOptions.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>*/}

                    <div className="row-15">
                      <div className="container-left">
                        <p className="row-10 normal-weight">WIOA Eligible</p>
                        <Switch
                          onChange={(change) => this.setState({ isSubsidyEligible: change, applicationFormHasChanged: true })}
                          checked={this.state.isSubsidyEligible}
                          id="normal-switch"
                        />
                      </div>
                      <div className="container-right">
                        <div className="float-left">
                          <p className="row-10 normal-weight">Passed Workforce Readiness Courses</p>
                          <Switch
                            onChange={(change) => this.setState({ isWorkforceReady: change, applicationFormHasChanged: true })}
                            checked={this.state.isWorkforceReady}
                            id="normal-switch"
                          />
                        </div>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-15">
                      <div className="container-left" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This should be adjusted by marking this user, the attendee of a posted event, as approved, or bulk uploading from settings.">
                        <p className="row-10 normal-weight wash-out-2">Is Work-Ready Certified</p>
                        <Switch
                          onChange={(change) => this.setState({ isWorkReadyCertified: change, applicationFormHasChanged: true })}
                          checked={this.state.isWorkReadyCertified}
                          id="normal-switch"
                          disabled={true}
                        />

                      </div>
                      <div className="container-right">
                        <div className="float-left">
                          <p className="row-10 normal-weight">Has Work Permit</p>
                          <Switch
                            onChange={(change) => this.setState({ hasWorkPermit: change, applicationFormHasChanged: true })}
                            checked={this.state.hasWorkPermit}
                            id="normal-switch"
                          />
                        </div>
                      </div>
                      <div className="clear" />
                      <ReactTooltip id="tooltip-placeholder-id" />
                    </div>

                    <div className="row-15">
                      <div className="container-left">
                        <p className="row-10 normal-weight">Is Authorized to Work in the U.S.</p>
                        <Switch
                          onChange={(change) => this.setState({ workAuthorization: change, applicationFormHasChanged: true })}
                          checked={this.state.workAuthorization}
                          id="normal-switch"
                        />
                      </div>
                      {/*
                      <div className="container-right">
                        <div className="float-left">
                          <p className="row-10 normal-weight">High Potential/Priority</p>
                          <Switch
                            onChange={(change) => this.setState({ isHighPotential: change, applicationFormHasChanged: true })}
                            checked={this.state.isHighPotential}
                            id="normal-switch"
                          />
                        </div>
                      </div>*/}
                      <div className="clear" />
                    </div>
                  </div>

                  <hr className="vertical-margin-20" />

                  <div className="row-10">
                    <div className="row-15">
                      <p className="heading-text-3">Notes & Interviews</p>
                    </div>

                    <div className="row-15">
                      <p className="row-10 normal-weight">Internal Notes (Overall)</p>
                      <textarea type="text" className="text-field" placeholder="Add notes on this candidate..." name="notes" value={this.state.notes} onChange={(this.formChangeHandler)}></textarea>
                    </div>

                    <div className="row-15">
                      <div className="float-left">
                        <p className="row-10 normal-weight">{this.countCaseNotes()}Case Notes</p>
                      </div>
                      <div className="float-left left-padding top-padding-12">
                        <button onClick={() => this.addItem('case note')} className="background-button">
                          <div className="padding-5 standard-border rounded-corners">
                            <img src={addIcon} alt="GC" className="image-auto-10" />
                          </div>
                        </button>
                      </div>
                      <div className="clear"/>

                      <div className="row-10">
                        <div>
                          <div className="standard-border display-inline row-3 left-padding-5 right-padding-5 full-width white-background">
                            <div className="fixed-column-40 top-padding-3 left-padding-3">
                              <img src={searchIcon} alt="GC" className="image-auto-28 padding-5" />
                            </div>
                            <div className="calc-column-offset-100">
                              <input type="text" className="text-field clear-border description-text-1" placeholder={"Search case notes..."} name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                            </div>
                            {/*
                            <div className="fixed-column-60 top-padding-8 left-padding-3 top-margin-negative-3">
                              <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                                <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                              </button>
                            </div>*/}
                          </div>
                        </div>
                      </div>

                      {(this.state.caseNotes && this.state.caseNotes.length > 0) && (
                        <div>
                          {this.state.caseNotes.map((value, optionIndex) =>
                            <div key={value}>
                              {((!this.state.searchString) || (this.state.searchString && this.testMatch(this.state.searchString, value))) && (
                                <div className="top-margin">
                                  <div className="close-button-container-2" >
                                    <button type="button" onClick={() => this.deleteItem(optionIndex,'caseNote')} className="background-button">
                                      <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                                    </button>
                                  </div>
                                  <div className="standard-border padding-20 left-margin">
                                    <div>
                                      <div className="container-left">
                                        <p className="profile-descriptor">Date</p>
                                        <input type="date" className="date-field half-bold-text" placeholder="Add a date..." name={"caseNotes|caseDate|" + optionIndex} value={value.caseDate} onChange={this.formChangeHandler}></input>
                                      </div>
                                      <div className="container-right">
                                        <p className="profile-descriptor">Staff Name</p>
                                        <select name={"caseNotes|workerName|" + optionIndex} className="dropdown" value={value.workerName} onChange={this.formChangeHandler}>
                                          {this.state.staffMemberOptions.map(value => <option key={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""} value={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}>{(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}</option>)}
                                        </select>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    {(this.state.org === 'unite-la') && (
                                      <div>
                                        <div className="row-10 top-margin">
                                          <div className="container-left">
                                            <p className="profile-descriptor">Title</p>
                                            <input type="text" className="text-field" placeholder="Add the title..." name={"caseNotes|title|" + optionIndex} value={value.title} onChange={this.formChangeHandler}></input>
                                          </div>
                                          <div className="container-right">
                                            <p className="profile-descriptor">Program</p>
                                            <select name={"caseNotes|program|" + optionIndex} className="dropdown" value={value.program} onChange={this.formChangeHandler}>
                                              {this.state.programOptions.map(value => <option key={(value && value.title) ? value.title : ""} value={(value && value.title) ? value.title : ""}>{(value && value.title) ? value.title : ""}</option>)}
                                            </select>
                                          </div>
                                          <div className="clear" />
                                        </div>

                                        <div className="row-10 top-margin">
                                          <p className="profile-descriptor">Tag this Case Note</p>

                                          {(this.state.caseNoteTagOptions && this.state.caseNoteTagOptions.length > 0) && (
                                            <div>
                                              {this.state.caseNoteTagOptions.map((value2, optionIndex2) =>
                                                <div key={value2} className="float-left right-margin-15 top-margin-5 bottom-margin">
                                                  <div>
                                                    {console.log('why: ', value.tags)}

                                                    <button className={(value.tags && value.tags.includes(value2)) ? "background-button slightly-rounded-corners cta-background-color row-10 horizontal-padding-5" : "background-button slightly-rounded-corners light-background row-10 horizontal-padding-5"} onClick={() => this.tagClicked(value2,optionIndex)}>
                                                      <div>
                                                        <p className="description-text-1">{value2}</p>
                                                      </div>
                                                    </button>
                                                  </div>
                                                </div>
                                              )}
                                              <div className="clear" />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}

                                    <div className="top-margin">
                                      <div>
                                        <p className="profile-descriptor">Notes</p>
                                      </div>

                                      <textarea type="text" className="text-field" placeholder="Add notes on this candidate..." name={"caseNotes|notes|" + optionIndex} value={value.notes} onChange={(this.formChangeHandler)}></textarea>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="clear"/>
                    </div>

                    <div className="row-15">
                      <div className="float-left">
                        <p className="row-10 normal-weight">Interview Scores</p>
                      </div>
                      <div className="float-left left-padding top-padding-12">
                        <button onClick={() => this.addInterview()} className="background-button">
                          <div className="padding-5 standard-border rounded-corners">
                            <img src={addIcon} alt="GC" className="image-auto-10" />
                          </div>
                        </button>
                      </div>
                      <div className="clear"/>

                      <div className="spacer" />
                      {this.renderInterviews()}

                      <div className="clear"/>
                    </div>
                  </div>

                  {(this.state.org === 'unite-la') && (
                    <div className="row-30">
                      <hr className="vertical-margin-20" />

                      <div className="row-15">
                        <div className="row-15">
                          <p className="heading-text-3">Program Involvement</p>
                        </div>
                      </div>

                      {(this.state.programInvolvement && this.state.programInvolvement.length > 0) ? (
                        <div>
                          {this.state.programInvolvement.map((item, optionIndex) =>
                            <div key={item} className="row-15">
                              <Link classsName="background-button" to={"/organizations/" + this.state.org + "/postings/" + item.postingId}>
                                <div className="fixed-column-70">
                                  <img src={courseIconDark} alt="GC" className="image-40-fit" />
                                </div>
                                <div className="calc-column-offset-110">
                                  <p>{item.postingTitle}</p>
                                  <div className="top-margin-7">
                                    <p className="description-text-2">{item.stage}</p>
                                  </div>
                                </div>
                                <div className="fixed-column-40">
                                  <img src={arrowIndicatorIcon} alt="GC" className="image-20-fit" />
                                </div>
                                <div className="clear" />
                              </Link>
                              <div>
                              </div>

                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="row-10">
                          <p className="error-color">{this.state.profileFirstName} {this.state.profileLastName} has not been involved in any programs.</p>
                        </div>
                      )}
                    </div>
                  )}

                  <hr className="vertical-margin-20" />

                  <div className="row-10">
                    <div className="row-15">
                      <p className="heading-text-3">Notification Preferences</p>
                    </div>

                    {(this.props.profileUserId) && (
                      <SubNotificationPreferenes history={this.props.navigate} emailId={this.props.profileUserId} notificationType={null}/>
                    )}
                  </div>



                  <div className="row-20">
                    <button className="btn btn-primary" onClick={() => this.saveStudent()}>Save Changes</button>
                  </div>

                  { (this.state.checklistErrorMessage!== '') && <p className="error-color description-text-2 bottom-margin-20">{this.state.checklistErrorMessage}</p> }
                  { (this.state.serverPostSuccess) ? (
                    <div>
                      <p className="cta-color description-text-2 bottom-margin-20">{this.state.serverSuccessMessage}</p>
                    </div>
                  ) : (
                    <div>
                      <p className="error-color description-text-2 bottom-margin-20">{this.state.serverErrorMessage}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

        )
    }
}

export default withRouter(EditStudent);
