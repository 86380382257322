import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import TopNavigation from './TopNavigation';
import Footer from './Footer';
import Axios from 'axios';
import Modal from 'react-modal';
import ReactGA from 'react-ga4';

import SubCourses from '../components/Subcomponents/CoursesTwo';
import SubJoinNewsletter from './Common/JoinNewsletter';

import {convertDateToString} from '../components/Functions/ConvertDateToString';

const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const mentorIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-dark.png"
const intermediaryIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/intermediary-icon-dark.png"
const individualIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png"
const teacherIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/teacher-icon-dark.png";
const employerIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon-dark.png";
const exampleInternMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";
const exampleMentorMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-mentor-match.png";
const gcFrontImage = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/gc-front-image.png";
const orangePlayButton = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/orange-play-button.png";
const testimonialBackground1 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-1.png";
const quote1 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-1.png";
const testimonialBackground2 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-2.png";
const quote2 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-2.png";
const testimonialBackground3 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-3.png";
const quote3 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-3.png";
const testimonialBackground4 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-4.png";
const quote4 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-4.png";
const arrowBulletPoint = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-bullet-point.png";
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";
const lausdLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_11.jpg";
const uniteLALogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_12.png";
const dpscdLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_14.png";
const blockKnowledgeLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_15.png";
const profileIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png";

class FrontPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          auth: false,
          showCareerSeekerTestimonials: false,
          showIntermediaries: true,
          showAIVideo: true
        }

        this.closeModal = this.closeModal.bind(this)
        this.closeHeader = this.closeHeader.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.renderPosts = this.renderPosts.bind(this)

    }

    componentDidMount() {
      console.log('front page just mounted')
      document.body.style.backgroundColor = "#fff";

      window.scrollTo(0, 0)

      const category = 'Acquisition';
      const action = 'homeLandingPageVisits'

      ReactGA.initialize([{ trackingId: "376007762" }]);
      ReactGA.event({ category, action, label: action });

      const assistantBenefits = [
        'Explore career paths','Explore education options','Make financial plans','Design SMART goals','Get strategies on goals', 'Create resumes', 'Create cover letters', 'Complete mock interviews', 'Complete mock internships','Write bios','Generate business ideas','Automate tasks'
      ]
      this.setState({ assistantBenefits })

      Axios.get('/api/blog-posts', { params: { resLimit: 5, skipFuturePosts: true }})
      .then((response) => {
        console.log('Blog post query attempted', response.data);

        if (response.data.success) {
          console.log('blog post query worked')

          if (response.data.posts.length !== 0) {
            //jobs = response.data.postings
            let posts = response.data.posts

            this.setState({ posts });
          }

        } else {
          console.log('Blog posts query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Blog posts query did not work for some reason', error);
      });

      // Axios.get('/api/goal-plan-cron-job', { params: { email: 'creightontaylor+7@gmail.com' }})
      // .then((response) => {
      //   console.log('Profile complete query attempted', response.data);
      //
      //   if (response.data.success) {
      //     console.log('profile complete query worked')
      //
      //   } else {
      //     console.log('Profile complete query did not work', response.data.message)
      //   }
      //
      // }).catch((error) => {
      //     console.log('Profile complete query did not work for some reason', error);
      // });
    }

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    formChangeHandler = event => {

      console.log('show data: ', event.target.name)
      if (event.target.name === 'firstName') {
        this.setState({ firstName: event.target.value })
      } else if (event.target.name === 'lastName') {
        this.setState({ lastName: event.target.value })
      } else if (event.target.name === 'email') {
        this.setState({ email: event.target.value })
      } else if (event.target.name === 'jobTitle') {
        this.setState({ jobTitle: event.target.value })
      } else if (event.target.name === 'employerName') {
        this.setState({ employerName: event.target.value })
      }
    }

    handleSubmit() {
      console.log('handleSubmit called')

      this.setState({ isSaving: true })

      if (!this.state.firstName || this.state.firstName === '') {
        this.setState({ serverErrorMessage: 'Please enter your first name'})
      } else if (!this.state.lastName || this.state.lastName === '') {
        this.setState({ serverErrorMessage: 'Please enter your last name'})
      } else if (!this.state.email || this.state.email === '') {
        this.setState({ serverErrorMessage: 'Please enter your email'})
      } else if (!this.state.email.includes('@')) {
        this.setState({ serverErrorMessage: 'Please enter a valid email'})
      } else if (!this.state.jobTitle || this.state.jobTitle === '') {
        this.setState({ serverErrorMessage: 'Please enter your job title'})
      } else if (!this.state.employerName || this.state.employerName === '') {
        this.setState({ serverErrorMessage: 'Please enter your employer name or school'})
      } else {
        // send link

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const email = this.state.email
        const jobTitle = this.state.jobTitle
        const employerName = this.state.employerName

        // reason, title, materialsLink
        const reason = "Access Materials"
        const title = "How to Optimize Work-Based Learning After COVID-19"
        const materialsLink = window.location.protocol + "//" + window.location.host + "/whitepapers/how-to-optimize-wbl"

        console.log('about to share')
        Axios.post('/api/share-materials', {
          firstName, lastName, email, jobTitle, employerName, reason, title, materialsLink
        })
        .then((response) => {
          console.log('about to share 2')
          if (response.data.success) {
            //save values
            console.log('Sent materials!', response.data);

            this.setState({
              showHeaderNotification: false, isSaving: false, serverSuccessMessage: "Check your email!",
              firstName: '', lastName: '', email: '', jobTitle: '', employerName: ''
            })

            localStorage.setItem('showHeaderNotification', 'false')


          } else {
            console.error('There was an error sending materials');
            this.setState({
              serverErrorMessage: response.data.message, isSaving: false
            })
          }
        }).catch((error) => {
            console.log('There was an error sending materials', error);
            this.setState({ serverErrorMessage: error, isSaving: false })
        });
      }

    }

    closeHeader() {
      console.log('closeHeader called')

      this.setState({ showHeaderNotification: false })
      localStorage.setItem('showHeaderNotification', 'false')
    }

    renderPosts() {
      console.log('renderPosts called')

      let rows = []

      if (this.state.posts) {
        for (let i = 1; i <= this.state.posts.length; i++) {

          // const index = i - 1
          const item = this.state.posts[i - 1]
          const urlSuffix = item.slug

          let subpath = 'blog'
          if (window.location.pathname.includes('/whitepapers')) {
            subpath = 'whitepapers'
          }

          rows.push(
            <div key={i}>
              <div className="top-margin-30">
                <Link className="secret-link full-width" to={'/' + subpath + '/' + urlSuffix} state={{ selectedPost: item }}>
                  <div className="calc-column-offset-110">
                    <p className="description-text-1 description-text-color">{convertDateToString(new Date(item.createdAt),'date-2')}</p>
                    <h3>{item.title}</h3>

                  </div>
                  <div className="fixed-column-80 top-margin">
                    <p className="description-text-2 description-text-color right-text">[{item.category}]</p>
                  </div>
                  <div className="fixed-column-30 top-margin">
                    <img src={arrowIndicatorIcon} alt="GC" className="image-auto-18 pin-right" />
                  </div>
                  <div className="clear" />
                </Link>

                <div className="spacer" /><div className="spacer" />
                <hr />
              </div>
            </div>
          )
        }
      }

      return rows
    }

    render() {

        return (
            <div>
              {(this.state.showHeaderNotification) && (
                <div className="washed-error-background-color row-30">
                  <div className="container-left">
                    <p className="full-width right-text description-text-2">Read Our New Whitepaper</p>
                    <p className="full-width right-text">"How to Optimize Work-Based Learning After COVID-19"</p>
                  </div>
                  <div className="container-left">
                    <div className="calc-column-offset-40 left-padding-30">
                      <button className="btn btn-squarish-dark" onClick={() => this.setState({ modalIsOpen: true, showNewPost: true, showMentorMatch: false, showInternMatch: false })}>Get It Now</button>
                    </div>
                    <div className="fixed-column-40">
                      <a onClick={() => this.closeHeader()}>
                        <img src={closeIcon} alt="GC" className="image-auto-15" />
                      </a>
                    </div>
                    <div className="clear"/>
                  </div>
                  <div className="clear" />
                </div>
              )}

              <TopNavigation currentPage={"frontPage"}/>

              <header className="front-page-header">
                  <div className={(this.state.showHeaderNotification) ? "hero-text-box-noti" : "hero-text-box"}>
                    <div className="top-margin-5-percent">
                      <div className="super-spacer" /><div className="spacer" />
                      <div className="container-left">
                        <h1 className="white-text">The Holistic A.I. Work-Based Learning Platform</h1>
                        {/*<h1 className="white-text">Charting Your Future with Human and AI Expertise</h1>*/}
                        {/*
                        <Link to="/about" className="background-button">
                          <div className="row-20">
                            <div className="fixed-column-90">
                              <img className="image-auto-70" alt="GC" src={orangePlayButton} />
                            </div>
                            <div className="calc-column-offset-90 top-padding-30">
                              <p className="cta-color heading-text-4">OUR VIDEO</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </Link>*/}

                        <div className="row-20">
                          <p className="cta-color heading-text-4">From career exploration to career training to career placement, we've got you covered.</p>
                        </div>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <Link className="btn btn-full" to={'/join'}>Get Started</Link>
                        <Link className="btn btn-secondary white-background" to={'/contact'} state={{ reason: "Guided Compass to Work with My Org / School" }}>Contact Us</Link>

                      </div>
                      {(window.innerWidth > 768) && (
                        <div className="container-right top-padding-30 relative-position">
                          <img className="image-auto-500 left-padding-30-percent" alt="GC" src={gcFrontImage} />
                          <Link className="background-button absolute-position top-quadrant" to={'/career-seekers'} />
                          <Link className="background-button absolute-position left-quadrant" to={'/intermediaries'} />
                          <Link className="background-button absolute-position right-quadrant" to={'/intermediaries'} />
                          <Link className="background-button absolute-position bottom-quadrant" to={'/employers'} />
                        </div>
                      )}

                      <div className="clear" />
                    </div>
                  </div>
                  <div className="clear"/>
              </header>

              <section className="section-features">
                <div className="row">
                  <h2>What Is Guided Compass?</h2>
                  <div className="spacer" /><div className="spacer" />
                </div>

                <div className="row">
                  <p className="full-width center-text heading-text-4">Guided Compass is an AI-powered career development software platform for individuals and career programs that acts as an end-to-end co-pilot and community.</p>
                </div>

                <div className="row center-text top-margin-40 bottom-margin-40">
                  <div className={(window.innerWidth > 768) ? "relative-column-33" : "full-width"}>
                    <p className="heading-text-1">50,000+</p>
                    <p className="heading-text-3">career-seekers</p>
                  </div>
                  <div className={(window.innerWidth > 768) ? "relative-column-33" : "full-width"}>
                    <p className="heading-text-1">3,000+</p>
                    <p className="heading-text-3">educators & coordinators</p>
                  </div>
                  <div className={(window.innerWidth > 768) ? "relative-column-33" : "full-width"}>
                    <p className="heading-text-1">400+</p>
                    <p className="heading-text-3">employers</p>
                  </div>
                  <div className="clear" />
                </div>

                <div className="spacer" /><div className="spacer" />
              </section>

              <section className="section-workforce">
                  <p className="heading-text-2 full-width center-text">Your source for career exploration, prep, and advancement.</p>
              </section>

              <section className="section-features">
                <div className="row">
                  <h2>Beneficiaries</h2>
                  <div className="spacer" />
                  <div className="spacer" />
                </div>

                <div className="row">
                  <div className={(window.innerWidth > 768) ? (this.state.showIntermediaries) ? "relative-column-33 dark-border" : "relative-column-20 dark-border" : "full-width"}>
                    <div className="full-width nonary-background height-5" />

                    <div className="row-50 horizontal-padding-4">
                      <img className="image-100-fit center-horizontally" alt="GC" src={individualIconDark}/>
                      <p className="heading-text-2 center-text top-margin-25">Individuals</p>
                      <p className="center-text top-margin bold-text keep-line-breaks">Your personal Jarvis</p>
                      <p className="center-text top-margin description-text-color">Explore paths, clarify goals, track progress, prep for careers, and gain advocacy.</p>

                      <div className="center-text top-margin-30">
                        <Link className="btn btn-primary primary-background white-text" to={'/career-seekers'}>Learn More</Link>
                      </div>
                    </div>
                  </div>
                  {(this.state.showIntermediaries) ? (
                    <div className={(window.innerWidth > 768) ? "relative-column-33 dark-border" : "full-width"}>
                      <div className="full-width twelve-background height-5" />

                      <div className="row-50 horizontal-padding-4">
                        <img className="image-100-fit center-horizontally" alt="GC" src={intermediaryIconDark}/>
                        <p className="heading-text-2 center-text top-margin-25">Intermediaries</p>
                        <p className="center-text top-margin bold-text">Improve lives and track outcomes</p>
                        <p className="center-text top-margin description-text-color">Facilitate end-to-end work-based learning, track outcomes, and generate reports.</p>

                        <div className="center-text top-margin-30">
                          <Link className="btn btn-primary primary-background white-text" to={'/intermediaries'}>Learn More</Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span>
                      <div className={(window.innerWidth > 768) ? "relative-column-20 dark-border" : "full-width"}>
                        <div className="full-width primary-background height-5" />

                        <div className="row-50 horizontal-padding-4">
                          <img className="image-100-fit center-horizontally" alt="GC" src={teacherIconDark}/>
                          <p className="heading-text-2 center-text top-margin-25">Educators</p>
                          <p className="center-text top-margin bold-text">Save time and help students</p>
                          <p className="center-text top-margin description-text-color">Detect plagiarism, automatically assign tasks, and automatically grade projects.</p>

                          <div className="center-text top-margin-30">
                            <Link className="btn btn-primary primary-background white-text" to={'/educators'}>Learn More</Link>
                          </div>
                        </div>
                      </div>
                      <div className={(window.innerWidth > 768) ? "relative-column-20 dark-border" : "full-width"}>
                        <div className="full-width twelve-background height-5" />

                        <div className="row-50 horizontal-padding-4">
                          <img className="image-100-fit center-horizontally" alt="GC" src={intermediaryIconDark}/>
                          <p className="heading-text-2 center-text top-margin-25">Workforce</p>
                          <p className="center-text top-margin bold-text">Coordinate participants and track outcomes</p>
                          <p className="center-text top-margin description-text-color">Implement custom career exploration, career preparation, and advocacy communities.</p>

                          <div className="center-text top-margin-30">
                            <Link className="btn btn-primary primary-background white-text" to={'/workforce'}>Learn More</Link>
                          </div>
                        </div>
                      </div>
                      <div className={(window.innerWidth > 768) ? "relative-column-20 dark-border" : "full-width"}>
                        <div className="full-width denary-background height-5" />

                        <div className="row-50 horizontal-padding-4">
                          <img className="image-100-fit center-horizontally" alt="GC" src={mentorIconDark}/>
                          <p className="heading-text-2 center-text top-margin-25">Mentors</p>
                          <p className="center-text top-margin bold-text keep-line-breaks">Track mentee{'\n'}progress</p>
                          <p className="center-text top-margin description-text-color">Identify mentee goals, track progress, provide feedback, and advocate for mentee opportunities.</p>

                          <div className="center-text top-margin-30">
                            <Link className="btn btn-primary primary-background white-text" to={'/mentors'}>Learn More</Link>
                          </div>
                        </div>
                      </div>
                    </span>
                  )}

                  <div className={(window.innerWidth > 768) ? (this.state.showIntermediaries) ? "relative-column-33 dark-border" : "relative-column-20 dark-border" : "full-width"}>
                    <div className="full-width thirteen-background height-5" />

                    <div className="row-50 horizontal-padding-4">
                      <img className="image-100-fit center-horizontally" alt="GC" src={employerIconDark}/>
                      <p className="heading-text-2 center-text top-margin-25">Employers</p>
                      <p className="center-text top-margin bold-text">Build tailored talent pipelines</p>
                      <p className="center-text top-margin description-text-color">Post work opportunities, discover diverse talent, and engage custom talent pipelines.</p>

                      <div className="center-text top-margin-30">
                        <Link className="btn btn-primary primary-background white-text" to={'/employers'}>Learn More</Link>
                      </div>
                    </div>

                  </div>
                  <div className="clear" />
                </div>
              </section>

              <div className="row-10 flex-container-mobile-column box orange-background horizontal-padding-3">
                <div className="flex-20 box top-padding-5">
                  <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/orgLogos/yearup_logo_dark.png'} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                </div>
                <div className="flex-20 box">
                  <img src={uniteLALogo} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                </div>
                <div className="flex-20 box">
                  <img src={lausdLogo} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                </div>
                <div className="flex-20 box">
                  <img src={dpscdLogo} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                </div>
                <div className="flex-20 box">
                  <img src={blockKnowledgeLogo} alt="GC" className="image-auto-60 center-horizontally wash-out-2" />
                </div>
              </div>

              <section className="section-features primary-background-absurdly-light">
                  <div className="row">
                    <h2>Hear From Our Clients</h2>
                  </div>
                  {/*
                  <div className="row top-margin-60">
                    <div className="container-left">
                      <div className="white-background relative-position">
                        <div className="padding-20">
                          <img src={quote3} alt="GC" className="image-auto-30" />
                        </div>

                        <div className="bottom-padding-20 horizontal-padding-5">
                          <p className="heading-text-5">“Guided Compass helped us define our ideal entry-level candidates, before recommending some great candidates for internships and full-time. We hired 5 people for our marketing and content teams through the platform. Our hires are highly engaged and very strong matches for our companies.”</p>
                        </div>

                        <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                          <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/roger_smith.jpg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                          <div className="white-text bottom-padding-10-percent">
                            <p className="top-padding">Roger Smith</p>
                            <p className="description-text-2">President</p>
                            <p className="description-text-2">All Sports Television Network</p>
                          </div>
                        </div>

                        <img src={testimonialBackground3} alt="GC" className="image-full-auto" />
                      </div>
                    </div>
                    <div className="container-right">
                      <div className="white-background relative-position">
                        <div className="padding-20">
                          <img src={quote4} alt="GC" className="image-auto-30" />
                        </div>

                        <div className="bottom-padding horizontal-padding-5">
                          <p className="heading-text-5">“Guided Compass really helped me narrow down my search for diverse candidates that are a good fit for our firm. After a few events, we hired two interns that we continue to work with today. Creating the benchmarks alone, seemed to be helpful to the diverse communities Guided Compass works in.”</p>
                        </div>

                        <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                          <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/sebastian_de_vivo.jpg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                          <div className="white-text bottom-padding-10-percent">
                            <p className="top-padding">Sebastian De Vivo</p>
                            <p className="description-text-2">Co-Founder & CEO</p>
                            <p className="description-text-2">List Ventures</p>
                          </div>
                        </div>

                        <img src={testimonialBackground4} alt="GC" className="image-full-auto" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>*/}

                  <div className="row top-margin-60">
                    <div className="container-left">
                      <div className="white-background relative-position">
                        <div className="padding-20">
                          <img src={quote3} alt="GC" className="image-auto-30" />
                        </div>

                        <div className="bottom-padding-20 horizontal-padding-5">
                          <p className="heading-text-5">“I've met with Guided Compass weekly over the last few years to create a custom work-based learning portal for both Bixel Exchange and UNITE-LA. We've missed few meetings because we are all passionate about the space. I'm constantly impressed with the speed at which new, quality features are added to help us and the opportunity youth we serve. Many new features were conceived by their team, going over and above our contract. From big-picture ideas to improving processes to fixing bugs, the Guided Compass team has truly been a partner in our mission. I would highly recommend Guided Compass for any career development or work-based learning program.”</p>
                        </div>

                        <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                          <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/jaideep_upadhyay.jpeg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                          <div className="white-text bottom-padding-10-percent">
                            <p className="top-padding">Jaideep Upadhyay</p>
                            <p className="description-text-2">Program Manager</p>
                            <p className="description-text-2">UNITE-LA</p>
                          </div>
                        </div>

                        <img src={testimonialBackground3} alt="GC" className="image-full-auto" />
                      </div>
                    </div>
                    <div className="container-right">
                      <div className="white-background relative-position">
                        <div className="padding-20">
                          <img src={quote4} alt="GC" className="image-auto-30" />
                        </div>

                        <div className="bottom-padding horizontal-padding-5">
                          <p className="heading-text-5">“Guided Compass was very helpful during our career exploration and work-based learning workshops. It led to a more cohesive experience, and the real-world projects and work opportunities nicely tied the lessons together. The founder was also very high touch, answering questions and quickly implementing recommended changes. He has become a valued member of our advisory board and has provided useful ideas to continue improving our curriculum. It's impressive that they were able to get Guided Compass to pass LAUSD's stringent UDIPP process. Few technology products make it through this process due to its stringent privacy and security requirements.”</p>
                        </div>

                        <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                          <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/gabriela_rosillo.jpeg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                          <div className="white-text bottom-padding-10-percent">
                            <p className="top-padding">Gabriela Rosillo</p>
                            <p className="description-text-2">Academy Lead AOAT</p>
                            <p className="description-text-2">Grover Cleveland High School</p>
                          </div>
                        </div>

                        <img src={testimonialBackground4} alt="GC" className="image-full-auto" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                  {/*
                  <div className="row top-margin-20">
                    <div className="container-left">
                      <div className="white-background relative-position">
                        <div className="padding-20">
                          <img src={quote1} alt="GC" className="image-auto-30" />
                        </div>

                        <div className="bottom-padding-20 horizontal-padding-5">
                          <p className="heading-text-5">"Guided Compass really helped me discover a ton of career paths I didn't event know existed, identify which ones I was interested in, and keep track of my progress as I worked toward them. My parents were able to view my progress and more proactively help too. I really feel like Guided Compass helped me discover who I am and which path was right for me."</p>
                        </div>

                        <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                          <img src={profileIconDark} alt="GC" className="profile-thumbnail-6 center-horizontally white-background" />

                          <div className="white-text bottom-padding-10-percent">
                            <p className="top-padding">Koree Yancy</p>
                            <p className="description-text-2">Student</p>

                            <p className="description-text-2">University of Southern California</p>

                          </div>
                        </div>

                        <img src={testimonialBackground1} alt="GC" className="image-full-auto" />
                      </div>
                    </div>
                    <div className="container-right">
                      <div className="white-background relative-position">
                        <div className="padding-20">
                          <img src={quote2} alt="GC" className="image-auto-30" />
                        </div>

                        <div className="bottom-padding horizontal-padding-5">
                          <p className="heading-text-5">"Guided Compass helped me my secure best internship to date. I've worked at a few places, but they were just to pay the bills. I didn't feel like I had a connection to what I was doing and that I could build a career. Thanks to Guided Compass, I not only discovered a role I was interested in, I was able to put my best foot forward and secure it!"</p>
                        </div>

                        <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                          <img src={profileIconDark} alt="GC" className="profile-thumbnail-6 center-horizontally white-background" />

                          <div className="white-text bottom-padding-10-percent">
                            <p className="top-padding">Ana Rivera</p>
                            <p className="description-text-2">Student</p>

                            <p className="description-text-2">Long Beach City College</p>

                          </div>
                        </div>

                        <img src={testimonialBackground2} alt="GC" className="image-full-auto" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>*/}
              </section>

              <section className="section-workforce">
                <p className="heading-text-2 full-width center-text">Thousands trust us to help guide their career and power their career programs.</p>
              </section>

              {/*
              <div className="row">
                <h2>Compass Assistant</h2>
                <div className="spacer" />
                <div className="spacer" />
              </div>

              <div className="row">
                <p className="full-width center-text heading-text-4">We've built commands and functionality atop ChatGPT into our web and mobile apps.</p>
              </div>

              <div className="row top-margin-30">
                {(this.state.assistantBenefits) && (
                  <div className="top-margin-30 bottom-margin-20">
                    {this.state.assistantBenefits.map((item, optionIndex) =>
                      <div className="top-margin-20">
                        <div className="relative-column-33 description-text-color">
                          <div className="fixed-column-40">
                            <img src={arrowBulletPoint} alt="GC" className="image-auto-30" />
                          </div>
                          <div className="calc-column-offset-40">
                            <p className="heading-text-4">{item}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        {(optionIndex % 3 === 2) && (
                          <div className="clear" />
                        )}
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                )}
              </div>

              <div className="row top-margin-50 center-text">
                <Link className="btn btn-primary heading-text-4 cta-background-color white-text" to="/assistant">Try It</Link>
              </div>*/}

              {(this.state.showBlogPosts) && (
                <section className="section-features">

                  <div className="row">
                    <h2>Blog Posts</h2>
                    <div className="spacer" />
                    <div className="spacer" />
                  </div>

                  <div className="row">
                    {this.renderPosts()}
                    <div className="clear" />
                  </div>

                  <div className="row top-margin-50 center-text">
                    <Link className="btn btn-primary heading-text-4 cta-background-color white-text" to="/blog">View All</Link>
                  </div>
                </section>
              )}
              {/*
              <section className="section-features primary-background-absurdly-light">
                <SubJoinNewsletter />
              </section>*/}

              <section className="section-join white-background">
                <div className="join-container">
                  <div className="join-text-box">
                    <p className="join-text">Want to use</p>
                    <p className="join-title right-text">Guided Compass?</p>
                  </div>
                  <Link className="btn btn-full join-btn" to={'/join'} state={{ roleName: "Student / Career Seeker" }}>Join</Link>
                </div>
              </section>

              <Footer history={this.props.navigate} />
              {/*
              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
             >
              {(this.state.showNewPost) && (
                <div key="newPost" className="full-width">

                  <div className="spacer" />

                  <a onClick={() => this.closeModal()}>
                    <img src={closeIcon} alt="GC" className="image-auto-15" />
                  </a>

                  <div className="padding-40">
                    <p className="description-text-3">Download Our Whitepaper</p>
                    <p className="heading-text-2">How to Optimize Work-Based Learning (WBL) After COVID-19</p>
                    <div className="spacer" />
                    <p>This whitepaper combines lessons from WBL programs across the nation, and distills them into key strategies for administrators of educational and workforce development organizations that are building stellar WBL programs.</p>

                    <div className="spacer" /><div className="spacer" />

                    <div className="edit-profile-row">
                      <div className="container-left">
                          <label className="profile-label">First Name</label>
                          <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
                      </div>
                      <div className="container-right">
                          <label className="profile-label">Last Name</label>
                          <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler}/>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="edit-profile-row">
                      <div className="container-left">
                          <label className="profile-label">Email</label>
                          <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.formChangeHandler} />
                      </div>
                      <div className="container-right">
                          <label className="profile-label">Job Title</label>
                          <input className="text-field" type="text" placeholder="Job Title" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler}/>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="edit-profile-row">
                      <div className="container-left">
                          <label className="profile-label">Employer Name or School</label>
                          <input className="text-field" type="text" placeholder="Employer Name" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    {(this.state.serverSuccessMessage) && <p className="row-10 cta-color">{this.state.serverSuccessMessage}</p>}
                    {(this.state.serverErrorMessage) && <p className="row-10 error-color">{this.state.serverErrorMessage}</p>}

                    <button type="button" className="btn btn-full white-text" disable={this.state.isSaving} onClick={() => this.handleSubmit()}>Email Me The Link</button>

                  </div>
                </div>
              )}
              {(this.state.showMentorMatch) && (
                <div key="mentorMatch" className="full-width">
                  <img src={exampleMentorMatch} alt="GC" className="image-full-auto" />
                </div>
              )}
              {(this.state.showInternMatch) && (
                <div key="internMatch" className="full-width">
                  <img src={exampleInternMatch} alt="GC" className="image-full-auto" />
                </div>
              )}

             </Modal>*/}
            </div>
        )
    }
}

export default FrontPage;
